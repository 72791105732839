import {Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {filter, map, switchMap, takeUntil} from 'rxjs/operators';
import {fromEvent, Observable, Subject} from 'rxjs';
import {ICoordinate, IRect} from '../directives.helper';
import {DECIMAL_RADIX} from '../../common/Definitions/shared.definitions';

const BORDER_PRECISION_PX: number = 5;
const MIN_WIDTH_FACTOR: number = 0.5;
const MAX_WIDTH_FACTOR: number = 3;
const MIN_HEIGHT_FACTOR: number = 0.5;

const enum ResizeCursors {
  TOP_LEFT_RESIZE = 'nw-resize',
  TOP_RIGHT_RESIZE = 'ne-resize',
  BOTTOM_LEFT_RESIZE = 'sw-resize',
  BOTTOM_RIGHT_RESIZE = 'se-resize',
  HORIZONTAL_RESIZE = 'col-resize',
  VERTICAL_RESIZE = 'row-resize'
}

export class Edges {

  constructor(public top: boolean = false, public bottom: boolean = false,
              public left: boolean = false, public right: boolean = false) {}

  public isAnyEdgeResizable(): boolean {
    return this.top || this.bottom || this.left || this.right;
  }
}

class Rect implements IRect {

  constructor(public top: number = 0, public bottom: number = 0, public left: number = 0,
              public right: number = 0, public height: number = 0, public width: number = 0) {
  }
}

@Directive({
  selector: '[insResizable]'
})
export class ResizableDirective implements OnInit, OnDestroy {

  private natElem: HTMLElement;
  private destroy$: Subject<void> = new Subject<void>();
  private delta: ICoordinate = {x: 0, y: 0};
  private startingPos: Rect = new Rect();
  private currentSelectedEdges: Edges = new Edges();
  private calculatedTopMargin: number = 0;
  private calculatedLeftMargin: number = 0;

  private _duringResize: boolean = false;

  private get duringResize(): boolean {
    return this._duringResize;
  }

  private set duringResize(during: boolean) {
    // iFrame have its own "document" so when we are resizing element, we would like to 'disable' mouse-events on the iframe:
    const webglFrame: HTMLElement = document.getElementById('webglFrame');
    const pointerEvents: string = 'pointer-events';
    if (webglFrame != null) {
      if (during) {
        this.renderer.setStyle(webglFrame, pointerEvents, 'none');
      } else {
        this.renderer.setStyle(webglFrame, pointerEvents, 'auto');
      }
      this._duringResize = during;
    }
    const panoFrame: HTMLElement = document.getElementById('panoFrame');
    if (panoFrame != null) {
      if (during) {
        this.renderer.setStyle(panoFrame, pointerEvents, 'none');
      } else {
        this.renderer.setStyle(panoFrame, pointerEvents, 'auto');
      }
    }
    this._duringResize = during;
  }

  /* Configured edges to catch 'resize' */
  @Input()
  resizeEdges: Edges = new Edges();

  /* Min width that can be reached during resize */
  @Input()
  minWidth: number = undefined;

  @Input()
  maxWidth: number = undefined;

  /* Min Height that can be reached during resize */
  @Input()
  minHeight: number = undefined;

  @Output()
  doneResize: EventEmitter<string> = new EventEmitter();

  constructor(private elementRef: ElementRef, private zone: NgZone, private renderer: Renderer2) {
    this.natElem = elementRef.nativeElement;
  }

  private translate(): void {
    requestAnimationFrame(() => {

      const edges: Edges = this.currentSelectedEdges;
      const newWidth: number = this.startingPos.width + (edges.left ? -1 : 1) * this.delta.x;

      let widthDiff: number = 0;
      if ((newWidth - this.minWidth) < 0) {
        widthDiff = newWidth - this.minWidth;
      } else if ((this.maxWidth - newWidth) < 0) {
        widthDiff = -1 * (this.maxWidth - newWidth);
      }

      if (edges.left) {
        this.natElem.style.left = (this.startingPos.left - this.calculatedLeftMargin) + this.delta.x + widthDiff + 'px';
        this.natElem.style.width = newWidth - widthDiff + 'px';
      } else if (edges.right) {
        this.natElem.style.right = window.innerWidth - (this.startingPos.right + this.delta.x - widthDiff) + 'px';
        this.natElem.style.width = newWidth - widthDiff + 'px';
      }

      const newHeight: number = this.startingPos.height + (edges.top ? -1 : 1) * this.delta.y;
      const heightDiff: number = (newHeight - this.minHeight) < 0 ? (newHeight - this.minHeight) : 0 ;
      if (edges.top) {
        this.natElem.style.top = (this.startingPos.top - this.calculatedTopMargin) + this.delta.y + heightDiff + 'px';
        this.natElem.style.height = newHeight - heightDiff + 'px';
      } else if (edges.bottom) {
        this.natElem.style.bottom = this.startingPos.bottom + this.delta.y + heightDiff + 'px';
        this.natElem.style.height = newHeight - heightDiff + 'px';
      }
    });
  }

  private getCursor(edges: Edges): string {
    if (this.hasTopLeftEdge(edges)) {
      return ResizeCursors.TOP_LEFT_RESIZE;
    } else if (this.hasTopRightEdge(edges)) {
      return ResizeCursors.TOP_RIGHT_RESIZE;
    } else if (this.hasBottomLeftEdge(edges)) {
      return ResizeCursors.BOTTOM_LEFT_RESIZE;
    } else if (this.hasBottomRightEdge(edges)) {
      return ResizeCursors.BOTTOM_RIGHT_RESIZE;
    } else if (this.hasHorizontalEdge(edges)) {
      return ResizeCursors.HORIZONTAL_RESIZE;
    } else if (this.hasVerticalEdge(edges)) {
      return ResizeCursors.VERTICAL_RESIZE;
    } else {
      return 'auto';
    }
  }

  private hasTopLeftEdge(edges: Edges): boolean {
    return edges.left && edges.top;
  }
  
  private hasTopRightEdge(edges: Edges): boolean {
    return edges.right && edges.top;
  }
  
  private hasBottomLeftEdge(edges: Edges): boolean {
    return edges.left && edges.bottom;
  }
  
  private hasBottomRightEdge(edges: Edges): boolean {
    return edges.right && edges.bottom;
  }
  
  private hasHorizontalEdge(edges: Edges): boolean {
    return edges.left || edges.right;
  }
  
  private hasVerticalEdge(edges: Edges): boolean {
    return edges.top || edges.bottom;
  }

  private getCurrentEdges(clientX: number, clientY: number): Edges {
    const elmPosition: ClientRect = this.natElem.getBoundingClientRect();
    const edges: Edges = new Edges();
    if (this.resizeEdges.left && this.isMouseOverBorder(clientX, elmPosition.left)) {
      edges.left = true;
    }
    if (this.resizeEdges.right && this.isMouseOverBorder(clientX, elmPosition.right)) {
      edges.right = true;
    }
    if (this.resizeEdges.top && this.isMouseOverBorder(clientY, elmPosition.top)) {
      edges.top = true;
    }
    if (this.resizeEdges.bottom && this.isMouseOverBorder(clientY, elmPosition.bottom)) {
      edges.bottom = true;
    }
    return edges;
  }

  private pauseEvent(event: MouseEvent): void {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (event.preventDefault) {
      event.preventDefault();
    }
    event.cancelBubble = true;
    event.returnValue = false;
  }

  private setupEvents(): void {
    this.zone.runOutsideAngular(() => {
      const mouseDown$: Observable<Event> = fromEvent(this.natElem, 'mousedown');
      const mouseMove$: Observable<Event> = fromEvent(document, 'mousemove');
      const mouseUp$: Observable<Event> = fromEvent(document, 'mouseup');

      mouseMove$.pipe(takeUntil(this.destroy$)).subscribe((event: MouseEvent) => {
        if (!this.duringResize) {
          const edges: Edges = this.getCurrentEdges(event.clientX, event.clientY);
          const cursor: string = this.getCursor(edges);
          this.renderer.setStyle(this.natElem, 'cursor', cursor);
        }
      });

      const mouseDrag$: Observable<void> = mouseDown$
        .pipe(filter((downEvent: MouseEvent) => downEvent.button === 0))
        .pipe(filter((downEvent: MouseEvent) => {
          this.currentSelectedEdges = this.getCurrentEdges(downEvent.clientX, downEvent.clientY);
          const cursor: string = this.getCursor(this.currentSelectedEdges);
          this.renderer.setStyle(document.body, 'cursor', cursor);
          return this.currentSelectedEdges.isAnyEdgeResizable();
        }))
        .pipe(switchMap((downEvent: MouseEvent) => {
          this.pauseEvent(downEvent);
          this.duringResize = true;

          const calculatedStyle: CSSStyleDeclaration = window.getComputedStyle(this.natElem);
          this.startingPos.top = parseInt(calculatedStyle.top, DECIMAL_RADIX);
          this.startingPos.bottom = parseInt(calculatedStyle.bottom, DECIMAL_RADIX);
          this.startingPos.left = parseInt(calculatedStyle.left, DECIMAL_RADIX);
          this.startingPos.right = parseInt(calculatedStyle.right, DECIMAL_RADIX);
          this.startingPos.height = parseInt(calculatedStyle.height, DECIMAL_RADIX);
          this.startingPos.width = parseInt(calculatedStyle.width, DECIMAL_RADIX);

          if (!this.maxWidth) {
            this.maxWidth = this.startingPos.width * MAX_WIDTH_FACTOR;
          }

          if (!this.minWidth) {
            this.minWidth = this.startingPos.width * MIN_WIDTH_FACTOR;
          }
          if (!this.minHeight) {
            this.minHeight = this.startingPos.height * MIN_HEIGHT_FACTOR;
          }
          const startX = downEvent.clientX;
          const startY = downEvent.clientY;

          return mouseMove$.pipe(map((moveEvent: MouseEvent) => {
            this.pauseEvent(downEvent);
            this.delta = {
              x: moveEvent.clientX - startX,
              y: moveEvent.clientY - startY
            };
          })).pipe(takeUntil(mouseUp$));
        })).pipe(takeUntil(this.destroy$));

      mouseDrag$.subscribe(() => {
        if (this.delta.x === 0 && this.delta.y === 0) {
          return;
        }
        this.translate();
      });

      mouseUp$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        if (this.duringResize) {
          this.doneResize.emit(this.natElem.style.width);
        }
        this.duringResize = false;
        this.renderer.setStyle(document.body, 'cursor', 'auto');

      });
    });
  }

  public ngOnInit(): void {
    this.setupEvents();
  }

  public isMouseOverBorder(value1: number, value2: number): boolean {
    const diff: number = Math.abs(value1 - value2);
    return diff < BORDER_PRECISION_PX;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
