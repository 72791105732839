import {Injectable} from '@angular/core';
import {PostRequest, ServerApi} from './server.api';
import {environment} from '../../../environments/environment';
import {ApiTools} from './api.tools';
import {ExportType, IExportServicesArgs} from '../../Viewer/content.container/side-bar.container/subMenus/admin-panel/admin-panel.helper';
import {Subject, forkJoin, Observable, Observer} from 'rxjs';
import {MessagesBank} from '../status.service';
import {DownloadFileHelper} from '../download.file.helper';
import {SessionApiSvc} from './session.api.svc';
import {SiemensAnalyticsService} from '../siemens-analytics.service';
import {HttpHeaders} from '@angular/common/http';

@Injectable()
export class ImportExportApiSvc {

  public aborte57MultipartUpload = new Subject<any>();

  private runningE57MPUploadList = []; 

  constructor(private serverApi: ServerApi, public sessionApiSvc: SessionApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {}

  createOperation(name: string, siteId: string, fileName: string) {
    const createOperationUrl: string = `${environment.serverUrl}/api/v2/backgroung-operations`;
    const operatinBody = {
      name: name,
      initialState: "MODEL_FILE_UPLOADING",
      fileName: fileName
    }
    if (siteId) {
      operatinBody['siteId'] = siteId;
    }
    return this.serverApi.sendPostToServer(createOperationUrl, operatinBody);
  }

  importObjects(fileToImport: File, email: string, closeSubject: Subject<boolean>): void {
    const importObjectsUrl: string = `${environment.serverUrl}/ImportService?SITEID=${ApiTools.defaultSiteId}`;
    const uploadData = new FormData();
    uploadData.append('EMAIL', email);
    uploadData.append('SESSIONID', this.sessionApiSvc.sessionId);
    uploadData.append('file', fileToImport);

    this.createOperation('IMPORT_OBJECT', null, fileToImport.name).subscribe((response: any) => {
      if (response.id) {
        uploadData.append('OPERATION_ID', response.id);
      }
      this.serverApi.sendPostToServer(importObjectsUrl, uploadData).subscribe((response: any) => {
        this.serverApi.statusBar.removeStatus(MessagesBank.IMPORTING_INFO);
        this.serverApi.statusBar.addNewStatusOnTime(MessagesBank.IMPORT_WAIT_FOR_EMAIL, 5000);
        closeSubject.next(true);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_ImportObjects');
      },
        (err) => {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in importing. Please try later');
          this.serverApi.statusBar.removeStatus(MessagesBank.IMPORTING_INFO);
        });
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in importing. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.IMPORTING_INFO);
    })
    
  }

  exportService(exportServicesArgs: IExportServicesArgs, messageToRemove: MessagesBank): void {
    const exportServiceUrl: string = `${environment.serverUrl}/services/ExportServices/dataExport?siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.sendPostToServer(exportServiceUrl, new PostRequest({}, exportServicesArgs)).subscribe((response: any) => {
      if (response.url.value === null) {
        let message: MessagesBank;
        switch (exportServicesArgs.exportType) {
          case ExportType.ALL:
            message = MessagesBank.NO_DATA_TO_EXPORT;
            break;
          case ExportType.PLACEMARKS:
            message = MessagesBank.NO_PM_TO_EXPORT;
            break;
          case ExportType.MODELS:
            message = MessagesBank.NO_MODEL_TO_EXPORT;
            break;
          case ExportType.ADDRESSES:
            message = MessagesBank.NO_ADDRESS_TO_EXPORT;
            break;
          case ExportType.PANORAMIC:
            message = MessagesBank.NO_PANO_TO_EXPORT;
            break;
          case ExportType.IMAGES:
            message = MessagesBank.NO_IMAGE_TO_EXPORT;
            break;
          case ExportType.LAYERS:
            message = MessagesBank.NO_LAYER_TO_EXPORT;
            break;
        }
        this.serverApi.statusBar.removeStatus(messageToRemove);
        this.serverApi.statusBar.addNewStatusOnTime(message, 5000);
      } else {
        const urlObject: string = response.url.value;
        if (!urlObject || urlObject === '') {
          return;
        }
        console.log('Export file: ', urlObject);

        DownloadFileHelper.downloadFile(urlObject, response.url.fileName);
        this.serverApi.statusBar.removeStatus(messageToRemove);
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_ExportObjects');
      }
    },
      (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in expoting. Please try later');
        this.serverApi.statusBar.removeStatus(messageToRemove);
      });
  }

  importE57File(fileMetaData: any, fileToImport: File, closeSubject: Subject<boolean>): void {
    console.log(fileMetaData)
    fileMetaData['siteId'] = ApiTools.defaultSiteId;
    const generatePresignedUrl: string = `${environment.serverUrl}/api/v2/e57-import/get-presigned-url?`;
    this.serverApi.sendPostToServer(generatePresignedUrl, fileMetaData).subscribe((response: any) => {
      this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);
      console.log(response);
      closeSubject.next(true);
      // Log Siemens Analytics event
      // this.siemensAnalyticsService.logEvent('INS_ImportObjects');
      this.serverApi.statusBar.addNewStatusOnTime(MessagesBank.IMPORTING_E57_FILE, 5000);
      if (response.url) {
        const httpHeaders: HttpHeaders = new HttpHeaders({
          'Content-Transfer-Encoding': 'UTF-8',
        });
        this.serverApi.sendPutToServer(response.url, fileToImport, { headers: httpHeaders }).subscribe((response: any) => {
          console.log("......................................");
          console.log(response);
          console.log("......................................");
        },
        (err) => {
          this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in uploading e57 file. Please try later');
        })
      }
    },
    (err) => {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in importing e57 file. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);
    });
  }

  importE57FilePart(fileMetaData: any, fileToImport: File, closeSubject: Subject<boolean>): void {
    const sequence = new Subject<any>();
    const sequenceObserable: Observable<any> = sequence.asObservable();
    if (this.runningE57MPUploadList.length == 0) {
      this.runningE57MPUploadList.push(sequence);
    } else {
      this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);
      closeSubject.next(true);
      return this.serverApi.createNotificationDialog('warning', 'E57 Import', 'e57 file import is already running.. please wait once it is completed.');
    }
  
    fileMetaData['siteId'] = ApiTools.defaultSiteId;

    const chunkSize = 25 * 1024 * 1024; // 25MB chunk size
    const totalChunks = Math.ceil(fileToImport.size / chunkSize);

    fileMetaData['partNumbers'] = totalChunks;

    let partEtagMap = {};
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, fileToImport.size);
      const chunk = fileToImport.slice(start, end);
      console.log("------------------------------------------------------------")
      console.log(chunkSize)
      console.log(start + " : " + end)
      console.log("------------------------------------------------------------")
      partEtagMap[i+1] = {
        'start': start,
        'end': end,
        'chunk': chunk
      }
    }
    let subscribePayload = {
      'multipartCompleteUrl': ''
    };

    fileMetaData['requestCompleteMultipartUrl'] = false;
    fileMetaData['startPartNumber'] = 1;
    fileMetaData['endPartNumber'] = (totalChunks <= 3 ? totalChunks : 3);
    fileMetaData['objectKey'] = '';

    subscribePayload['fileMetaData'] = fileMetaData;
    subscribePayload['observer'] = sequence;

    // this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);

    sequence.subscribe({
      next: (subscribePayload) => {
        if (subscribePayload.fileMetaData['startPartNumber'] > totalChunks) {
          subscribePayload.observer.complete();
        } else {
          this.sendChunkRequests(subscribePayload, totalChunks, partEtagMap, closeSubject)
        }
      },
      error: (err) => {
        console.log(err);
        console.log('error');
      },
      complete: () => {
        this.completeMultipartUpload(subscribePayload, partEtagMap)
      }
    });
    sequence.next(subscribePayload);
  }

  sendChunkRequests(subscribePayload, totalChunks, partEtagMap, closeSubject) {
    if (subscribePayload.fileMetaData['endPartNumber'] == totalChunks) {
      subscribePayload.fileMetaData['requestCompleteMultipartUrl'] = true;
    }
    const generatePresignedUrl: string = `${environment.serverUrl}/api/v2/e57-import/get-presigned-url?`;
    this.serverApi.sendPostToServer(generatePresignedUrl, subscribePayload.fileMetaData).subscribe((urlResponse: any) => {
      console.log(urlResponse);
      closeSubject.next(true);
      // Log Siemens Analytics event
      // this.siemensAnalyticsService.logEvent('INS_ImportObjects');

      subscribePayload.fileMetaData['uploadId'] = urlResponse.uploadId;
      subscribePayload.fileMetaData['objectKey'] = urlResponse.objectKey;
      let reqArr = [];
      if (urlResponse.multipartUrlList) {
        for (let j=0; j<urlResponse.multipartUrlList.length; j++) {
          const httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Transfer-Encoding': 'UTF-8',
          });
          let etagMap = partEtagMap[subscribePayload.fileMetaData['startPartNumber'] + j];
          const chunk = etagMap['chunk'] //fileToImport.slice(etagMap.start, etagMap.end);
          let req = this.serverApi.sendPutToServer(urlResponse.multipartUrlList[j], chunk, { headers: httpHeaders, observe: 'response' });
          reqArr.push(req);
        }
      }
      forkJoin(reqArr).subscribe((responses) => {
        responses.forEach((data: any, indx) => {
          if (data.hasError) {
            console.log(data);
          } else {
            partEtagMap[subscribePayload.fileMetaData['startPartNumber'] + indx]['etag'] = data.headers.get('Etag');
          }
        });

        subscribePayload.fileMetaData['startPartNumber'] = subscribePayload.fileMetaData['endPartNumber'] + 1;
        subscribePayload.fileMetaData['endPartNumber'] = subscribePayload.fileMetaData['endPartNumber'] + 3;
        if (subscribePayload.fileMetaData['endPartNumber'] >= totalChunks) {
          subscribePayload.fileMetaData['endPartNumber'] = totalChunks;
        }

        subscribePayload.multipartCompleteUrl = urlResponse.multipartCompleteUrl;
        subscribePayload.observer.next(subscribePayload)
      },
      (err) => {
        console.log(err);
        this.runningE57MPUploadList.pop();
        this.abortMultipartUpload(subscribePayload, partEtagMap);
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in importing e57 file. Please try later');
        this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);
      });
    },
    (err) => {
      this.runningE57MPUploadList.pop();
      this.abortMultipartUpload(subscribePayload, partEtagMap);
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in importing e57 file. Please try later');
      this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);
    });
  }

  completeMultipartUpload(subscribePayload, partEtagMap) {
    let partString = '';
    for(let partNumber in partEtagMap) {
      partString = partString +
      '<Part><PartNumber>' + partNumber +
          '</PartNumber><ETag>' + partEtagMap[partNumber]['etag'] +
      '</ETag></Part>'
    }
    let body = '<CompleteMultipartUpload>' + partString + '</CompleteMultipartUpload>';
    const reqHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/xml'
    });
    this.serverApi.sendPostToServer(subscribePayload.multipartCompleteUrl, body, { headers: reqHeaders, observe: 'response', responseType: "text"}).subscribe((completeResponse: any) => {
      this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);
      this.serverApi.statusBar.addNewStatusOnTime(MessagesBank.IMPORTING_E57_FILE, 5000);
      this.runningE57MPUploadList.pop();
      console.log(completeResponse);
    },
    (err) => {
      this.runningE57MPUploadList.pop();
      this.abortMultipartUpload(subscribePayload, partEtagMap);
      this.serverApi.createNotifiactionDialogForHttpCrisis(err, 'Error in importing e57 file. Please try later');
      this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);
    });
  }

  abortMultipartUpload(subscribePayload, partEtagMap) {
    const abortMultipartUrl: string = `${environment.serverUrl}/api/v2/e57-import/abort-multipart-upload?`;
    this.serverApi.sendPostToServer(abortMultipartUrl, {
      "uploadId": subscribePayload.fileMetaData['uploadId'],
      "objectKey": subscribePayload.fileMetaData['objectKey']
    }).subscribe((completeResponse: any) => {
      // this.serverApi.statusBar.removeStatus(MessagesBank.START_E57IMPORT);
      console.log(completeResponse);
    });
  }
}
