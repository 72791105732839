import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { SessionApiSvc } from '../services/api.services/session.api.svc';
import { ApplicationView, ICompanyInfo, ISessionMetaData, IntositeLoginMethod } from '../common/Models/UI/company-info.interface';
import { Store } from '@ngxs/store';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private sessionAPISvc: SessionApiSvc, private store: Store) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (await this.isLoggedIn()) {
      return true;
    }
    if (route.queryParams?.setnewpassword) {
      this.sessionAPISvc.clearLocalStorageItems();
      this.router.navigate(['login'], { queryParams: route.queryParams });
      return false;
    }
    // not logged in so redirect to login page
    console.log('You are not logged in, we will redirect you to the login page');
    console.log('NAVIGATING to login from AuthGuard');
    const companyInfo: ICompanyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo);
    if (companyInfo?.ssoLogin) {
      this.loginSSOWithCompanyInfo(companyInfo, route);
    } else if (route.queryParams.ssoCopyLink || route.queryParams.sso) {
      this.router.navigate(['ssologin'], { queryParams: route.queryParams });
    } else {
      this.router.navigate(['login'], { queryParams: route.queryParams });
    }
    return false;
  }

  private async isLoggedIn(): Promise<boolean> {
    if (localStorage.getItem('intosite_session')) {
      await this.sessionAPISvc.getUserSessionInfo();
      const sessionMetaData: ISessionMetaData = this.sessionAPISvc.getSessionMetaData();
      if (sessionMetaData.appView != ApplicationView.Tour) {
        console.log('You are logged in, ENJOY');
        return true;
      } else {
        this.sessionAPISvc.closeSession();
        return false;
      }
    }
    return false;
  }

  private loginSSOWithCompanyInfo(companyInfo: ICompanyInfo, route: ActivatedRouteSnapshot) {
    let params;
    if (companyInfo.intositeLoginMethod === IntositeLoginMethod.Enterprise) {
      params = { company: companyInfo.name, Enterprise: true };
    } else if (companyInfo.intositeLoginMethod === IntositeLoginMethod.Sphere) {
      params = { company: companyInfo.name, Sphere: true };
    } else {
      params = { company: companyInfo.name };
    }
    this.router.navigate(['ssologin'], {
      queryParams: { ...route.queryParams, ...params },
    });
  }
}