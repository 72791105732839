import {ChangeDetectorRef, Component, ElementRef, Input, Renderer2} from '@angular/core';
import {ButtonInfo} from '../../../common/UI-Components/helperClasses/value-and-display.class';
import {Select, Store} from '@ngxs/store';
import {SetOpenedPanel} from '../../../Store/app.state/app.actions';
import {APPLICATION_MODE, IAppConf, SCENE_MODE} from '../../../Store/app.state/app.model';
import {DiscussionApiSvc} from '../../../services/api.services/discussion.api.svc';
import {AppState} from '../../../Store/app.state/app.state';
import {Observable} from 'rxjs';
import {SetSelectedPlacemark} from '../../../Store/discussions.state/discussions.actions';
import {Actions, PermissionsManager} from '../../../services/permissions-manager';

enum SidebarOption {
  SEARCH = 'sidebar.search',
  SITES = 'sidebar.sites',
  ZONES = 'sidebar.zones',
  LAYERS = 'sidebar.layers',
  VIEWPOINTS = 'sidebar.viewpoints',
  DISCUSSION = 'sidebar.discussion'
}

export const ModeDependentPanels: string[] = [SidebarOption.SEARCH, SidebarOption.SITES, SidebarOption.DISCUSSION];

@Component({
  selector: 'ins-side-bar-container',
  templateUrl: './side-bar.container.html',
  styleUrls: ['./side-bar.container.scss']
})
export class SideBarContainer {

  @Select(AppState.getOpenedPanel) pressedPanelName$: Observable<string>;

  public upperButtonsInfo: ButtonInfo[] = [];
  public lowerButtonsInfo: ButtonInfo[] = [];
  public userButtonInfo: ButtonInfo;
  public userButtonIcon: string;

  @Input() public sceneMode: SCENE_MODE;
  @Input() public pressedMenuName: string = '';

  @Select(AppState.getAppConf) appConf$: Observable<IAppConf>;
  constructor(private store: Store, public _elementRef: ElementRef, private renderer: Renderer2, private discussionApiSvc: DiscussionApiSvc,
              private changeDetectorRef: ChangeDetectorRef) {
    this.upperButtonsInfo.push(
      new ButtonInfo(SidebarOption.SEARCH, 'Search'),
      new ButtonInfo(SidebarOption.SITES, 'Sites'),
      new ButtonInfo(SidebarOption.ZONES, 'Zones'),
      new ButtonInfo(SidebarOption.LAYERS, 'Layers'),
      new ButtonInfo(SidebarOption.VIEWPOINTS, 'Viewpoints'),
      new ButtonInfo(SidebarOption.DISCUSSION, 'Discussion')
    );

    PermissionsManager.isPermitted$(Actions.ADMIN_MENU_BUTTON).subscribe((isPerm: boolean) => {
      const adminButton: ButtonInfo = new ButtonInfo('panels.admin', 'Admin');
      if (isPerm) {
        if (!this.lowerButtonsInfo) {
          this.lowerButtonsInfo.push(adminButton);
        } else if (!this.lowerButtonsInfo.find((button: ButtonInfo) => { return adminButton.displayValue === button.displayValue; })) {
          this.lowerButtonsInfo.unshift(adminButton); // Add element in the first place
          this.changeDetectorRef.detectChanges();
        }
      } else {
        if (this.lowerButtonsInfo.find((button: ButtonInfo) => { return adminButton.displayValue === button.displayValue; })) {
          this.lowerButtonsInfo.shift(); // Remove Admin from array
          this.changeDetectorRef.detectChanges();
        }
      }
    });

    this.lowerButtonsInfo.push(
      new ButtonInfo('panels.help', 'Help'),
      new ButtonInfo('panels.settings', 'Settings')
    );

    this.userButtonInfo = new ButtonInfo('panels.profile', '');
    this.appConf$.subscribe((appConf: IAppConf) => {
      if (appConf && appConf.appMode === APPLICATION_MODE.EMBEDDED) {
        this.userButtonIcon = 'panels.profile.light';
      } else {
        this.userButtonIcon = 'panels.profile';
      }
    });
  }

  public isHidden(panelName: string): boolean {
    if (this.sceneMode === SCENE_MODE.Panoramic && ModeDependentPanels.find((name: string) => name === panelName)) {
      return true;
    }
    return false;
  }

  public toggleMenu(menuName: string): void {
    this.store.dispatch(new SetOpenedPanel(menuName));

    if (menuName === SidebarOption.DISCUSSION) {
      this.pressedPanelName$.subscribe((name: string) => {
        if (name === menuName) {
          this.discussionApiSvc.getAllPlacemarksWithComments(undefined);
        } else {
          this.store.dispatch(new SetSelectedPlacemark(undefined));
        }
      }).unsubscribe();
    } else {
      this.store.dispatch(new SetSelectedPlacemark(undefined));
    }
  }

}
