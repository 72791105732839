import {Component, Injector, Input} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';

@Component({
  selector: 'ins-hello-world',
  templateUrl: './hello-world.component.html',
  styleUrls: ['./hello-world.component.css']
})
export class HelloWorldModalComponent extends BaseDialog {

  @Input()
  strToInput: string = '';

  @Input()
  multilineInput: string = '';

  @Input()
  image: any = null;
  constructor(public injector: Injector) {
    super(injector);
  }

  handleInput(input: any): void {
  }

  public defineDialogModel(): void {
    this.dialogModel.setData('image', null);
  }

  onChanges(): void {
    console.log('onChanges');
  }

}
