import { Component, OnInit } from '@angular/core';
import { MessageAndType, PriorityStack, StatusService } from '../../../services/status.service';
@Component({
  selector: 'ins-status-container',
  templateUrl: './status.container.html',
  styleUrls: ['./status.container.scss']
})

export class StatusContainer implements OnInit {
  public statusText: string = '';

  constructor(private statusService: StatusService) {}

  ngOnInit(): void {
    this.statusService.registerStatus().subscribe((status: PriorityStack<MessageAndType>) => {
      if (status.isEmpty()) {
        this.statusText = '';
      } else {
        this.statusText = status.top().message;
      }
    });
  }

}
