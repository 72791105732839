import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {DIALOG_DEFAULT_WIDTH_PX, DialogService, DialogType} from '../../../../../services/dialogs.service';
import {LayersSelectionDialogComponent} from '../../../../../common/Forms/Dialogs/layers-selection-dialog/layers-selection-dialog.component';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {SitesSelectionDialogComponent} from '../../../../../common/Forms/Dialogs/sites-selection-dialog/sites-selection-dialog.component';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {SearchApiSvc} from '../../../../../services/api.services/search.api.svc';
import {LAYERS_FILTER_TYPES, SEARCH_TYPES, SearchInputModel, SITES_FILTER_TYPES} from './search-input-model';
import {SitesState} from '../../../../../Store/sites.state/sites.state';
import {ISite} from '../../../../../Store/sites.state/sites.model';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {LayersState} from '../../../../../Store/layers.state/layers.state';
import {AppState} from '../../../../../Store/app.state/app.state';
import {LayersStateUtils} from '../../../../../Store/layers.state/layers.state.utils';
import {LayerTreeNode} from '../../../../../common/Models/UI/ui-tree-node.model';
import {SCENE_MODE} from '../../../../../Store/app.state/app.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../../services/cmd-router.service';
import {cloneDeep} from 'lodash';
import {ILocation, IPlacemark, IPosition} from '../../../../../Store/placemarks.state/placemarks.model';
import {IViewpoint} from '../../../../../Store/viewpoints.state/viewpoints.model';
import {PlacemarkPreviewService} from '../../../../../services/placemark-preview.service';
import {PlacemarksApiSvc} from '../../../../../services/api.services/placemarks.api.svc';
import {LAYER_TYPES} from '../../../../../Store/layers.state/layers.model';
import {ViewpointsApiSvc} from '../../../../../services/api.services/viewpoints.api.svc';
import {ValueAndDisplay} from '../../../../../common/UI-Components/helperClasses/value-and-display.class';
import {Edges} from '../../../../../Directives/resizable/resizable.directive';
import {isNullOrUndefined} from 'util';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {SitesLoaderSvc} from '../../../../../services/api.services/sites.loader.svc';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';
import {ViewerObjectType} from 'src/app/common/Models/UI/viewer-object-type.enum';
import {AppTheme, ThemeService} from 'src/app/services/themes.service';

export const MAX_SEARCH_RESULTS_IN_PAGE: number = 21;
export const MAP_LOCATION_SITE_NAME: string = 'Map Locations';

const NO_SEARCH_ENTRY: string = 'No search entry has been provided yet.';
const NO_RESULTS_FOUND: string = 'No results found.';

export class SingleSearchResult {

  private _letterIndex: string = '';

  type: string;
  icon_name: string;
  displayValue: string;
  name: string;
  site: ISite;

  originalObjId: string;
  searchObjId: string;
  position: IPosition;
  location: ILocation;
  coordinatesType: string;

  constructor(type: string, name: string, site: ISite) {
    this.type = type;
    this.site = site;
    switch (type) {
      case 'PLACEMARK': {
        this.icon_name = 'placemarks.search.result';
        break;
      }
      case 'VIEWPOINT': {
        this.icon_name = 'viewpoints.search.result';
        break;
      }
      case 'PLACE': {
        this.icon_name = 'address.search.result';
        break;
      }
      case 'PANORAMIC_IMAGE': {
        this.icon_name = 'panoramic.search.result';
        break;
      }
      case 'GOOGLE_PLACE': {
        this.icon_name = 'mapLocation.search.result';
        break;
      }
      case 'SCAN_PLACEMARK': {
        this.icon_name = 'scanPlacemark.search.result';
        break;
      }
      case 'MODEL': {
        this.icon_name = 'model.search.result';
        break;
      }
    }
    this.displayValue = this.name = name;
  }

  set letterIndex(value: string) {
    this._letterIndex = value;
    this.displayValue = `(${value}) ${this.name}`;
  }

  get letterIndex(): string {
    return this._letterIndex;
  }

  emptyLetterIndex(): void {
    this._letterIndex = '';
    this.displayValue = this.name;
  }
}

@Component({
  selector: 'ins-search-res-menu',
  templateUrl: './search-res-menu.component.html',
  styleUrls: ['./search-res-menu.component.scss', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class SearchResMenuComponent implements OnChanges, OnInit {

  @Input() public openedPanel: string;
  @Input() sceneMode: SCENE_MODE = SCENE_MODE.Map;
  SceneMode: any = SCENE_MODE;
  _searchInput: string = '';

  maxResultPerPage: number = MAX_SEARCH_RESULTS_IN_PAGE;

  SearchTypes: any = SEARCH_TYPES;
  selectedType: SEARCH_TYPES = SEARCH_TYPES.ALL;
  pmSubTypeMenuOpened: boolean = false;
  layersFilterVisible: boolean = true;
  disableSearch: boolean = true;
  SitesFilter: any = SITES_FILTER_TYPES;
  selectedSiteFilterType: SITES_FILTER_TYPES = SITES_FILTER_TYPES.CUREENT_SITE;
  selectedSiteFilterStr: string = SITES_FILTER_TYPES.CUREENT_SITE + '';
  @Select(SitesState.getSites) sites$: Observable<ISite[]>;
  allSites: ISite[];
  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  @Select(AppState.getLogoutToggleIndex) logoutToggleIndex$: Observable<number>;
  activeSite: ISite;
  mapSearchSiteMock: ISite;
  selectedSites: string[] = [];

  LayersFilter: any = LAYERS_FILTER_TYPES;
  selectedLayersFilterType: LAYERS_FILTER_TYPES = LAYERS_FILTER_TYPES.ALL;
  selectedLayersFilterStr: string = LAYERS_FILTER_TYPES.ALL + '';
  @Select(LayersState.getLayersTree) layersTree$: Observable<LayerTreeNode[]>;
  allLayers: LayerTreeNode[];
  allLayersFlatten: LayerTreeNode[] = [];
  selectedLayers: string[] = [];
  selectedLayersUnModified: string[] = [];

  _mapLocationSearch: boolean = false;

  // Search results members
  page: number = 1;
  pageFacility: number = 1;
  pageMap: number = 1;
  currentSearchResultsArr: SingleSearchResult[] = [];
  searchResultsFacility: Map<ISite, SingleSearchResult[]> = new Map<ISite, SingleSearchResult[]>();
  searchResultsMap: Map<ISite, SingleSearchResult[]> = new Map<ISite, SingleSearchResult[]>();
  processingSearchRequest: boolean = false;
  loadSiteFromSearchPanel: boolean = false;

  tempSiteSelectionArr: string[] = [];
  tempLayersSelectionArr: string[] = [];
  noContentAvailableStr: string = NO_SEARCH_ENTRY;

  appTheme: AppTheme = AppTheme.DARK;
  get searchInput(): string {
    return this._searchInput;
  }

  set searchInput(value: string) {
    this._searchInput = value;
  }

  get mapLocationSearch(): boolean {
    return this._mapLocationSearch;
  }

  set mapLocationSearch(value: boolean) {
    this._mapLocationSearch = value;
    if (this.searchInput !== '') {
      this.runSearch();
    }
  }

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();

  constructor(private readonly dialogService: DialogService, private readonly searchApiSvc: SearchApiSvc, private readonly store: Store, private readonly placemarksApiSvc: PlacemarksApiSvc,
              private readonly cmdRouterSvc: CmdRouterService, private readonly placemarkPreviewService: PlacemarkPreviewService, private readonly viewpointsApiSvc: ViewpointsApiSvc,
              private readonly statusBar: StatusService, public readonly sitesLoaderSvc: SitesLoaderSvc, private readonly siemensAnalyticsService: SiemensAnalyticsService,
              private readonly themeService: ThemeService) {
    this.sites$.subscribe((sites: ISite[]) => {
      if (!sites) { return; }
      this.allSites = sites;
      if (this.selectedSiteFilterType === SITES_FILTER_TYPES.ALL) {
        this.selectSiteFilter(SITES_FILTER_TYPES.ALL, false);
      }
    });
    this.activeSite$.subscribe((site: ISite) => {
      if (!site) {
        this.disableSearch = true;
        return;
      }
      this.disableSearch = false;
      this.activeSite = site;
      this.mapSearchSiteMock = cloneDeep(site);
      this.mapSearchSiteMock.name = MAP_LOCATION_SITE_NAME;
      this.mapSearchSiteMock.id = -1;
      // The following piece of code may be irrelevant- no specific scenario that related to search
      // if (this.selectedSiteFilterType === SITES_FILTER_TYPES.CUREENT_SITE) {
      //   this.selectSiteFilter(SITES_FILTER_TYPES.CUREENT_SITE);
      // }
      if (this.loadSiteFromSearchPanel) {
        // console.log('ACTIVE CHANGED - RE RUN SEARCH AFTER LOAD FROM SEARCH PANEL');
        // this.runSearch();
      } else {
        // console.log('ACTIVE CHANGED - CLEAN ALL');
        this.clearSearchStr();
        this.resetSearchSession();
        this.selectedSites = [];
        this.tempSiteSelectionArr = [];
        this.selectSiteFilter(SITES_FILTER_TYPES.CUREENT_SITE, false);
      }
    });
    this.layersTree$.subscribe((layers: LayerTreeNode[]) => {
      if (!layers || layers.length === 0) { return; }
      this.allLayersFlatten = [];
      this.allLayers = cloneDeep(layers);
      this.allLayers = LayersStateUtils.removeGeneralLayers(this.allLayers);
      this.allLayers = LayersStateUtils.filterInfertileLayers(this.allLayers);
      LayersStateUtils.flattenTree(this.allLayers, this.allLayersFlatten);
      if (this.selectedLayersFilterType === LAYERS_FILTER_TYPES.ALL) {
        this.selectLayersFilter(LAYERS_FILTER_TYPES.ALL, this.loadSiteFromSearchPanel);
        this.loadSiteFromSearchPanel = false;
      }
    });
    this.logoutToggleIndex$.subscribe((index: number) => {
      if (index > 0) {
        this.searchInput = '';
        this.cleanSearchResults();
      }
    });
  }

  pageChanged(newPage: number): void {
    if (this.sceneMode === SCENE_MODE.Facility) {
      this.pageFacility = newPage;
      this.indexResults(this.currentSearchResultsArr, CMD_TARGETS.WEBGL_MANAGER, this.page, newPage);
      this.page = this.pageFacility;
    } else {
      this.pageMap = newPage;
      this.indexResults(this.currentSearchResultsArr, CMD_TARGETS.MAP_MANAGER, this.page, newPage);
      this.page = this.pageMap;
    }
  }

  resetSearchSession(): void {
    this.pageMap = this.pageFacility = 1;
    this.pageChanged(1);
    this.selectedLayersFilterType = LAYERS_FILTER_TYPES.ALL;
    this.selectedLayersFilterStr = LAYERS_FILTER_TYPES.ALL + '';
    this.tempLayersSelectionArr = [];
    this.selectedLayers = [];
    this.selectedLayersUnModified = [];

    this._mapLocationSearch = true;

    this.cleanSearchResults();
  }

  ngOnInit(): void {
    this.appTheme = this.themeService.getTheme();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sceneMode && changes.sceneMode.previousValue !== SCENE_MODE.Panoramic) {
      if (this.sceneMode === SCENE_MODE.Facility || this.sceneMode === SCENE_MODE.Map) {
        this.currentSearchResultsArr = this.flatResults(
          this.sceneMode === SCENE_MODE.Facility ? this.searchResultsFacility : this.searchResultsMap);
        this.pageChanged(this.sceneMode === SCENE_MODE.Facility ? this.pageFacility : this.pageMap);
      }
    }

    if (this.openedPanel === 'sidebar.search') {
      if (this.selectedType === SEARCH_TYPES.THREE_D_Models && (this.sceneMode === SCENE_MODE.Map || (changes.sceneMode && changes.sceneMode.currentValue === SCENE_MODE.Map))) {
        this.selectedType = SEARCH_TYPES.ALL;
      }
    }

    if (this.openedPanel === 'sidebar.search' && this.searchInput.trim() !== '') {
      this.runSearch();
    }
  }

  public onKeyClick(ev: KeyboardEvent): void {
    const keycode: number = (ev.keyCode ? ev.keyCode : ev.which);
    if (keycode === 13) {
      this.runSearch();
    }
  }

  public pushInnerSearchResult(result: any): void {
    const siteId: string = result.siteId.value;
    const siteObj: ISite = this.allSites.find((site: ISite) => site.id === +siteId);
    if (!siteObj) {
      console.log('OG - NO - Cant find id', siteId, result.name.value);
    }
    const type: string = result.coordinatesType;
    const resultStruct: SingleSearchResult = new SingleSearchResult(result.searchResultType, result.name.value, siteObj);
    resultStruct.position = {x: result.position.x, y: result.position.y, z: result.position.z};
    resultStruct.location = {latitude: result.location.latitude, longitude: result.location.longitude};
    resultStruct.searchObjId = result.id.value;
    resultStruct.originalObjId = resultStruct.searchObjId.replace('SEARCH_ENGINE_RESULT_', '');
    resultStruct.coordinatesType = result.coordinatesType;
    if (type.toLowerCase() === 'facility') {
      // console.log('FACILITY', resultStruct.name);
      if (!this.searchResultsFacility.has(siteObj)) {
        this.searchResultsFacility.set(siteObj, []);
      }
      this.searchResultsFacility.get(siteObj).push(resultStruct);
    } else {
      // console.log('MAPS', resultStruct.name);
      if (!this.searchResultsMap.has(siteObj)) {
        this.searchResultsMap.set(siteObj, []);
      }
      this.searchResultsMap.get(siteObj).push(resultStruct);
    }
  }

  public pushMapSearchResult(result: any): void {
    const resultStruct: SingleSearchResult = new SingleSearchResult(result.searchResultType, result.name.value, this.mapSearchSiteMock);
    resultStruct.position = {x: result.position.x, y: result.position.y, z: result.position.z};
    resultStruct.location = {latitude: result.location.latitude, longitude: result.location.longitude};
    resultStruct.searchObjId = result.id.value;
    resultStruct.coordinatesType = result.coordinatesType;
    if (!this.searchResultsMap.has(this.mapSearchSiteMock)) {
      this.searchResultsMap.set(this.mapSearchSiteMock, []);
    }
    this.searchResultsMap.get(this.mapSearchSiteMock).push(resultStruct);
  }

  public cleanSearchResults(): void {
    this.currentSearchResultsArr = [];
    this.searchResultsFacility = new Map<ISite, SingleSearchResult[]>();
    this.searchResultsMap = new Map<ISite, SingleSearchResult[]>();
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.REMOVE_SEARCH_RESULTS);
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.REMOVE_SEARCH_RESULTS);
  }

  public clearSearchStr(): void {
    this.searchInput = '';
    this.noContentAvailableStr = NO_SEARCH_ENTRY;
    this.cleanSearchResults();
    this.stopCurrentSearch();
  }

  public stopCurrentSearch(): void {
    this.processingSearchRequest = false;
    this.searchApiSvc.cancelPrevSearch();
  }

  public async runSearch(): Promise<void> {
    if (this.searchInput.trim() === '') {
      this.clearSearchStr();
      return;
    }
    const searchModel: SearchInputModel = new SearchInputModel();
    // fill in input string
    searchModel.searchInput = this.searchInput;

    // fill in search type
    searchModel.searchType = this.selectedType;

    // fill in filtered sites
    searchModel.selectedSitesIds = this.selectedSites;

    // fill in filtered layers
    if (searchModel.searchType !== SEARCH_TYPES.ADDRESSES &&
      searchModel.searchType !== SEARCH_TYPES.PANORAMICS &&
      searchModel.searchType !== SEARCH_TYPES.VIEWPOINTS) {
      // ADDRESSES, PANORAMICS and VIEWPOINTS are not allowed to have Layers filter
      searchModel.selectedLayersIds = this.selectedLayers;
    }

    // fill in data for Google search mechanism
    let mapLocationData: any = {isGeoSearch: false};
    if (this._mapLocationSearch && this.sceneMode === SCENE_MODE.Map) {
      let currVp: IViewpoint = this.store.selectSnapshot<IViewpoint>((state: any) => state.StateApp.currViewpoint);
      if (!currVp) {
        console.log('Sanity check - should not happen!');
      }
      if (isNaN(currVp.latitude) || isNaN(currVp.longitude)) {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.FILL_VIEWPOINT_PROPERTIES);
        currVp = this.store.selectSnapshot<IViewpoint>((state: any) => state.StateApp.currViewpoint);
      }
      mapLocationData = {isGeoSearch: true, vpLat: currVp.latitude, vpLng: currVp.longitude};
    }

    this.cleanSearchResults();
    this.processingSearchRequest = true;
    this.searchApiSvc.cancelPrevSearch();
    const allSearchResults: any[] = await this.searchApiSvc.doSearch(searchModel, mapLocationData);
    this.searchResultsFacility = new Map<ISite, SingleSearchResult[]>();
    this.searchResultsMap = new Map<ISite, SingleSearchResult[]>();

    if (isNullOrUndefined(allSearchResults)) {
      // implies that a different search is already running
      return;
    }
    allSearchResults.forEach((result: any) => {

      if (result.searchResultType === 'GOOGLE_PLACE') {
        this.pushMapSearchResult(result);
      } else {
        this.pushInnerSearchResult(result);
      }
    });

    this.sortResults(this.searchResultsFacility);
    this.sortResults(this.searchResultsMap);

    this.currentSearchResultsArr = this.flatResults(
      this.sceneMode === SCENE_MODE.Facility ? this.searchResultsFacility : this.searchResultsMap);

    if (this.currentSearchResultsArr.length === 0) {
      this.noContentAvailableStr = NO_RESULTS_FOUND;
    }

    this.pageMap = this.pageFacility = 1;
    this.pageChanged(1);

    requestAnimationFrame(() => {
      // to avoid change true to false in same painting flow
      this.processingSearchRequest = false;
    });

    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_Search');
  }

  sortResults(arr: Map<ISite, SingleSearchResult[]>): void {
    arr.forEach((results: SingleSearchResult[], site: ISite) => {

      // ABC Sort
      results.sort((A: SingleSearchResult, B: SingleSearchResult) => {
        return A.displayValue.localeCompare(B.displayValue);
      });

      // TYPE Sort
      results.sort((A: SingleSearchResult, B: SingleSearchResult) => {
        if (A.type === B.type) {
          return 0;
        } else if (B.type === 'PLACEMARK') {
          return 1;
        } else if (A.type === 'PLACEMARK') {
          return -1;
        } else if (B.type === 'PLACE') {
          return 1;
        } else if (A.type === 'PLACE') {
          return -1;
        } else if (B.type === 'PANORAMIC_IMAGE') {
          return 1;
        } else if (A.type === 'PANORAMIC_IMAGE') {
          return -1;
        } else {
          return 0;
        }
      });

    });
  }

  sortSites(resultsArr: SingleSearchResult[]): void {

    // Site id sort
    resultsArr.sort((A: SingleSearchResult, B: SingleSearchResult) => {
      return A.site.id - B.site.id;
    });

    // final sort: by Map location, current site, and the rest
    resultsArr.sort((A: SingleSearchResult, B: SingleSearchResult) => {
      if (B.site.name === this.mapSearchSiteMock.name) {
        return 1;
      } else if (A.site.name === this.mapSearchSiteMock.name) {
        return -1;
      } else if (B.site.name === this.activeSite.name) {
        return 1;
      } else if (A.site.name === this.activeSite.name) {
        return -1;
      } else {
        // ABC Sort
        return A.site.name.localeCompare(B.site.name);
      }
    });
  }

  flatResults(arr: Map<ISite, SingleSearchResult[]>): SingleSearchResult[] {
    let retArr: SingleSearchResult[] = [];
    let onlyCurrentSiteResults: boolean = true;
    arr.forEach((results: SingleSearchResult[], site: ISite) => {
      if (!site.isDefault || site.name === MAP_LOCATION_SITE_NAME) {
        onlyCurrentSiteResults = false;
      }
      const siteName: string = site.isDefault ?
        (site.name === this.mapSearchSiteMock.name ? this.mapSearchSiteMock.name : 'Current Site')
        : site.name;
      const defSiteResults: SingleSearchResult[] = [new SingleSearchResult('site', siteName, site), ...results];
      retArr = retArr.concat(defSiteResults);
    });
    this.sortSites(retArr);

    let newResultsLength: number = retArr.length;
    let counter: number = 1;
    while (newResultsLength > this.maxResultPerPage) {
      const currentIndex: number = this.maxResultPerPage * counter;
      if (retArr[currentIndex].type !== 'site') {
        const testerIndex: number = currentIndex - 1;
        const siteName: string = retArr[testerIndex].site.isDefault ?
          (retArr[testerIndex].site.name === this.mapSearchSiteMock.name ? this.mapSearchSiteMock.name : 'Current Site')
          : retArr[testerIndex].site.name;
        retArr.splice(currentIndex, 0,
          new SingleSearchResult('site', siteName/*+ ' - Cont...'*/, retArr[testerIndex].site));
        newResultsLength -= (this.maxResultPerPage - 1);
      } else {
        newResultsLength -= (this.maxResultPerPage);
      }
      counter++;
    }

    if (onlyCurrentSiteResults) {
      // if the results are only from current site, no "Site Label" needed
      retArr = retArr.filter((result: SingleSearchResult) => {
        return result.type !== 'site';
      });
    }

    return retArr;
  }

  indexResults(results: SingleSearchResult[], sendTo: CMD_TARGETS, prevPage: number, newPage: number): SingleSearchResult[] {
    let letterIndex = 0;
    const indexedResults: SingleSearchResult[] = [];
    let pageIndex: number = this.maxResultPerPage * (prevPage - 1);
    for (let i = pageIndex; i < (this.maxResultPerPage * prevPage) && i < results.length; i++) {
      results[i].emptyLetterIndex();
    }
    pageIndex = this.maxResultPerPage * (newPage - 1);
    for (let i = pageIndex; i < (this.maxResultPerPage * newPage) && i < results.length; i++) {
      const currRes: SingleSearchResult = results[i];
      if (currRes.site.isDefault && currRes.type !== 'site' && currRes.type !== 'VIEWPOINT') {
        const letter: string = String.fromCharCode(97 + letterIndex++).toUpperCase();
        results[i].letterIndex = letter;
        indexedResults.push(results[i]);
      }
    }
    this.cmdRouterSvc.sendActionCmd(sendTo, CMD_ACTIONS.SHOW_SEARCH_RESULTS, {searchResults: indexedResults});
    return results;
  }

  selectSearchType(value: SEARCH_TYPES): void {
    // const lastSelectedType: SEARCH_TYPES = this.selectedType;
    this.selectedType = value;
    if (this.selectedLayersFilterType === LAYERS_FILTER_TYPES.SELECTION) {
      if (this.selectedType === SEARCH_TYPES.STATUS_PLACEMARKS) {
        const allStatusLayers: LayerTreeNode[] = this.allLayersFlatten.filter((layer: LayerTreeNode) => layer.layerType === LAYER_TYPES.Status);
        this.selectedLayers = this.selectedLayersUnModified.filter((layerId: string) => !!allStatusLayers.find((layer: LayerTreeNode) => layer.id === layerId));
      } else if (this.selectedType === SEARCH_TYPES.URL_PLACEMARKS ||
        this.selectedType === SEARCH_TYPES.FILE_PLACEMARKS) {
        const allInfoLayers: LayerTreeNode[] = this.allLayersFlatten.filter((layer: LayerTreeNode) => layer.layerType === LAYER_TYPES.Site);
        this.selectedLayers = this.selectedLayersUnModified.filter((layerId: string) => !!allInfoLayers.find((layer: LayerTreeNode) => layer.id === layerId));
      } else {
        this.selectedLayers = this.selectedLayersUnModified;
      }
      this.selectedLayersFilterStr = this.selectedLayers.length + this.selectedLayersFilterType;
    }

    this.updatePmSubTypeOpened();
    this.updateLayersFilterVisibility();
    this.runSearch();
  }

  updatePmSubTypeOpened(): void {
    if (this.selectedType !== SEARCH_TYPES.PLACEMARKS && this.selectedType !== SEARCH_TYPES.URL_PLACEMARKS &&
      this.selectedType !== SEARCH_TYPES.FILE_PLACEMARKS && this.selectedType !== SEARCH_TYPES.STATUS_PLACEMARKS) {
      this.pmSubTypeMenuOpened = false;
    } else {
      this.pmSubTypeMenuOpened = true;
    }
  }

  updateLayersFilterVisibility(): void {
    if (this.selectedType === SEARCH_TYPES.ADDRESSES || this.selectedType === SEARCH_TYPES.VIEWPOINTS || this.selectedType === SEARCH_TYPES.PANORAMICS) {
      this.layersFilterVisible = false;
    } else {
      this.layersFilterVisible = true;
    }
  }

  selectSiteFilter(value: SITES_FILTER_TYPES, reRunSearch: boolean = true): void {
    const lastFilterValue: SITES_FILTER_TYPES = this.selectedSiteFilterType;
    this.selectedSiteFilterType = value;
    this.selectedSiteFilterStr = value + '';
    switch (value) {
      case SITES_FILTER_TYPES.CUREENT_SITE: {
        this.selectedSites = [this.activeSite.id.toString()];
        if (reRunSearch) {
          this.runSearch();
        }
        // console.log('Selected Current', this.selectedSites);
        break;
      }
      case SITES_FILTER_TYPES.ALL: {
        this.selectedSites = this.allSites.map((site: ISite) => site.id.toString());
        if (reRunSearch) {
          this.runSearch();
        }
        // console.log('Selected ALL', this.selectedSites);
        break;
      }
      case SITES_FILTER_TYPES.SELECTION: {
        const inputsBinding: InputsBindingsModel = new Map<string, any>([
          [ 'value', this.tempSiteSelectionArr]
        ]);
        const dialog: DialogRef = this.dialogService.createDialog(SitesSelectionDialogComponent, DialogType.Modal, inputsBinding, null, DIALOG_DEFAULT_WIDTH_PX, window.innerHeight / 2);
        dialog.onClose$().subscribe((model: DialogModel) => {
          if (model.userAction === 'select') {
            this.selectedSites = model.getData('value');
            this.tempSiteSelectionArr = this.selectedSites;
            this.selectedSiteFilterStr = ( this.allSites.length === this.selectedSites.length ) ? SITES_FILTER_TYPES.ALL : this.selectedSites.length + this.selectedSiteFilterStr;
            if (reRunSearch) {
              this.runSearch();
            }
            // console.log('Selected SOME', this.selectedSites);
          } else {
            // console.log('SITES - replacing to ', lastFilterValue);
            this.selectedSiteFilterType = lastFilterValue;
            if (lastFilterValue === SITES_FILTER_TYPES.SELECTION) {
              this.selectedSiteFilterStr = this.selectedSites.length + lastFilterValue;
            } else {
              this.selectedSiteFilterStr = lastFilterValue + '';
            }
          }
        });
        break;
      }
    }
  }

  selectLayersFilter(value: LAYERS_FILTER_TYPES, reRunSearch: boolean = true): void {
    const lastFilterValue: LAYERS_FILTER_TYPES = this.selectedLayersFilterType;
    this.selectedLayersFilterType = value;
    this.selectedLayersFilterStr = value + '';
    switch (value) {
      case LAYERS_FILTER_TYPES.ALL: {
        this.selectedLayers = this.allLayersFlatten.map((layer: LayerTreeNode) => layer.id);
        this.selectedLayersUnModified = this.selectedLayers;
        if (reRunSearch) {
          this.runSearch();
        }
        // console.log('Selected layers - ALL', this.selectedLayers);
        break;
      }
      case LAYERS_FILTER_TYPES.SELECTION: {
        const inputsBinding: InputsBindingsModel = new Map<string, any>([
          [ 'value', this.tempLayersSelectionArr]
        ]);
        if (this.selectedType === SEARCH_TYPES.STATUS_PLACEMARKS) {
          inputsBinding.set('ignoredLayersTypes', [LAYER_TYPES.Site]);
        } else if (this.selectedType === SEARCH_TYPES.FILE_PLACEMARKS || this.selectedType === SEARCH_TYPES.URL_PLACEMARKS) {
          inputsBinding.set('ignoredLayersTypes', [LAYER_TYPES.Status]);
        }
        const resizeableEdges: Edges = new Edges(false, false, true, true);
        const dialog: DialogRef = this.dialogService.createDialog(LayersSelectionDialogComponent, DialogType.Modal, inputsBinding, resizeableEdges,
          -1, window.innerHeight / 2, -1, DIALOG_DEFAULT_WIDTH_PX);
        const latestSelectedLayers: string[] = this.selectedLayers;
        dialog.onClose$().subscribe((model: DialogModel) => {
          if (model.userAction === 'select') {
            this.selectedLayers = model.getData('value');
            if (this.selectedType !== SEARCH_TYPES.STATUS_PLACEMARKS &&
              this.selectedType !== SEARCH_TYPES.FILE_PLACEMARKS && this.selectedType !== SEARCH_TYPES.URL_PLACEMARKS) {
              this.selectedLayersUnModified = this.selectedLayers;
              this.tempLayersSelectionArr = this.selectedLayers;
            } else {
              const uncheckedLayers: string[] = latestSelectedLayers.filter((id: string) => this.selectedLayers.indexOf(id) < 0);
              uncheckedLayers.forEach((id: string) => {
                const indexOfUnCheckedLayer: number = this.tempLayersSelectionArr.indexOf(id);
                this.tempLayersSelectionArr.splice(indexOfUnCheckedLayer, 1);
              });
              const checkedLayers: string[] = this.selectedLayers.filter((id: string) => latestSelectedLayers.indexOf(id) < 0);
              checkedLayers.forEach((id: string) => {
                this.tempLayersSelectionArr.push(id);
              });
            }

            this.selectedLayersFilterStr = ( this.allLayersFlatten.length === this.selectedLayers.length ) ? LAYERS_FILTER_TYPES.ALL : this.selectedLayers.length + this.selectedLayersFilterStr;
            if (reRunSearch) {
              this.runSearch();
            }
            // console.log('Selected layers - SOME', this.selectedLayers);
          } else {
            // console.log('LAYERS - replacing to ', lastFilterValue);
            this.selectedLayersFilterType = lastFilterValue;
            if (lastFilterValue === LAYERS_FILTER_TYPES.SELECTION) {
              this.selectedLayersFilterStr = this.selectedLayers.length + lastFilterValue;
            } else {
              this.selectedLayersFilterStr = lastFilterValue + '';
            }
          }
        });
        break;
      }
    }
  }

  public loadSite(site: ISite): void {
    const notificationInputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'type', 'warning'],
      [ 'title', 'Load Site' ],
      [ 'message', `Are you sure you want to load the '${site.name}' site?<br>This may take some time.`],
      [ 'buttonsInfo', [new ValueAndDisplay('cancel', 'Cancel'),
        new ValueAndDisplay('yes', 'Yes')]]
    ]);
    const notificationDialog: DialogRef = this.dialogService.createNotificationDialog(notificationInputsBinding);

    notificationDialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'yes') {
        this.loadSiteFromSearchPanel = true;
        this.stopCurrentSearch();
        this.resetSearchSession();
        this.sitesLoaderSvc.loadSite(site, this.sceneMode);
      }
    });
  }

  public displayResultOnGraphic(selectedResult: SingleSearchResult): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SELECT_UNSELECT_MODEL, {data: {select: false, modelId: null}});
    if (selectedResult.type === 'VIEWPOINT' || selectedResult.type === 'PLACE') {
      this.openResult(selectedResult);
    } else {
      if (selectedResult.coordinatesType.toLowerCase() === 'facility') {
        if (selectedResult.type === 'MODEL') {
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.ZOOM_IN_TO_OBJECT, {data: {objectType: ViewerObjectType.MODEL, id: selectedResult.originalObjId}});
        } else {
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CENTER_SEARCH_RESULT,
            {x: selectedResult.position.x, z: selectedResult.position.z});
        }
      } else {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CENTER_SEARCH_RESULT,
          {lat: selectedResult.location.latitude, lng: selectedResult.location.longitude});
      }
    }
  }

  public async openResult(selectedResult: SingleSearchResult): Promise<void> {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SELECT_UNSELECT_MODEL, {data: {select: false, modelId: null}});
    switch (selectedResult.type) {
      case 'PLACE': {
        const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
        let selectedPm: IPlacemark = placemarks.find((pm: IPlacemark) => pm.id === selectedResult.originalObjId);
        if (!selectedPm) {
          // we will get here if pm is part of unloaded Zone where we didn't load the placemark to store
          selectedPm = await this.placemarksApiSvc.getPlacemarkById(selectedResult.originalObjId, selectedResult.type);
        }
        const target: CMD_TARGETS = (this.sceneMode === SCENE_MODE.Map) ? CMD_TARGETS.MAP_MANAGER : CMD_TARGETS.WEBGL_MANAGER;
        this.cmdRouterSvc.sendActionCmd(target, CMD_ACTIONS.OPEN_CENTER_SEARCH_RESULT, {pm: selectedPm});
        break;
      }
      case 'PLACEMARK': {
        this.statusBar.addNewStatus(MessagesBank.OPENING_PM);
        const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
        let selectedPm: IPlacemark = placemarks.find((pm: IPlacemark) => pm.id === selectedResult.originalObjId);
        if (!selectedPm) {
          // we will get here if pm is part of unloaded Zone where we didn't load the placemark to store
          selectedPm = await this.placemarksApiSvc.getPlacemarkById(selectedResult.originalObjId, selectedResult.type);
        }
        this.placemarkPreviewService.showPlacemarkContent(selectedPm.category,
          selectedPm.placemarkUiType, selectedPm.name, selectedPm.settings.width, selectedPm.settings.height, selectedPm.url, selectedPm.html);
        this.statusBar.removeStatus(MessagesBank.OPENING_PM);
        break;
      }
      case 'VIEWPOINT': {
        const viewpoints: IViewpoint[] = this.store.selectSnapshot<IViewpoint[]>((state: any) => state.StateViewpoints.viewpoints);
        let selectedVp: IViewpoint = viewpoints.find((vp: IViewpoint) => vp.id === selectedResult.originalObjId);
        if (!selectedVp) {
          // we will get here if vp is part of unloaded Zone where we didn't load the vp to store
          selectedVp = await this.viewpointsApiSvc.getViewpointById(selectedResult.originalObjId);
        }
        const target: CMD_TARGETS = (selectedVp.parent === 'WEBGL') ? CMD_TARGETS.WEBGL_MANAGER :
          (selectedVp.parent === 'MAPS') ? CMD_TARGETS.MAP_MANAGER : CMD_TARGETS.PANORAMIC_MANAGER;
        this.cmdRouterSvc.sendActionCmd(target, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: selectedVp});
        break;
      }
      case 'PANORAMIC_IMAGE': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.GO_TO_PANO, {pmId: selectedResult.originalObjId});
        break;
      }
      case 'SCAN_PLACEMARK': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.OPEN_SCAN_PM_OPTIONS, {pmId: selectedResult.originalObjId, position: selectedResult.position});
        break;
      }
      case 'MODEL': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SELECT_UNSELECT_MODEL, {data: {select: true, modelId: selectedResult.originalObjId, zoomInToObject: true}});
        break;
      }
    }
  }
}
