import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, SimpleChange} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {LayersState} from '../../../../Store/layers.state/layers.state';
import {
  ILayer,
  IRemovedLayer,
  LAYER_TYPES,
  LAYER_VISIBILITY,
  STATUS_LAYER_MASK,
} from '../../../../Store/layers.state/layers.model';
import {ZonesState} from '../../../../Store/zones.state/zones.state';
import {IRemovedZone, IZone} from '../../../../Store/zones.state/zones.model';
import {ModelsState} from '../../../../Store/models.state/models.state';
import {I3DModel, ILayout, IRemovedObject} from '../../../../Store/models.state/models.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService, IActionCmd} from '../../../../services/cmd-router.service';
import {DialogModel} from '../../../../common/Models/Dialog/dialog.model';
import {DialogComSvc} from '../../../../services/modeless-com.service';
import {DialogRef} from '../../../../common/Forms/Dialog-types/dialog-ref';
import {ServerApi} from '../../../../services/api.services/server.api';
import {SitesLoaderSvc} from '../../../../services/api.services/sites.loader.svc';
import {Models2DApiSvc} from '../../../../services/api.services/models.2D.api.svc';
import {ViewpointsApiSvc} from '../../../../services/api.services/viewpoints.api.svc';
import {PlacemarksState} from '../../../../Store/placemarks.state/placemarks.state';
import {
  CoordinatesType,
  IPlacemark,
  IPosition,
  TEMP_PLACEMARK_ICON,
  WEBGL_OBJECT_MODE
} from '../../../../Store/placemarks.state/placemarks.model';
import {LibIconsService} from '../../../../services/lib-icons.service';
import {IViewpoint} from '../../../../Store/viewpoints.state/viewpoints.model';
import {generalLayersIds} from '../scene.container';
import {SetSelectedMode, UpdateCurrViewpointParameters} from '../../../../Store/app.state/app.actions';
import {APPLICATION_MODE, IAppConf, SCENE_MODE} from '../../../../Store/app.state/app.model';
import {IRegistrationPoint, ISite} from '../../../../Store/sites.state/sites.model';
import {isNullOrUndefined, isUndefined} from 'util';
import {MessagesBank, StatusService} from '../../../../services/status.service';
import {PlacemarkPreviewService} from '../../../../services/placemark-preview.service';
import {ContainersHelper} from './containers-helper';
import {Models3DApiSvc} from '../../../../services/api.services/models.3D.api.svc';
import {StatusCommentsIcon} from '../../../../Store/discussions.state/discussions.model';
import {SetPlacemarksWithComments} from '../../../../Store/discussions.state/discussions.actions';
import {SingleSearchResult} from '../../side-bar.container/subMenus/search-res-menu/search-res-menu.component';
import {PlacemarksApiSvc} from '../../../../services/api.services/placemarks.api.svc';
import {cloneDeep} from 'lodash';
import {Actions} from '../../../../services/permissions-manager';
import {AppState} from '../../../../Store/app.state/app.state';
import {WebshareApiSvc} from 'src/app/services/api.services/webshare.api.svc';
import {ObjectSiteValidatorService} from '../../../../services/object-site-validator.service';
import {ApiTools} from '../../../../services/api.services/api.tools';
import {DiscussionsState} from '../../../../Store/discussions.state/discussions.state';
import {SseApiSvc} from '../../../../services/api.services/SSE/sse.api.svc';
import {SessionApiSvc} from 'src/app/services/api.services/session.api.svc';
import {InputsBindingsModel} from 'src/app/common/Models/Dialog/inputs-binding.model';
import {NotificationDialogComponent} from 'src/app/common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {DialogService} from 'src/app/services/dialogs.service';
import {OutgoingMessagesService} from 'src/app/services/external/outgoing-messages.service';
import {CommonUtilitySvc} from 'src/app/services/common-utility.service';
import {ViewerObjectType} from 'src/app/common/Models/UI/viewer-object-type.enum';
import {STATUS_LAYER_ICON_PRE} from 'src/app/Store/layers.state/layers.const.utils';

interface ILatLngBounds {
  minX: number;
  minZ: number;
  maxX: number;
  maxZ: number;
}

interface IComData {
  cmd: string;
  args: any;
}

enum frameLoadedStatus {
  NOT_LOADED = 0,
  AFTER_WEBGL_FRAME_INIT = 1,
  AFTER_FRAME_LOADED = 2,
  ALL_LOADED = 3
}

@Component({
  selector: 'ins-webgl-container',
  templateUrl: './webgl-container.component.html',
  styleUrls: ['./webgl-container.component.scss']
})
export class WebglContainerComponent implements OnInit, OnChanges {

  @Input() openedPanel: string;
  public lastOpenedPanel: string;
  @Input() activeSite: ISite;
  @Output() openContextMenu: EventEmitter<any> = new EventEmitter();

  @Select(LayersState.getLayers) allLayers$: Observable<ILayer[]>;
  @Select(LayersState.getVisiblityChangedLayers) layers$: Observable<ILayer[]>;
  @Select(LayersState.getChangedLayers) changedLayers$: Observable<ILayer[]>;
  @Select(ZonesState.getZones) allZones$: Observable<IZone[]>;
  @Select(ZonesState.getVisiblityChangedZones) visibleZones$: Observable<IZone[]>;
  @Select(ZonesState.getRemovedZones) removedZones$: Observable<IRemovedZone>;
  @Select(ModelsState.getLayouts) layouts$: Observable<ILayout[]>;
  @Select(ModelsState.get3DModels) models3D$: Observable<I3DModel[]>;
  @Select(ModelsState.getNew3DModels) new3DModels$: Observable<I3DModel[]>;
  @Select(ModelsState.getUpdated3DModels) updated3DModels$: Observable<I3DModel[]>;
  @Select(ModelsState.getRemovedModelObj) removedModelObjs$: Observable<IRemovedObject[]>;
  // @Select(ModelsState.isEmptyScene) isEmptySceneObjs$: Observable<boolean>;
  @Select(PlacemarksState.getRemovedPlacemarkId) removedPlacemarkId$: Observable<string>;
  @Select(PlacemarksState.getPlacemarksByType(CoordinatesType.FACILITY, WEBGL_OBJECT_MODE.NEW)) newPlacemarks$: Observable<IPlacemark[]>;
  @Select(PlacemarksState.getPlacemarksByType(CoordinatesType.FACILITY, WEBGL_OBJECT_MODE.UPDATED)) updatePlacemarks$: Observable<IPlacemark[]>;
  @Select(LayersState.getRemovedLayers) removedLayers$: Observable<IRemovedLayer[]>;
  @Select(AppState.getNumberOfObjectsToLoad) numberOfObjectToLoad$: Observable<number>;
  @Select(DiscussionsState.getSelectedPlacemark) getSelectedPlacemark$: Observable<IPlacemark>;

  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  public sceneMode: SCENE_MODE;

  // temp vars that should be separate to different logic managers
  tempUnHandledNewPlacemarks: IPlacemark[] = [];

  // scene vars
  frameCodeLoaded: boolean = false;
  iframeSrc: string;
  frameWin: Window;
  frameLoadedStatus: frameLoadedStatus = frameLoadedStatus.NOT_LOADED;
  webglCmdQueue: IComData[] = [];

  searchResultsPms: Map<string, SingleSearchResult> = new Map();

  public webshareAuthPending: boolean = false;
  public zonesVisibilityMap: Map<string, boolean> = new Map();


  @Select(AppState.getAppConf) appConf$: Observable<IAppConf>;
  public appConf: IAppConf;

  constructor(private cmdRouterSvc: CmdRouterService, public dialogComService: DialogComSvc, private dialogService: DialogService,
              public serverApi: ServerApi, public libIconSrv: LibIconsService, public store: Store, private outgoingMessageService: OutgoingMessagesService,
              private statusBar: StatusService, private placemarkPreviewService: PlacemarkPreviewService, private commonUtilitySvc: CommonUtilitySvc,
              private containersHelper: ContainersHelper, private models3DApiSvc: Models3DApiSvc, private placemarksApiSvc: PlacemarksApiSvc,
              private objectSiteValidatorService: ObjectSiteValidatorService, private sitesLoaderSvc: SitesLoaderSvc, private sessionAPiSvc: SessionApiSvc,
              private models2DApiSvc: Models2DApiSvc, private viewpointsApiSvc: ViewpointsApiSvc, private sseSvc: SseApiSvc, private webshareApiSvc: WebshareApiSvc) {
    window.addEventListener('message', this.handleIFrameCmds.bind(this));
    if (localStorage.getItem("angularjsApp") == "yes") {
      this.iframeSrc = `${environment.baseServerUrl}/${environment.externalApp}/IndoorMode`;
    } else {
      this.iframeSrc = environment.webglAppUrl;
    }

    this.sendCmdToIFrame({cmd: 'showLoadingObjectsLogs', args: [ApiTools.showLogsInWebGL]});
    this.selectedMode$.subscribe((mode: SCENE_MODE) => {
      if (this.sceneMode == SCENE_MODE.Panoramic && mode == SCENE_MODE.Facility) {
        this.sendCmdToIFrame({cmd: 'executeSetViewpointProperties', args: undefined});
      }
      this.sceneMode = mode;
    });

    this.appConf$.subscribe( (appConf: IAppConf) => {
      this.appConf = appConf;
      this.setAppMode(this.appConf.appMode);
    });
  }

  private handleObjectMoveCmd(data: DialogModel): void {
    this.sendCmdToIFrame({cmd: 'moveObjects', args: [data['deltaLatitude'], data['deltaAltitude'], data['deltaLongitude'], data['deltaRotation'] ? data['deltaRotation'] : 0]});
  }

  private handleModelEditCmd(data: DialogModel): void {
    this.coordinatesChanged(data['latitude'], data['altitude'], data['longitude'], data['rotation']);
    const rotation: any = data['rotation'];
    if (rotation) {
      this.sendCmdToIFrame({cmd: 'setPickedModelRotation', args: [rotation]});
    }
    const isSelectionCleared: any = data['isSelectionCleared'];
    if (isSelectionCleared) {
      // need to send original values to webgl and then stop the mode
      const isremoveTempModel: any = data['removeTempModel'];
      if (isremoveTempModel) {
        this.sendCmdToIFrame({cmd: 'deleteTemporaryModels', args: undefined});
      }
      this.setModelEditingMode(false);
      this.setModelEditingMode(true);
    }
  }

  private handleLayoutEditCmd(data: DialogModel): void {

    const removeSelectedLayoutIdNext: boolean = data['removeSelectedLayoutIdNext'];
    if (removeSelectedLayoutIdNext) {
      this.sendCmdToIFrame({cmd: 'removeSelectedLayoutIdNext', args: undefined});
    }

    const isSelectionCleared: boolean = data['isSelectionCleared'];
    if (isSelectionCleared) {
      this.sendCmdToIFrame({cmd: 'deleteTemp2DLayout', args: [undefined, true]});
    }

    const recoverOrigLayout: boolean = data['recoverOrigLayout'];
    if (recoverOrigLayout) {
      this.sendCmdToIFrame({cmd: 'recoverOrigLayout', args: undefined});
    }
  }

  private coordinatesChanged(x: number, y: number, z: number, r : number): void {
    if (x !== undefined) {
      this.sendCmdToIFrame({cmd: 'setPickedModelX', args: [x]});
    }
    if (y !== undefined) {
      this.sendCmdToIFrame({cmd: 'setPickedModelY', args: [y]});
    }
    if (z !== undefined) {
      this.sendCmdToIFrame({cmd: 'setPickedModelZ', args: [z]});
    }
    if(r !== undefined) {
      this.sendCmdToIFrame({cmd: 'setPickedModelRotation', args: [r]});
    }
  }

  private cancelCommentIconOnPlacemark(placemarksWithComments: Map<string, string>): void {
    placemarksWithComments.forEach((iconUrl, pmId) => {
      this.sendCmdToIFrame({cmd: 'changePlacemarkIcon', args: [pmId, iconUrl]});
    });
  }

  private showPlacemarkCommentsIcon(placemarkToEdit: IPlacemark, isSelectedPlacemark: boolean = false): void {
    if (placemarkToEdit) {
      let statusIconUrl: string;
      // console.log('placemarkToEdit: ', placemarkToEdit);
      const imageURL: string = this.setIconForPM(placemarkToEdit.parentLayerId, placemarkToEdit);
      this.store.dispatch(new SetPlacemarksWithComments(placemarkToEdit.id, imageURL));
      // console.log('placemarkToEdit.html: ', imageURL);
      if (isSelectedPlacemark) {
        if (placemarkToEdit.statusType === 'STATUS' || placemarkToEdit.statusType === 'CHECKLIST' || placemarkToEdit.scanUUID ||
          placemarkToEdit.category === 'PANORAMIC' || placemarkToEdit.category === 'ADDRESS') {
          statusIconUrl =
            imageURL.substring(0, imageURL.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS_SELECTED;
        } else {
          statusIconUrl =
            imageURL.substring(0, imageURL.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS_SELECTED
            + imageURL.substring(imageURL.indexOf('?'));
        }
      } else {
        if (placemarkToEdit.statusType === 'STATUS' || placemarkToEdit.statusType === 'CHECKLIST' || placemarkToEdit.scanUUID ||
          placemarkToEdit.category === 'PANORAMIC' || placemarkToEdit.category === 'ADDRESS') {
          statusIconUrl =
            imageURL.substring(0, imageURL.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS;
        } else {
          statusIconUrl =
            imageURL.substring(0, imageURL.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS
            + imageURL.substring(imageURL.indexOf('?'));
        }
      }

      if (statusIconUrl && statusIconUrl !== '') {
        // console.log('statusIconUrl: ', statusIconUrl);
        this.sendCmdToIFrame({cmd: 'changePlacemarkIcon', args: [placemarkToEdit.id, statusIconUrl]});
      }
    }
  }

  ngOnInit(): void {
    const iframeElem: HTMLIFrameElement = document.getElementById('webglFrame') as HTMLIFrameElement;
    this.frameWin = iframeElem.contentWindow;
    this.initActionCmdHandler();
    this.setLayersListeners();
    this.setZonesListener();

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleActiveSiteChange(changes.activeSite);
    this.handleOpenedPanelChange(changes.openedPanel);
  }

  private handleActiveSiteChange(activeSiteChange: SimpleChange): void {
    if (activeSiteChange !== undefined && (activeSiteChange.currentValue || activeSiteChange.previousValue)) {
      const removeLayouts = this.shouldRemoveLayouts(activeSiteChange);
      this.prepareSceneForNewRegistrationPoints(removeLayouts, false);
      this.updatePlacemarksSettings(activeSiteChange.currentValue);
    }
  }

  private shouldRemoveLayouts(activeSiteChange: SimpleChange): boolean {
    return activeSiteChange.currentValue && activeSiteChange.previousValue ? activeSiteChange.currentValue.id !== activeSiteChange.previousValue.id : true;
  }

  private updatePlacemarksSettings(activeSite: ISite): void {
    if (activeSite) {
      if (activeSite.placemarkLOD != null) {
        this.sendCmdToIFrame({cmd: 'setPlacemarksLOD', args: [activeSite.placemarkLOD]});
      }
      if (activeSite.placemarkClustering != null) {
        this.sendCmdToIFrame({cmd: 'setPlacemarkClustering', args: [activeSite.placemarkClustering]});
      }
    }
  }

  private handleOpenedPanelChange(openedPanelChange: SimpleChange): void {
    if (openedPanelChange && this.openedPanel !== 'sidebar.search') {
      this.hideSearchPMMarkers();
    }
  }

  public hideSearchPMMarkers(): void {
    this.removeSearchPMMarkers(true);
  }

  public displaySearchPMMarkers(): void {
    this.searchResultsPms.forEach((value: SingleSearchResult, id: string) => {
      this.addSearchResultPlacemark(value);
    });
  }

  public initActionCmdHandler(): void {
    this.cmdRouterSvc.actionCmdListener$(CMD_TARGETS.WEBGL_MANAGER)
      .subscribe((actionCmd: IActionCmd) => {
        switch (actionCmd.action) {
          case CMD_ACTIONS.CLEAR_SCENE: {
            const siteLayersOnly: boolean = actionCmd.options['siteLayersOnly'];
            this.zonesVisibilityMap.clear();
            this.clearScene(siteLayersOnly);
            this.createEmptyGrid();
            break;
          }
          case CMD_ACTIONS.FIT: {
            this.sendCmdToIFrame({cmd: 'zoomToFit', args: undefined});
            break;
          }
          case CMD_ACTIONS.MODEL_UPLOAD_MODE: {
            this.setModelUploadingMode(true);
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onChanges$().subscribe((data: DialogModel) => {
              const tempFilesUrls: string[] = data['tempFiles'];
              const useOriginFrameFromCAD: boolean = data['useOriginFrameFromCAD'];
              if (useOriginFrameFromCAD !== undefined) {
                if (useOriginFrameFromCAD) {
                  this.sendCmdToIFrame({cmd: 'resetUploadedModel', args: undefined});
                } else {
                  this.sendCmdToIFrame({cmd: 'centerUploadedModel', args: undefined});
                }
              }
              if (tempFilesUrls) {
                this.changeNewModelUpload(true, tempFilesUrls);
              } else {
                this.handleModelEditCmd(data);
              }
            });
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              this.changeNewModelUpload(false);
            });
            break;
          }
          case CMD_ACTIONS.MODEL_EDIT_MODE: {
            this.setModelEditingMode(true);
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onChanges$().subscribe((data: DialogModel) => {
              // The model needs to move on the webgl scene
              this.handleModelEditCmd(data); // , data['isSelectionCleared']);
            });
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              this.setModelEditingMode(false);
            });
            break;
          }
          case CMD_ACTIONS.MODEL_DELETE_MODE: {
            this.setModelDeleteMode(true);
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onChanges$().subscribe((data: DialogModel) => {
              const modelIdToFree: string = data['freeElementId'] as string;
              if (modelIdToFree !== undefined) {
                this.sendCmdToIFrame({cmd: 'deleteObjectFromTemperory', args: [modelIdToFree, ViewerObjectType.MODEL]});
              }
            });
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              // get out of the delete model mode
              this.setModelDeleteMode(false);
            });
            break;
          }
          case CMD_ACTIONS.SET_MOVE_OBJECTS_MODE: {
            if (actionCmd.options['moveObjectsMode']) {
              this.setMoveObjectsMode(true);
              const dialogRef: DialogRef = actionCmd.options['dialogRef'];
              dialogRef.onChanges$().subscribe((data: DialogModel) => {
                const objectIdToFree: string = data['freeElementId'] as string;
                const objectType: string = data['objectType'];
                if (objectIdToFree !== undefined) {
                  this.sendCmdToIFrame({cmd: 'deleteObjectFromTemperory', args: [objectIdToFree, objectType]});
                }
                if (data.hasOwnProperty('deltaLatitude') || data.hasOwnProperty('deltaAltitude') || 
                  data.hasOwnProperty('deltaLongitude') || data.hasOwnProperty('deltaRotation')) {
                    this.handleObjectMoveCmd(data);
                }
              });
              dialogRef.onClose$().subscribe((data: DialogModel) => {
                data.userAction == 'cancel' && this.setMoveObjectsMode(false, true);
              });
            } else {
              const revertObjectsMovement = actionCmd.options['revertObjectsMovement'];
              this.setMoveObjectsMode(false, revertObjectsMovement)
            }
            break;
          }
          case CMD_ACTIONS.GO_TO_TOP_VIEW: {
            this.sendCmdToIFrame({cmd: 'goToTopView', args: undefined});
            break;
          }
          case CMD_ACTIONS.VP_NEW_EDIT_MODE: {
            const newVpMode: boolean = actionCmd.options['newMode'];
            this.updateViewpointProperties(true, newVpMode);
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              // get out of the listen change mode
              this.updateViewpointProperties(false);
            });
            break;
          }
          case CMD_ACTIONS.CREATE_PLACEMARK: {
            const newMode: boolean = !!actionCmd.options['newMode'];
            this.setPlacemarkEditingMode(true, newMode);
            this.sendCmdToIFrame({cmd: 'hideMultiplePlacemarks', args: undefined});
            const position: IPosition = actionCmd.options['positionProperty'];
            if (newMode) {
              const createAtCenter: boolean = actionCmd.options['createAtCenter'];
              if (isUndefined(position)) {
                // TODO: url change
                this.sendCmdToIFrame({cmd: 'createTempPlacemark', args: [environment.windowOrigin + '/' + TEMP_PLACEMARK_ICON, createAtCenter, undefined, undefined, undefined]});
              } else {
                // TODO: url change
                this.sendCmdToIFrame({cmd: 'createTempPlacemark', args: [environment.windowOrigin + '/' + TEMP_PLACEMARK_ICON, createAtCenter, position.x, position.y, position.z]});
              }
            } 
            // else {
            //   this.sendCmdToIFrame({cmd: 'startPlacemarkEditingMode', args: undefined /*[window.location.origin + '/' + TEMP_PLACEMARK_ICON, position.x, position.y, position.z]*/});
            // }

            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onChanges$().subscribe((data: DialogModel) => {
              if (data['latitude'] && !isNaN(Number(data['latitude']))) {
                this.sendCmdToIFrame({cmd: 'setPickedPlacemarkX', args: [data['latitude']]});
              } else if (data['longitude'] && !isNaN(Number(data['longitude']))) {
                this.sendCmdToIFrame({cmd: 'setPickedPlacemarkZ', args: [data['longitude']]});
              } else if (data['altitude'] && !isNaN(Number(data['altitude']))) {
                this.sendCmdToIFrame({cmd: 'setPickedPlacemarkY', args: [data['altitude']]});
              } else if (data['name']) {
                this.sendCmdToIFrame({cmd: 'setPickedPlacemarkName', args: [data['name']]});
              } else if (data['layerId']) {
                this.sendCmdToIFrame({cmd: 'setPickedPlacemarkIcon',
                  args: [this.setIconForPM(data['layerId'])]});
              }
            });

            dialogRef.onClose$().subscribe((data: DialogModel) => {
              if (newMode) {
                this.sendCmdToIFrame({cmd: 'deleteTempPlacemark', args: undefined});
              }
              const oldPm: IPlacemark  = actionCmd.options['oldPm'];
              if (data.userAction === 'cancel' && !newMode) {
                this.pmCoordinatesChanged(position.x, position.z, position.y);
                if (oldPm != null) {
                  this.sendCmdToIFrame({cmd: 'setPickedPlacemarkName', args: [oldPm.name]});

                  const imageURL: string = this.setIconForPM(oldPm.parentLayerId, oldPm);
                  // If we in discussion panel and edit PM that have discussion and cancel the edit, we need to comeback to the discussion PM icon
                  if (this.openedPanel === '' && this.lastOpenedPanel === 'sidebar.discussion') {
                    let statusIconUrl: string;
                    this.getSelectedPlacemark$.subscribe((pmId: IPlacemark) => {
                      const isSelectedPlacemark: boolean = pmId.id === oldPm.id;

                      if (isSelectedPlacemark) {
                        if (oldPm.statusType === 'STATUS' || oldPm.statusType === 'CHECKLIST') {
                          statusIconUrl =
                            imageURL.substring(0, imageURL.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS_SELECTED;
                        } else {
                          statusIconUrl =
                            imageURL.substring(0, imageURL.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS_SELECTED
                            + imageURL.substring(imageURL.indexOf('?'));
                        }
                      } else {
                        if (oldPm.statusType === 'STATUS' || oldPm.statusType === 'CHECKLIST') {
                          statusIconUrl =
                            imageURL.substring(0, imageURL.lastIndexOf('.')) + StatusCommentsIcon.COMMENTS;
                        } else {
                          statusIconUrl =
                            imageURL.substring(0, imageURL.lastIndexOf('/') + 1) + StatusCommentsIcon.STATUS + StatusCommentsIcon.COMMENTS
                            + imageURL.substring(imageURL.indexOf('?'));
                        }
                      }

                  this.sendCmdToIFrame({
                    cmd: 'setPickedPlacemarkIcon',
                        args: [statusIconUrl]
                  });
                    }).unsubscribe();

                  } else {
                    this.sendCmdToIFrame({
                      cmd: 'setPickedPlacemarkIcon',
                      args: [imageURL]
                    });
                  }
                }
              }
              this.setPlacemarkEditingMode(false);
            });
            break;
          }
          case CMD_ACTIONS.GO_TO_VP_MODE: {
            const selectedVP: IViewpoint = actionCmd.options['selectedVP'];
            if (selectedVP === undefined) {
              this.sendCmdToIFrame({cmd: 'zoomToFit', args: undefined});
            } else {
              this.goToViewpoint(selectedVP);
            }

            break;
          }
          case CMD_ACTIONS.GO_TO_PM_MODE: {
            this.pmCoordinatesChanged(actionCmd.options['x'], actionCmd.options['y'], actionCmd.options['z']);
            break;
          }
          case CMD_ACTIONS.LAYOUT_UPLOAD_MODE: {
            this.setLayoutUploadingMode(true);
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];

            dialogRef.onChanges$().subscribe((data: DialogModel) => {

              // handle new layout file uploading
              const tempFileParams: any[] = data['tempFileParams'];
              if (tempFileParams && tempFileParams[0]) {
                this.createTempLayout(true, tempFileParams);
              }

              // handle removal of temp layout when Hi-Res is loaded instead
              const deleteTempLayout: string = data['deleteTempLayout'];
              if (deleteTempLayout) {
                this.sendCmdToIFrame({cmd: 'deleteTemp2DLayout', args: [undefined, false]});
              }

              // handle creation of registration point from dialog
              let regPointIndex: any = data['createRegPoint'];
              if (regPointIndex !== undefined) {
                this.sendCmdToIFrame({cmd: 'startRegistrationPointMode', args: [regPointIndex]});
              }

              // handle removed registration point from dialog
              regPointIndex = data['removedRegPoint'];
              const cancelPtPM: boolean = data['cancelRegPtPM'];
              if (cancelPtPM !== undefined) {
                this.sendCmdToIFrame({cmd: 'stopRegistrationPointMode', args: [cancelPtPM + '', regPointIndex]});
              }

            });

            dialogRef.onClose$().subscribe((data: DialogModel) => {
              this.sendCmdToIFrame({cmd: 'deleteRegistrationPointPlacemark', args: undefined});
              this.setLayoutUploadingMode(false);
            });
            break;
          }
          case CMD_ACTIONS.DELETE_REG_POINTS: {
            this.deleteRegistrationPointPlacemark();
            break;
          }
          case CMD_ACTIONS.LAYOUT_EDIT_MODE: {
            this.setLayoutEditingMode(true);
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];

            dialogRef.onChanges$().subscribe((data: DialogModel) => {

              this.handleLayoutEditCmd(data);

              // handle new layout file uploading
              const tempFileParams: any[] = data['tempFileParams'];
              if (tempFileParams && tempFileParams[0]) {
                this.createTempLayout(true, tempFileParams);
              }

              // handle creation of registration point from dialog
              let regPointIndex: any = data['createRegPoint'];
              if (regPointIndex !== undefined) {
                this.sendCmdToIFrame({cmd: 'startRegistrationPointMode', args: [regPointIndex]});
              }

              // handle removed registration point from dialog
              regPointIndex = data['removedRegPoint'];
              const cancelPtPM: boolean = data['cancelRegPtPM'];
              if (cancelPtPM !== undefined) {
                this.sendCmdToIFrame({cmd: 'stopRegistrationPointMode', args: [cancelPtPM + '', regPointIndex]});
              }
            });

            dialogRef.onClose$().subscribe((data: DialogModel) => {
              if (data.userAction !== 'saved') {
                // handle Cancel / exit
                if (!isNullOrUndefined(data.getData('restoredData'))) {
                  this.sendCmdToIFrame({cmd: 'deleteRegistrationPointPlacemark', args: undefined});
                  this.create2DLayout(data.getData('restoredData'), false);
                }
              }
              this.setLayoutEditingMode(false);
            });
            break;
          }
          case CMD_ACTIONS.LAYOUT_DELETE_MODE: {
            this.setLayoutDeleteMode(true);
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onChanges$().subscribe((data: DialogModel) => {
              const layoutIdToFree: string = data['freeElementId'] as string;
              if (layoutIdToFree !== undefined) {
                this.sendCmdToIFrame({cmd: 'removeHilightByObjectId', args: [layoutIdToFree]});
              }
            });
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              this.setLayoutDeleteMode(false);
            });
            break;
          }
          case CMD_ACTIONS.FILL_VIEWPOINT_PROPERTIES: {
            this.sendCmdToIFrame({cmd: 'fillViewpointProperties', args: undefined});
            break;
          }
          case CMD_ACTIONS.DISPLAY_PM_COMMENTS_ICONS: { // Change placemark icon for discussion panel
            const placemarkToEdit: IPlacemark = actionCmd.options['placemarkEdit'];
            const isSelectedPlacemark: boolean = actionCmd.options['isSelectedPlacemark'];
            this.showPlacemarkCommentsIcon(placemarkToEdit, isSelectedPlacemark);
            break;
          }
          case CMD_ACTIONS.CANCEL_DISPLAY_PM_COMMENTS_ICONS: { // Cancel changed placemark icon for discussion panel
            const placemarksWithComments: Map<string, string> = actionCmd.options['placemarksWithComments'];
            this.cancelCommentIconOnPlacemark(placemarksWithComments);
            break;
          }
          case CMD_ACTIONS.AFTER_UNLOAD: {
            this.frameLoadedStatus = frameLoadedStatus.NOT_LOADED;
            break;
          }
          case CMD_ACTIONS.SHOW_SEARCH_RESULTS: {
            const searchResults: SingleSearchResult[] = actionCmd.options['searchResults'];
            this.addSearchResultPlacemarks(searchResults);
            break;
          }
          case CMD_ACTIONS.REMOVE_SEARCH_RESULTS: {
            this.removeSearchPMMarkers();
            break;
          }
          case CMD_ACTIONS.CENTER_SEARCH_RESULT: {
            const x: number = actionCmd.options['x'];
            const z: number = actionCmd.options['z'];
            this.sendCmdToIFrame({cmd: 'lookAtBoundingBox', args: [x, z, x, z]});
            break;
          }
          case CMD_ACTIONS.OPEN_CENTER_SEARCH_RESULT: {
            const pm: IPlacemark = actionCmd.options['pm'];
            this.sendCmdToIFrame({cmd: 'openPlacemarkAndZoomIn', args: [pm.id]});
            break;
          }
          case CMD_ACTIONS.MEASURE: {
            this.sendCmdToIFrame({cmd: 'drawDistanceControl', args: undefined});
            break;
          }
          case CMD_ACTIONS.DRAW_SHAPE: {
            this.sendCmdToIFrame({cmd: 'drawShape', args: [actionCmd.options]});
            break;
          }
          case CMD_ACTIONS.ZOOM_IN_TO_OBJECT: {
            this.sendCmdToIFrame({cmd: 'zoomInToObject', args: [actionCmd.options.data]});
            break;
          }
          case CMD_ACTIONS.RENDER_SHAPE: {
            this.sendCmdToIFrame({cmd: 'renderShape', args: [actionCmd.options]});
            break;
          }
          case CMD_ACTIONS.SELECT_UNSELECT_MODEL: {
            this.sendCmdToIFrame({cmd: 'selectUnselectModel', args: [actionCmd.options.data.select, actionCmd.options.data.modelId, actionCmd.options.data.zoomInToObject]});
            break;
          }
          case CMD_ACTIONS.SET_PLACEMARK_HIGHLIGHT: {
            this.sendCmdToIFrame({cmd: 'setPlacemarkHighlight', args: [actionCmd.options.data.highlight, actionCmd.options.data.placemarkId]});
            break;
          }
          case CMD_ACTIONS.CLEAR_SHAPE: {
            this.sendCmdToIFrame({cmd: 'clearShape', args: [actionCmd.options]});
            break;
          }
          case CMD_ACTIONS.SET_FOLLOW_OBJECT_MODE: {
            this.sendCmdToIFrame({cmd: 'setFollowObjectMode', args: [actionCmd.options.data.followObjectMode, actionCmd.options.data.objectType, actionCmd.options.data.id]});
          break;
          }
          case CMD_ACTIONS.CONTROL_OBJECT_MOVEMENT: {
            this.sendCmdToIFrame({cmd: 'controlObjectMovement', args: [actionCmd.options.action, actionCmd.options.modelId, actionCmd.options.includePlacemark, actionCmd.options.placemarkId]});
            break;
          }
          case CMD_ACTIONS.SET_MODEL_ROTATION: {
            this.sendCmdToIFrame({cmd: 'setModelRotation', args: [actionCmd.options.modelId, actionCmd.options.rotation]});
            break;
          }
          case CMD_ACTIONS.MANAGE_STANDALONE_PLACEMARKS: {
            if (actionCmd.options.action == 'CREATE') {
              this.sendCmdToIFrame({cmd: 'manageStandalonePlacemarks', args: [
                'CREATE', actionCmd.options.id, actionCmd.options.iconId, actionCmd.options.name,
                actionCmd.options.positionProperty.x, actionCmd.options.positionProperty.y, actionCmd.options.positionProperty.z,
                actionCmd.options.iconUrl, actionCmd.options.url
              ]});
            } else if (actionCmd.options.action == 'DELETE') {
              this.sendCmdToIFrame({cmd: 'manageStandalonePlacemarks', args: ['DELETE', actionCmd.options.id, actionCmd.options.iconId]});
            } else if (actionCmd.options.action == 'DELETE_ALL') {
              this.sendCmdToIFrame({cmd: 'manageStandalonePlacemarks', args: ['DELETE_ALL']});
            } else if (actionCmd.options.action == 'DELETE_PM_BY_ICON_ID') {
              this.sendCmdToIFrame({cmd: 'manageStandalonePlacemarks', args: ['DELETE_PM_BY_ICON_ID', null, actionCmd.options.iconId]});
            }
            break;
          }
          case CMD_ACTIONS.SUBTRACT_GIS_PMS: {
            this.sendCmdToIFrame({cmd: 'addLoadingObjects', args: [-1 * +actionCmd.options['number'], false]});
            break;
          }
          case CMD_ACTIONS.HIDE_MULTIPLE_PM_MENU: {
            this.sendCmdToIFrame({cmd: 'hideMultiplePlacemarks', args: undefined});
            break;
        }
          case CMD_ACTIONS.UPDATE_OFFSETS: {
            const site: ISite = actionCmd.options['site'];
            const offsetX: number = site.offsetX;
            const offsetY: number = site.offsetY;
            const offsetZ: number = site.offsetZ;
            const scale: number = site.scale;
            const rotation: number = site.rotation;

            this.setSiteOffsets(offsetX, offsetY, offsetZ, scale, rotation);
            break;
          }
          // case CMD_ACTIONS.STOP_WEBGL: {
            // const iframeElem: HTMLIFrameElement = document.getElementById('webglFrame') as HTMLIFrameElement;
            // if(iframeElem) {
            //   iframeElem.parentNode.removeChild(iframeElem);
            // }
            // break;
          // }
          case CMD_ACTIONS.REMOVE_LAST_ACTIVE_PANEL: {
            this.lastOpenedPanel = undefined;
            break;
          }
          case CMD_ACTIONS.CLOSE_ACTIVE_PANEL: {
            this.lastOpenedPanel = this.openedPanel;
            break;
          }
          case CMD_ACTIONS.OPEN_SCAN_PM_OPTIONS: {
            this.sendCmdToIFrame({cmd: 'openScanPlacemarkOpenOptions', args: actionCmd.options });
            break;
          }
        }
      });
  }

  public removeSearchPMMarkers(hideOnly: boolean = false): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SELECT_UNSELECT_MODEL, {data: {select: false, modelId: null}});
    this.searchResultsPms.forEach((res: SingleSearchResult, pmId: string) => {
      this.sendCmdToIFrame({cmd: 'deletePlacemark', args: [pmId]});
    });
    if (!hideOnly) {
      this.searchResultsPms = new Map<string, SingleSearchResult>();
    }
  }

  async addSearchResultPlacemarks(searchResults: SingleSearchResult[]): Promise<void> {
    if (this.openedPanel == 'sidebar.search') {
      this.removeSearchPMMarkers();
      for(const result of searchResults){
        await this.addSearchResultPlacemark(result);
      }

      if (this.searchResultsPms.size > 0) {
        if (this.searchResultsPms.size === 1) {
          const onlySearchResult: SingleSearchResult = this.searchResultsPms.values().next().value;
          if (onlySearchResult.type !== 'VIEWPOINT') {
            if (onlySearchResult.type === 'MODEL') {
              this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.ZOOM_IN_TO_OBJECT, {data: {objectType: ViewerObjectType.MODEL, id: onlySearchResult.originalObjId}});
            } else {
              this.sendCmdToIFrame({cmd: 'lookAtBoundingBox', args:
                  [onlySearchResult.position.x, onlySearchResult.position.z, onlySearchResult.position.x, onlySearchResult.position.z]});
            }
          }
        } else {
          this.sendCmdToIFrame({cmd: 'lookAtSearchResultsBoundingBox', args: [this.searchResultsPms]});

          // const bounds: ILatLngBounds = {minX: Infinity, minZ: Infinity, maxX: -Infinity, maxZ: -Infinity};
          // this.searchResultsPms.forEach((res: SingleSearchResult, pmId: string) => {
          //   if (bounds.minX > res.position.x) {
          //     bounds.minX = res.position.x;
          //   }
          //   if (bounds.maxX < res.position.x) {
          //     bounds.maxX = res.position.x;
          //   }
          //   if (bounds.minZ > res.position.z) {
          //     bounds.minZ = res.position.z;
          //   }
          //   if (bounds.maxZ < res.position.z) {
          //     bounds.maxZ = res.position.z;
          //   }
          // });
          // this.sendCmdToIFrame({cmd: 'lookAtBoundingBox', args: [bounds.minX, bounds.minZ, bounds.maxX, bounds.maxZ]});
        }
      }
    }
  }

  async addSearchResultPlacemark(searchResult: SingleSearchResult): Promise<void> {
    const id: string = searchResult.searchObjId;
    const iconUrl: string = `${environment.windowOrigin}/assets/search_panel/letters/placemarkResult64_${searchResult.letterIndex}.png`;
    if (searchResult.type == "MODEL") {
      const models3d: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
      const model3D: I3DModel = models3d.find((model: I3DModel) => model.id == searchResult.originalObjId);
      if (model3D) {
        this.sendCmdToIFrame({cmd: 'createModelSearchPlacemark', args: [
          searchResult.originalObjId, searchResult.searchObjId, searchResult.name, model3D.description, iconUrl
        ]});
      }
    } else {
      const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
      let origPm: IPlacemark = placemarks.find((pm: IPlacemark) => pm.id === searchResult.originalObjId);
      if (!origPm) {
        // we will get here if pm is part of unloaded Zone where we didn't load the placemark to store
        if(searchResult.type === "PLACE"){
          origPm = await this.placemarksApiSvc.getAddressPlacemarkById(searchResult.originalObjId);
        } else {
          origPm = await this.placemarksApiSvc.getPlacemarkById(searchResult.originalObjId, searchResult.type);
        }
      } else {
        origPm = cloneDeep(origPm);
      }
      this.sendCmdToIFrame({cmd: 'createPlacemark', args: [
        'SEARCH', searchResult.searchObjId, searchResult.name, (searchResult.position.x)?.toFixed(10), (searchResult.position.y)?.toFixed(10), (searchResult.position.z)?.toFixed(10), iconUrl, '', origPm.description,
        'true', 'false', 'false', origPm.placemarkUiType, '', '',
        origPm.issues ? origPm.issues.join(';') : '', origPm.statusType, undefined, undefined, undefined, undefined /*this.activeSite.id*/
      ]});

    }
    this.searchResultsPms.set(id, searchResult);
  }

  setIconForPM(layerId: string, pm?: IPlacemark): string {
    return this.commonUtilitySvc.setIconForPM(layerId, pm);
  }

  // test comment
  public handleIFrameCmds(cmd: any): void {
    if (cmd.data.origin !== 'webglView') {
      return;
    }
    const modelErrorMessage = 'The attached file is not a valid model file';
    switch (cmd.data.cmd) {
      case 'sendClickModelEvent': {
        const modelId: string = cmd.data.data;
        let model3D: I3DModel;
        this.models3D$.subscribe((models: I3DModel[]) => {
          model3D = models.find((model: I3DModel) => {
            return model.id === modelId;
          });
        }).unsubscribe();
        this.dialogComService.sendToActiveDialog(model3D);
        break;
      }
      case 'sendClickObjectEvent': {
        const objectType: string = cmd.data.data[0];
        const objectId: string = cmd.data.data[1];
        if (objectType == ViewerObjectType.MODEL) {
          let model3D: I3DModel;
          this.models3D$.subscribe((models: I3DModel[]) => {
            model3D = models.find((model: I3DModel) => {
              return model.id === objectId;
            });
          }).unsubscribe();
          this.dialogComService.sendToActiveDialog({object: model3D, objectType: objectType});
        } else {
          const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
          const placemark: IPlacemark = placemarks.find((pm: IPlacemark) => {
            return pm.id === objectId;
          });
          this.dialogComService.sendToActiveDialog({object: placemark, objectType: objectType});
        }
        break;
      }
      case 'updatePlacemarkPosition': {
        const xCoor: any = cmd.data.data[0].toFixed(10);
        const yCoor: any = cmd.data.data[1].toFixed(10);
        const zCoor: any = cmd.data.data[2].toFixed(10);
        this.dialogComService.sendToActiveDialog({latitude: xCoor, longitude: yCoor, altitude: zCoor});
        break;
      }
      case 'updateMoveObjectDelta': {
        const dX: any = cmd.data.data[0].toFixed(10);
        const dY: any = cmd.data.data[1].toFixed(10);
        const dZ: any = cmd.data.data[2].toFixed(10);
        this.dialogComService.sendToActiveDialog({deltaLatitude: dX, deltaAltitude: dY, deltaLongitude: dZ});
        break;
      }
      case 'clickEvent': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.CLICK_OUTSIDE_DIR, CMD_ACTIONS.CLICK);
        break;
      }
      case 'temp3DModelUploadStatus': {
        if (!cmd.data.data[0]) {
          this.serverApi.createNotifiactionDialogForHttpCrisis(new Error(modelErrorMessage), modelErrorMessage, true, 'Upload Model')
        }
        this.dialogComService.sendToActiveDialog({isModelValid: cmd.data.data[0]});
        break;
      }

      case 'temp2DLayoutUploadStatus': {
        if (!cmd.data.data[0]) {
          this.serverApi.createNotifiactionDialogForHttpCrisis(new Error('The attached file is not a valid lyout file'), modelErrorMessage, true, 'Upload Model')
        }
        this.dialogComService.sendToActiveDialog({isLayoutValid: cmd.data.data[0]});
        break;
      }

      case 'setViewpointProperties': {
        const originX: any = cmd.data.data[0].toFixed(10);
        const originY: any = cmd.data.data[1].toFixed(10);
        const originZ: any = cmd.data.data[2].toFixed(10);
        const lookAtX: any = cmd.data.data[3].toFixed(10);
        const lookAtY: any = cmd.data.data[4].toFixed(10);
        const lookAtZ: any = cmd.data.data[5].toFixed(10);
        const params: any = {
          parent: 'WEBGL',
          coordinatesType: 'FACILITY',
          positionProperty: {x: parseFloat(originX), y: parseFloat(originY) , z: parseFloat(originZ)},
          rotation: parseFloat(lookAtX),
          range: parseFloat(lookAtY),
          tilt: parseFloat(lookAtZ)
        };
        this.store.dispatch(new UpdateCurrViewpointParameters(params));
        if (this.dialogComService.isAnyDialogOpen()) {
          this.dialogComService.sendToActiveDialog({x_coord: originX, y_coord: originY, z_coord: originZ, heading_vp: lookAtX, range_vp: lookAtY, tilt_vp: lookAtZ, isFinishSetVp: true});
        }
        break;
      }
      case 'openPlacemarkWindow': {
        const placemarkId: any = cmd.data.data;
        this.displayPlacemarkContent(placemarkId);
        break;
      }
      case 'goToPanoramic': {
        const placemarkId: any = cmd.data.data;
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.GO_TO_PANO, {pmId: placemarkId});
        break;
      }
      case 'goToScanPlacemark': {
        const placemarkId: any = cmd.data.data[0];
        const placemarkType: any = cmd.data.data[1];
        const layerId: any = cmd.data.data[2];
        this.checkLoginToWebshareAndOpenPlacemark(placemarkId, placemarkType, layerId);
        break;
      }
      case 'showPointCloud': {
        const scanUUID : any = cmd.data.data[0];
        const pointCloudUUID: any = cmd.data.data[1];
        const x: any = cmd.data.data[2];
        const y: any = cmd.data.data[3];
        const z: any = cmd.data.data[4];
        const phi: any = cmd.data.data[5];
        const theta: any = cmd.data.data[6];
        const projectName: any = cmd.data.data[7];
        const fov: any = cmd.data.data[8];
        this.showPointCloud(scanUUID, pointCloudUUID, x, y, z, phi, theta, projectName, fov);
        break;

      }
      case 'openRightClickMenu': {
        if (!this.appConf.readonly) {
          const x_coord: any = cmd.data.data[0];
          const y_coord: any = cmd.data.data[1];
          this.openContextMenu.emit({x: x_coord, y: y_coord});
        }
        break;
      }
      case 'openRightClickPlacemarkMenu': {
        if (!this.appConf.readonly) {
          const x_coord: any = cmd.data.data[0];
          const y_coord: any = cmd.data.data[1];
          const pmId: any = cmd.data.data[2];
          this.openContextMenu.emit({x: x_coord, y: y_coord, placemarkId: pmId});
        }
        break;
      }
      case 'goToMaps': {
        this.store.dispatch(new SetSelectedMode(SCENE_MODE.Map));
        break;
      }
      case 'vpChangedEvent': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.VIEWPOINTS_PANEL, CMD_ACTIONS.REMOVE_SELECTED_VP);
        break;
      }
      case 'addRegistrationPoint': {
        const cadXin: any = cmd.data.data[0];
        const cadZin: any = cmd.data.data[1];
        const layoutXin: any = cmd.data.data[2];
        const layoutZin: any = cmd.data.data[3];
        const pointIndex: any = cmd.data.data[4];
        const newRegPoint: IRegistrationPoint = {cadX: cadXin, cadY: 0, cadZ: cadZin, layoutX: layoutXin, layoutY: 0, layoutZ: layoutZin};
        this.dialogComService.sendToActiveDialog({currCreatedRegPointData: [newRegPoint, pointIndex]});
        break;
      }
      case 'cancelCreationOfRegistrationPoint': {
        const pointIndex: any = cmd.data.data[0];
        this.dialogComService.sendToActiveDialog({currCreatedRegPointData: [undefined, pointIndex]});
        break;
      }
      case 'finishProgressBar': {
        const progressBarVisible: boolean = cmd.data.data[0];
        if (progressBarVisible) {
          // We don't want to show status bar with this
          // message whenever webgl starts uploading an object
           // this.statusBar.addNewStatus(MessagesBank.LOADING_SITE_INFORMATION);
        } else {
          this.statusBar.removeStatus(MessagesBank.LOADING_SITE_INFORMATION);
          this.sseSvc.enableSSEPropagation();
          const end = new Date().getTime();
          const startTime = this.sitesLoaderSvc.getStartLoadingSiteTime();
          let res = end - startTime;
          res = res - (this.placemarksApiSvc.getTotalTimeServerPlacemark() + this.models2DApiSvc.getTotalTimeServer() +
            this.models3DApiSvc.getTotalTimeServer() + this.viewpointsApiSvc.getTotalTimeServer())
          console.log(res / 1000, '##########################end loading site##############################');
        }
        break;
      }
      case 'sendClickLayoutEvent': {
        const layoutId: string = cmd.data.data;
        const currLayout: ILayout = this.store.selectSnapshot<ILayout[]>((state: any) => state.StateModels.layouts)
          .find((layout: ILayout) => {
            return layout.id === layoutId;
          });
        this.dialogComService.sendToActiveDialog(currLayout);
        break;
      }
      case 'webglInit': {
        this.frameLoadedStatus |= frameLoadedStatus.AFTER_WEBGL_FRAME_INIT;
        this.sendCmdToIFrame();
        break;
      }
      case 'shapeCreated': {
        this.outgoingMessageService.shapeCreated(cmd.data.data[0]);
        break;
      }
      case 'modelLocationUpdated': {
        this.outgoingMessageService.modelLocationUpdated(cmd.data.data[0]);
        break;
      }
      case 'setLayoutDetails': {
        this.commonUtilitySvc.setLayoutDetails(cmd.data.data[0]);
        break;
      }
      case 'showNotification': {
        const type = cmd.data.data[0];
        const title = cmd.data.data[1];
        const message = cmd.data.data[2];

        const inputsBinding: InputsBindingsModel = new Map([
          [ 'type', type],
          [ 'title', title],
          [ 'message', message],
          ['onXAction', 'Cancel']
        ]);
        const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
        const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
        dialogComp.buttonsInfo = [
          new ButtonInfo('no', 'Cancel'),
        ];
      }
    }
    // console.log('handleIFrameCmds', cmd.data);
  }

  private async showPointCloud(scanUUID: any, pointCloudUUID: any, x: any, y: any, z: any, phi: any, theta: any, projectName: any, fov: any) {
    if (this.webshareAuthPending) {
      return;
    }
    this.webshareAuthPending = true;
    const sub = this.webshareApiSvc.webShareLoginInfoSubject()
      .subscribe( async ( success ) => {
        sub.unsubscribe();
        if (success) {
          const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
          const placemark: IPlacemark = placemarks.find( pm => pm.scanUUID == scanUUID);
          try {
            await this.webshareApiSvc.checkUserAccessToWebshareProject(placemark.parentLayerId);
            this.webshareApiSvc.getWebShareScanProjectDetails(projectName, scanUUID)
              .subscribe( (res: any) => {
                if ( res.hasOwnProperty('TransformationGlobal') ) {
                  x = res['TransformationGlobal'][12];
                  z = res['TransformationGlobal'][13];
                  y = res['TransformationGlobal'][14];
                }

                this.webshareApiSvc.showPointCloud(fov, pointCloudUUID, x, y, z, phi, theta, projectName);
            })
          } catch (error) {}
        }
        this.webshareAuthPending = false;
      })
    this.webshareApiSvc.loginToWebshare();
  }

  private async checkLoginToWebshareAndOpenPlacemark(placemarkId: any, placemarkType: any, layerId: any) {
    if (this.webshareAuthPending) {
      return;
    }
    this.webshareAuthPending = true;
    const sub = this.webshareApiSvc.webShareLoginInfoSubject()
      .subscribe( async ( success ) => {
        sub.unsubscribe();
        if (success) {
          try {
            await this.webshareApiSvc.checkUserAccessToWebshareProject(layerId);
            this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.GO_TO_SCAN_PM, {pmId: placemarkId, pmType: placemarkType});
          } catch (error) {
          }
        }
        this.webshareAuthPending = false;
      })
    this.webshareApiSvc.loginToWebshare();
  }

  public displayPlacemarkContent(pmId: string): void {
    const selectedPm: IPlacemark = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks)
      .find((pm: IPlacemark) => pm.id === pmId);
    if (!selectedPm) {
      console.log('ERROR - placemark not found');
    }
    const url: string = selectedPm.url;
    if (!url) {
      return;
    }
    let resourceUrl: any = selectedPm.url;
    if (selectedPm.templateId){
      resourceUrl = new URL(resourceUrl);
      resourceUrl.searchParams.append('resourceType', 'TEMPLATE_PLACEMARK');
      resourceUrl = resourceUrl.toString();
    }

    this.placemarkPreviewService.showPlacemarkContent(selectedPm.category, selectedPm.placemarkUiType, selectedPm.name,
      selectedPm.settings.width, selectedPm.settings.height, resourceUrl, selectedPm.html);
  }

  public shouldPopupSaveAsDialog(fileName: string): boolean {
    const typesToOpenDialog: string[] = ['avi', 'mp4', 'ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf'];
    const result: string = typesToOpenDialog.find((extension: string) => {
      return fileName.includes(extension);
    });
    return result !== undefined;
  }

  public sendCmdToIFrame(data: IComData = null): void {
    if (data != null) {
      if (isUndefined(data.args)) {
        data.args = [];
      }
      data.args = [ApiTools.defaultSiteId].concat(data.args);
      this.webglCmdQueue.push(data);
    }
    // console.log('frame loaded=', this.frameLoaded);
    if (this.frameLoadedStatus === frameLoadedStatus.ALL_LOADED) {
      this.webglCmdQueue.forEach((cmd: IComData) => {
        // console.log('CMD', cmd.cmd, cmd.args);
        this.frameWin.postMessage(cmd, '*');
      });

      this.webglCmdQueue = [];
    }
  }

  public webglLoaded(): void {
    const iframeElem: HTMLIFrameElement = document.getElementById('webglFrame') as HTMLIFrameElement;
    this.frameWin = iframeElem.contentWindow;
    this.frameLoadedStatus |= frameLoadedStatus.AFTER_FRAME_LOADED;
    this.sendCmdToIFrame();
    if (this.frameCodeLoaded) {
      return;
    }
    this.frameCodeLoaded = true;
    this.numberOfObjectToLoad$.subscribe((num: number) => {
      if (!isNaN(num)) {
        this.sendCmdToIFrame({cmd: 'addLoadingObjects', args: [num, true]});
        if (num === 0) {
          this.statusBar.removeStatus(MessagesBank.LOADING_SITE_INFORMATION);
        }
      }
    });
    this.setPlacemarksListeners();
    this.setModelsListeners();
  }

  /* WEBGL MANAGER METHODS THAT CALLS THE IFRAME */

  public setPlacemarksListeners(): void {
    this.newPlacemarks$.subscribe((pms: IPlacemark[]) => {
      // console.log('GETTER-PM - newPlacemarks$', pms);
      const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones);
      pms.forEach((pm: IPlacemark) => {
        if (this.objectSiteValidatorService.validateObjectToActiveSite(pm.id)) {
        this.tempUnHandledNewPlacemarks.push(pm);
        this.handleTempPms(layers, zones);
        }
      });
    });

    this.removedPlacemarkId$.subscribe((pmId: string) => {
      if (pmId !== undefined) {
        this.sendCmdToIFrame({cmd: 'deletePlacemark', args: [pmId]});
      }
    });

    this.updatePlacemarks$.subscribe((pms: IPlacemark[]) => {
      if (pms.length === 0) {
        return;
      }
      const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      pms.forEach((pm: IPlacemark) => {
        const currentLayer: ILayer = layers.find((layer: ILayer) => layer.id === pm.parentLayerId);
        const imageURL: string = this.setIconForPM(currentLayer.id, pm);
        this.sendCmdToIFrame({cmd: 'updatePlacemark', args: [
          pm.id, pm.style.showLeg.toString(), pm.style.showLabelAlways.toString(), pm.placemarkUiType, pm.parentLayerId,
            pm.parentZoneId, imageURL, pm.issues ? pm.issues[0] : undefined, pm.issues ? pm.issues[1] : undefined, pm.issues ? pm.issues[2] : undefined,
            pm.issues ? pm.issues[3] : undefined, pm.issues ? pm.issues[4] : undefined, pm.issues ? pm.issues[5] : undefined, pm.statusType,
            pm.name, (pm.positionProperty.x)?.toFixed(10), (pm.positionProperty.z)?.toFixed(10), (pm.positionProperty.y)?.toFixed(10), pm.description, pm.showVisualization, pm.visualizationConf, this.getPlacemarkData(pm), pm.speed
          ]});
        if(this.sceneMode === SCENE_MODE.Panoramic){
          const iframeElem: HTMLIFrameElement = document.getElementById('panoFrame') as HTMLIFrameElement;
          const panoIframe = iframeElem.contentWindow;
          panoIframe.postMessage({cmd: 'loadPlacemark', args: [pm.id, pm.style.showLeg.toString(), pm.style.showLabelAlways.toString(), pm.placemarkUiType, pm.parentLayerId,
            pm.parentZoneId, pm.htmlFileName, pm.settings, imageURL, pm.issues ? pm.issues[0] : undefined, pm.issues ? pm.issues[1] : undefined, pm.issues ? pm.issues[2] : undefined,
            pm.issues ? pm.issues[3] : undefined, pm.issues ? pm.issues[4] : undefined, pm.issues ? pm.issues[5] : undefined, pm.statusType,
            pm.name, (pm.positionProperty.x)?.toFixed(10), (pm.positionProperty.z)?.toFixed(10), (pm.positionProperty.y)?.toFixed(10), pm.description, pm.showVisualization, pm.visualizationConf, this.getPlacemarkData(pm)]}, '*');
        }
      });
    });
  }

  public setModelsListeners(): void {
    this.set3DModelsListeners();
    this.set2DLayoutsOnScene();
    this.removedModelObjs$.subscribe((remObjs: IRemovedObject[]) => {
      if (remObjs) {
        remObjs.forEach((remObj: IRemovedObject) => {
          if (remObj && remObj.id !== undefined) {
            if (remObj.type === '2D') {
              this.sendCmdToIFrame({cmd: 'deleteLayoutById', args: [remObj.id]});
            } else {
              this.sendCmdToIFrame({cmd: 'deleteModel', args: [remObj.id]});
            }
          }
        });
      }
    });
  }

  public set3DModelsListeners(): void {
    // console.log('CMD-in', 'set3DModelsListeners');
    this.new3DModels$.subscribe((models: I3DModel[]) => {
      models.forEach((model: I3DModel) => {
        if (this.objectSiteValidatorService.validateObjectToActiveSite(model.id)) {
        this.sendCmdToIFrame({cmd: 'add3DElement', args: [
            model.id, model.modelUrl, model.latitude, model.altitude, model.longitude, model.rotation, model.parentLayerId, model.parentZoneId, model.firstLoaded
          ]});
        }
      });
    });

    this.updated3DModels$.subscribe((models: I3DModel[]) => {
      models.forEach((model: I3DModel) => {
        // console.log('Update3DElement', [model.id, model.latitude, model.altitude, model.longitude, model.rotation]);
        this.sendCmdToIFrame({cmd: 'update3DElement', args: [
            model.id, model.modelUrl, model.latitude, model.altitude, model.longitude, model.rotation, model.parentLayerId, model.parentZoneId, model.movementParams?.speed, model.movementParams?.autoRotate, model.movementParams?.referenceId
          ]});
      });
    });
  }

  public set2DLayoutsOnScene(): void {
    this.layouts$.subscribe((layouts: ILayout[]) => {
      if (layouts && layouts.length > 0) {
        // console.log('WEBGL, layouts', layouts);
        // we will send input 'true' to the method to make sure all layouts are redrawn
        this.prepareSceneForNewRegistrationPoints(true);
        layouts.forEach((layout: ILayout) => {
          this.create2DLayout(layout, layout.firstLoaded);
        });
      } else {
        this.sendCmdToIFrame({cmd: 'removeAllLayouts', args: [false] });
      }
    });
  }

  public create2DLayout(layout: ILayout, isNewLayout: boolean): void {
    if (this.objectSiteValidatorService.validateObjectToActiveSite(layout.id)) {
    this.sendCmdToIFrame({cmd: 'add2DLayout', args: [
        layout.imageUrl, layout.physicalWidth, layout.physicalHeight, layout.altitude,
        this.activeSite.offsetX, this.activeSite.offsetY, this.activeSite.offsetZ, this.activeSite.scale, this.activeSite.rotation,
        layout.opacity, layout.parentZoneId, layout.parentLayerId, layout.id, layout.isTiled.toString(), layout.tilingLOD, isNewLayout, layout.geoMapInfo
      ]});
    }
  }

  public setZonesListener(): void {
    this.removedZones$.subscribe((zone: IRemovedZone) => {
      // console.log('REM ZONES', zone);
      if (zone) {
        this.sendCmdToIFrame({cmd: 'replaceZone', args: [zone.removedId, zone.replacementId]});
      }
    });

    this.allZones$.subscribe((zones: IZone[]) => {
      if (zones.length === 0) {
        return;
      }

      const zonesArr: IZone[] = [];
      zones.forEach(zone => {
        if (!this.zonesVisibilityMap.has(zone.id) || this.zonesVisibilityMap.get(zone.id) != zone.visible) {
          this.zonesVisibilityMap.set(zone.id, zone.visible);
          zonesArr.push(zone);
        }
      })
      // console.log('WEBGL, zones', zones);
      if (zonesArr.length > 0) {
        const visibilityArr: string = zonesArr.map((zone: IZone) => zone.visible).join(';');
        const idsArr: string = zonesArr.map((zone: IZone) => zone.id).join(';');
        this.sendCmdToIFrame({cmd: 'setZonesVisibility', args: [idsArr, visibilityArr]});
      }
    });

    this.visibleZones$.subscribe((zones: IZone[]) => {
      const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      this.handleTempPms(layers, zones);
    });
  }

  public handleTempPms(layers: ILayer[], zones: IZone[]): void {
    this.tempUnHandledNewPlacemarks = this.tempUnHandledNewPlacemarks.filter((pm: IPlacemark) => {

      // if Placemark doesn't have parent Layer ID and other than general placemark doesn't have parent zone ID, then it is an Invalid Placemark
      if (!pm.parentLayerId || (generalLayersIds.indexOf(Number(pm.parentLayerId)) < 0 && !pm.parentZoneId)) {
        this.sendCmdToIFrame({cmd: 'addLoadingObjects', args: [-1, false]});
        return false;
      }

      const layer: ILayer = layers.find((layer1: ILayer) => layer1.id === pm.parentLayerId);
      if (!layer) {
        return true;
      }
      let isGeneralLayer: boolean = false;
      let zone: IZone = null;
      if (generalLayersIds.indexOf(Number(layer.id)) < 0) {
        zone = zones.find((zone1: IZone) => zone1.id === pm.parentZoneId);
        if (!zone) {
          return true;
        }
      } else {
        isGeneralLayer = true;
      }

      let pmType: string = pm.category;
      if (pm.statusType) {
        // this is a status pm
        pmType = 'STATUS_PLACEMARK';
        for (let i = 0; i < 6; i ++) {
          pm.issues[i] = this.containersHelper.hasStatus(layer, STATUS_LAYER_MASK['STATUS' + (i + 1)]) ? pm.issues[i] : -1;
        }
      }
      const iconUrl:string = this.setIconForPM(layer.id, pm);

      const currentLayer = layers.find((foundLayer: ILayer) => foundLayer.id === layer.id);
      if (currentLayer && currentLayer.layerType === LAYER_TYPES.Scan) {
        pmType = 'SCAN_PLACEMARK';
      }

      if (iconUrl === window.location.origin + '/' + STATUS_LAYER_ICON_PRE + '.png') {
        // illegal pm, need to subtract
        console.log('counting illegal status pm, subtracting 1 to all calculation');
        this.sendCmdToIFrame({cmd: 'addLoadingObjects', args: [-1, false]});
      }

      if (pmType === 'QR_CODE') {
        // pmType = 'PLACEMARK';
        pm.url = pm.html;
      }

      let visibility: string = 'false';
      if ((!isGeneralLayer && zone.visible && layer.visible === LAYER_VISIBILITY.VISIBLE) || (isGeneralLayer && layer.visible === LAYER_VISIBILITY.VISIBLE)) {
        visibility = 'true';
      }
      if (iconUrl) {
        this.sendCmdToIFrame({cmd: 'createPlacemark', args: [
            pmType, pm.id, pm.name, (pm.positionProperty.x)?.toFixed(10), (pm.positionProperty.y)?.toFixed(10), (pm.positionProperty.z)?.toFixed(10), iconUrl, pm.url, pm.description,
            visibility, pm.style.showLeg.toString(), pm.style.showLabelAlways.toString(), pm.placemarkUiType, pm.parentLayerId, pm.parentZoneId,
            pm.issues ? pm.issues.join(';') : '', pm.statusType, undefined, pm.showVisualization, pm.visualizationConf, this.getPlacemarkData(pm) /*this.activeSite.id*/
          ]});
      } else {
        // corrupted icon
        console.log('counting illegal pm with no icon. Subtracting 1 to all calculation');
        this.sendCmdToIFrame({cmd: 'addLoadingObjects', args: [-1, false]});
      }
      return false;
    });
  }

  public getPlacemarkData(pm: IPlacemark) {
    if(pm.eventInfo) {
      const eventInfoJson = JSON.parse(pm.eventInfo);
      if (!pm.placemarkData || pm.placemarkData.length === 0) {
        pm.placemarkData = [];
      }
      if (!(pm.placemarkData.find((data) => data.variableId === "eventDescription"))) {
        pm.placemarkData.unshift({name: "Event Description", replaceVariable: true, value: eventInfoJson.actionDesc, variableId: "eventDescription"});
      }
    }
    return pm.placemarkData;
  }

  public setLayersListeners(): void {
    this.layers$.subscribe((layers: ILayer[]) => {
      // console.log('WEBGL, layers', layers);
      layers.filter((layer: ILayer) => layer.layerType === LAYER_TYPES.Status).forEach((layer: ILayer) => {
        this.sendCmdToIFrame({cmd: 'setLayerColor', args: [layer.id, layer.statusColors.join(';')]});
      });

      const visibilityArr: string = layers.map((layer: ILayer) => layer.visible === LAYER_VISIBILITY.VISIBLE).join(';');
      const idsArr: string = layers.map((layer: ILayer) => layer.id).join(';');
      this.sendCmdToIFrame({cmd: 'setLayersVisibility', args: [idsArr, visibilityArr]});
      const zones: IZone[] = this.store.selectSnapshot<IZone[]>((state: any) => state.StateZones.zones);
      this.handleTempPms(layers, zones);
    });

    this.removedLayers$.subscribe((layers: IRemovedLayer[]) => {
      layers.forEach((layer: IRemovedLayer) => {
        if (layer) {
          this.sendCmdToIFrame({cmd: 'replaceLayer', args: [layer.removedId, layer.replacementId, layer.replacedIcon]});
        }
      });
    });

    this.changedLayers$.subscribe((changedLayers: ILayer[]) => {
      const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
      changedLayers.forEach((layer: ILayer) => {
        if  (layer.layerType === LAYER_TYPES.Status) {
          this.sendCmdToIFrame({cmd: 'updateDynamicPlacemarksOnLayerUpdate', args: [layer.id, layer.visualizationMethod, layer.visualizationConf]});
          const changedPlacemarks: IPlacemark[] = placemarks.filter((pm: IPlacemark) => pm.parentLayerId === layer.id);
          changedPlacemarks.forEach((pm: IPlacemark) => {
            if (pm.coordinatesType === CoordinatesType.FACILITY) {
              const imageURL: string = this.containersHelper.getStatusPMIcon(pm, layer);
              this.sendCmdToIFrame({cmd: 'changePlacemarkIcon', args: [pm.id, imageURL]});
              // this.sendCmdToIFrame({cmd: 'updateStatusPlacemarkColors', args: [pm.id, layer.id]});
              for (let i = 0; i < layer.statusColors.length; i++) {
                if (layer.statusColors[i] !== '') {
                  if (pm.issues[i] === -1) {
                    pm.issues[i] = 0;
            }
                }
              }
              this.sendCmdToIFrame({cmd: 'updateStatusPlacemarkPropertiesById', args: [pm.id, pm.issues.join(';'), pm.statusType]});

            }
          });
        } else if (layer.layerType === LAYER_TYPES.Site) {
          const changedPlacemarks: IPlacemark[] = placemarks.filter((pm: IPlacemark) => pm.parentLayerId === layer.id);
          changedPlacemarks.forEach((pm: IPlacemark) => {
            this.sendCmdToIFrame({cmd: 'changePlacemarkIcon', args: [pm.id, this.setIconForPM(layer.id)]});
          });
        }
      });
    });
  }

  public setLayoutEditingMode(activeMode: boolean): void {
    if (activeMode) {
      this.sendCmdToIFrame({cmd: 'startLayoutEditingMode', args: [Actions.permLayoutsToEdit]});
    } else {
      this.sendCmdToIFrame({cmd: 'stopLayoutEditingMode', args: undefined});
    }
  }

  public setLayoutUploadingMode(activeMode: boolean): void {
    if (activeMode) {
      this.sendCmdToIFrame({cmd: 'startLayoutUploadingMode', args: undefined});
    } else {
      this.sendCmdToIFrame({cmd: 'stopLayoutUploadingMode', args: undefined});
    }
  }

  public setModelEditingMode(activeMode: boolean): void {
    if (activeMode) {
      this.sendCmdToIFrame({cmd: 'startModelEditingMode', args: [Actions.permModelsToEdit]});
    } else {
      this.sendCmdToIFrame({cmd: 'stopModelEditingMode', args: undefined});
    }
  }

  public setModelUploadingMode(activeMode: boolean): void {
    if (activeMode) {
      this.sendCmdToIFrame({cmd: 'startModelUploadingMode', args: undefined});
    } else {
      this.sendCmdToIFrame({cmd: 'stopModelUploadingMode', args: undefined});
    }
  }

  public setLayoutDeleteMode(activeMode: boolean): void {
    if (activeMode) {
      this.sendCmdToIFrame({cmd: 'startLayoutDeleteMode', args: [Actions.permLayoutsToDelete]});
    } else {
      this.sendCmdToIFrame({cmd: 'stopLayoutDeleteMode', args: undefined});
    }
  }

  public setModelDeleteMode(activeMode: boolean): void {
    if (activeMode) {
      this.sendCmdToIFrame({cmd: 'startModelDeleteMode', args: [Actions.permModelsToDelete]});
    } else {
      this.sendCmdToIFrame({cmd: 'stopModelDeleteMode', args: undefined});
    }
  }

  public setMoveObjectsMode(activeMode: boolean, revertObjectsMovement = false): void {
    if (activeMode) {
      this.sendCmdToIFrame({cmd: 'startMoveObjectsMode', args: [Actions.permModelsToEdit, Actions.permPlacemarksToEdit]});
    } else {
      this.sendCmdToIFrame({cmd: 'stopMoveObjectsMode', args: [revertObjectsMovement]});
    }
  }

  public createTempLayout(startMode: boolean, layoutParams: any[]): void {
    this.sendCmdToIFrame({cmd: 'deleteRegistrationPointPlacemark', args: undefined});
    this.sendCmdToIFrame({cmd: 'addTemporary2DLayout', args: layoutParams.map((item: any) => item.toString())});
  }

  public deleteRegistrationPointPlacemark(): void {
    this.sendCmdToIFrame({cmd: 'deleteRegistrationPointPlacemark', args: undefined});
  }
  public changeNewModelUpload(startMode: boolean, modelsUrls?: string[]): void {
    if (startMode) {
      this.sendCmdToIFrame({cmd: 'deleteTemporaryModels', args: undefined});
      this.setModelUploadingMode(false);
      this.setModelUploadingMode(true);
      modelsUrls.forEach((modelUrl: string) => {
        this.sendCmdToIFrame({cmd: 'addTemporary3DElement', args: ['tempModel', modelUrl]});
      });
    } else {
      this.sendCmdToIFrame({cmd: 'deleteTemporaryModels', args: undefined});
      this.setModelUploadingMode(false);
    }
  }

  // should be called in "loadSite" and "unloadSite"
  public clearScene(siteLayersOnly?: boolean): void {
    this.sendCmdToIFrame({cmd: 'clearScene', args: [siteLayersOnly] });
  }

  public setSiteId(id: number): void {
    this.sendCmdToIFrame({cmd: 'setSiteId', args: [id] });
  }

  public setAppMode(appMode: APPLICATION_MODE): void {
    this.sendCmdToIFrame({cmd: 'setAppMode', args: [appMode] });
  }

  public createEmptyGrid(): void {
    this.sendCmdToIFrame({cmd: 'createEmptyGrid', args: undefined });
    this.sendCmdToIFrame({cmd: 'setSessionId', args: [this.sessionAPiSvc.sessionId] });
  }

  public prepareSceneForNewRegistrationPoints(removeLayouts: boolean, updateObjectCount: boolean = true): void {
    if (!isNullOrUndefined(this.activeSite)) {
      this.updateSiteSettings();
    }
    if (removeLayouts) {
      this.sendCmdToIFrame({cmd: 'removeAllLayouts', args: [updateObjectCount] });
    }
  }

  public updateSiteSettings(): void {
    const offsetX: number = this.activeSite.offsetX;
    const offsetY: number = this.activeSite.offsetY;
    const offsetZ: number = this.activeSite.offsetZ;
    const scale: number = this.activeSite.scale;
    const rotation: number = this.activeSite.rotation;
    this.setSiteId(this.activeSite.id);

    this.setSiteOffsets(offsetX, offsetY, offsetZ, scale, rotation);
  }

  public setSiteOffsets(x: number, y: number, z: number, scale: number, roatation: number): void {
    this.sendCmdToIFrame({cmd: 'updateSceneWithNewRegistrationPoints', args: [x, y, z, scale, roatation] });
  }

  public updateViewpointProperties(isCheckMode: boolean, newVpMode: boolean = false): void {
    if (isCheckMode) {
      // Start fillViewpointProperties
      this.sendCmdToIFrame({cmd: 'startChangeViewpointMode', args: [newVpMode]});
    } else {
      // stop fillViewpointProperties
      this.sendCmdToIFrame({cmd: 'stopChangeViewpointMode', args: undefined});
    }
  }

  public goToViewpoint(viewpoint: IViewpoint): void {
    if (!viewpoint || viewpoint.parent !== 'WEBGL') {
      this.sendCmdToIFrame({cmd: 'zoomToFit', args: undefined});
    } else {
      // positionCartesian: ICartesianPositionProperty = viewpoint.positionProperty;
      if (viewpoint.positionProperty === null
        || (!viewpoint.latitude && !viewpoint.longitude && !viewpoint.positionProperty)) {
        this.sendCmdToIFrame({cmd: 'zoomToFit', args: undefined});
      } else {
        // console.log('WEBGL - goToViewpoint', viewpoint.rotation, viewpoint.range, viewpoint.tilt,
        //  viewpoint.positionProperty.x, viewpoint.positionProperty.y, viewpoint.positionProperty.z);
        this.sendCmdToIFrame({
          cmd: 'goToViewpointAndNotify', args: [viewpoint.rotation, viewpoint.range, viewpoint.tilt,
            viewpoint.positionProperty.x, viewpoint.positionProperty.y, viewpoint.positionProperty.z]
        });
      }
    }
  }

  public setPlacemarkEditingMode(activeMode: boolean, newMode: boolean = true): void {
    if (activeMode) {
      this.sendCmdToIFrame({cmd: 'startPlacemarkEditingMode', args: newMode});
    } else {
      this.sendCmdToIFrame({cmd: 'stopPlacemarkEditingMode', args: undefined});
    }
  }

  pmCoordinatesChanged(x: number, y: number, z: number): void {
    // console.log('pmCoordinatesChanged', x, y, z);
    if (x !== undefined) {
      this.sendCmdToIFrame({cmd: 'setPickedPlacemarkX', args: [x]});
    }
    if (y !== undefined) {
      this.sendCmdToIFrame({cmd: 'setPickedPlacemarkZ', args: [y]});
    }
    if (z !== undefined) {
      this.sendCmdToIFrame({cmd: 'setPickedPlacemarkY', args: [z]});
    }
  }
}
