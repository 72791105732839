import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState } from '../../../../../Store/app.state/app.state';
import { Observable } from 'rxjs';
import { SCENE_MODE } from '../../../../../Store/app.state/app.model';
import { IViewpoint } from '../../../../../Store/viewpoints.state/viewpoints.model';
import { CopyLinkService } from '../../../../../services/copy-link.service';
import { DialogRef } from 'src/app/common/Forms/Dialog-types/dialog-ref';
import { DialogService, DialogType } from 'src/app/services/dialogs.service';
import { ApiTools } from 'src/app/services/api.services/api.tools';
import { WebshareConfDialogComponent } from 'src/app/common/Forms/Dialogs/webshare-conf-dialog/webshare-conf-dialog.component';
import { DataPrivacyApiSvc } from 'src/app/services/api.services/data-privacy.api.svc';
import { SiemensAnalyticsService } from 'src/app/services/siemens-analytics.service';
import { environment } from 'src/environments/environment';
import { OperationStatusBoardDialogComponent } from 'src/app/common/Forms/Dialogs/operation-status-board-dialog/operation-status-board-dialog.component';
import { ILayer } from 'src/app/Store/layers.state/layers.model';
import { PermissionsManager, ACTION_TYPE, Actions } from 'src/app/services/permissions-manager';
import { LayersState } from 'src/app/Store/layers.state/layers.state';
import { ISite } from 'src/app/Store/sites.state/sites.model';
import { take } from 'rxjs/operators';

export const SIEGLE_PROXY: string = 'intosite-proxy';
export const MAIN_HTML: string = 'Main.html';

export const CLIENT_REDIRECT_NOTIFIER: string = 'redirect';

@Component({
  selector: 'ins-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.css', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class SettingsPanelComponent implements OnInit {
  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  @Select (AppState.getCurrentViewpoint) currentViewpoint$: Observable<IViewpoint>;
  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  @Select(LayersState.getSiteLayers) siteLayers$: Observable<ILayer[]>;

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();
  public isAdmin: boolean = false;
  public isSiteAdmin: boolean = false;
  public isPerm2DEdit: boolean = false;
  public useSan = environment.useSan;

  constructor(private copyLinkService: CopyLinkService, private dialogService: DialogService, private dataPrivacyApiSvc: DataPrivacyApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    if (ApiTools.userRoles) {
      ApiTools.userRoles.includes('ADMIN') && (this.isAdmin = true);
      ApiTools.userRoles.includes('SITE_ADMIN') && (this.isSiteAdmin = true);
    }
  }

  ngOnInit(): void {
    this.activeSite$.subscribe((site: ISite) => {
      if (site) {
        this.isPerm2DEdit = false;
        this.check2DEditPermission();
      }
    });
  }

  check2DEditPermission(): void {
    let sub;
    sub = this.siteLayers$.subscribe((layers: ILayer[]) => {
      layers.forEach((layer: ILayer) => {
        PermissionsManager.isPermitted$(Actions.LAYER_2D_MODEL, ACTION_TYPE.UPDATE, layer.id).subscribe((isPerm: boolean) => {
          this.isPerm2DEdit = this.isPerm2DEdit || isPerm;
        });
        PermissionsManager.isPermitted$(Actions.LAYER_2D_MODEL, ACTION_TYPE.CREATE, layer.id).subscribe((isPerm: boolean) => {
          this.isPerm2DEdit = this.isPerm2DEdit || isPerm;
        });
      });
      if (sub) {
        sub.unsubscribe();
        sub = null;
      }
    });
  }

  copyLink(): void {
    this.copyLinkService.copyViewpointLinkToClipboard();
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_CopyLink');
  }

  openWebshareConfDialog(): void {
    this.dialogService.createDialog(WebshareConfDialogComponent, DialogType.Modal, null, null, 985);
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_PointCloudConfiguration', false);
  }

  openDataPrivacyDialog(context: string): void {
     this.dataPrivacyApiSvc.openDataPrivacyDialog(context);
  }

  removeUrlParam(url: string, param: string): string {
    let result: string = url;
    const startIndex: number = result.indexOf(param);
    if (startIndex !== -1) {
      const endIndex = result.indexOf('?', startIndex);
      if (endIndex === -1) {
        result = result.substr(0, result.indexOf(param) - 1);
      } else {
        result = result.replace(result.substr(startIndex - 1, endIndex) , '');
      }
    }
    return result;
  }

  openOperationStatusBoard(): void {
    const dialog: DialogRef = this.dialogService.createDialog(OperationStatusBoardDialogComponent, DialogType.Modal, null, null, 1000);
  }
}
