import {Component, EventEmitter, Output} from '@angular/core';
import {LayerTreeNode} from '../../../../../common/Models/UI/ui-tree-node.model';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {LayersState} from '../../../../../Store/layers.state/layers.state';
import {SetLayerExpanded, SetLayerVisibility} from '../../../../../Store/layers.state/layers.actions';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {DeleteLayerDialogComponent} from '../../../../../common/Forms/Dialogs/delete-layer-dialog/delete-layer-dialog.component';
import {DialogService, DialogType} from '../../../../../services/dialogs.service';
import {CloneLayerDialogComponent} from '../../../../../common/Forms/Dialogs/clone-layer-dialog/clone-layer-dialog.component';
import {cloneDeep} from 'lodash';
import {
  ACCESS_MODE,
  ILayer,
  ILayerProperty,
  LAYER_TREE_NODE_PROPERTIES,
  LAYER_TYPES,
  LAYER_VISIBILITY,
  STATUS_VISUALIZATION_METHOD
} from '../../../../../Store/layers.state/layers.model';
import {NotificationDialogComponent} from '../../../../../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo, ValueAndDisplay} from '../../../../../common/UI-Components/helperClasses/value-and-display.class';
import {ISite} from '../../../../../Store/sites.state/sites.model';
import {AppState} from '../../../../../Store/app.state/app.state';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {ServerApi} from '../../../../../services/api.services/server.api';
import {
  AddEditLayerDialogComponent,
  colorSeverityNumber
} from '../../../../../common/Forms/Dialogs/add-edit-layer-dialog/add-edit-layer-dialog.component';
import {EditorMode} from '../../../../../common/Forms/Dialogs/dialog.helper';
import {LibIconsService} from '../../../../../services/lib-icons.service';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {LayersApiSvc} from '../../../../../services/api.services/layers.api.svc';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../../../services/permissions-manager';
import {isNullOrUndefined} from 'util';
import {statusIconSrc, scanIconSrcForPanel} from '../../../../../Store/layers.state/layers.const.utils';
import {CopyLayerDialogComponent} from 'src/app/common/Forms/Dialogs/copy-layer-dialog/copy-layer-dialog.component';
import {ApiTools} from 'src/app/services/api.services/api.tools';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';

@Component({
  selector: 'ins-layers-menu',
  templateUrl: './layers-menu.component.html',
  styleUrls: ['./layers-menu.component.scss', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class LayersMenuComponent {

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();

  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  @Select(LayersState.getLayers) layerArray: Observable<ILayer[]>;
  @Select(LayersState.getLayersTree) layersTree$: Observable<LayerTreeNode[]>;

  public activeSite: ISite;
  public layers: LayerTreeNode[] = [];
  public hoveredLayer: LayerTreeNode = null;
  public isPermAddLayer: boolean = false;
  public isPermEditLayer: boolean = false;
  public isPermDeleteLayer: boolean = false;
  public isPermCloneLayers: boolean = false;
  public isPermDeleteAll: boolean = false;
  public enableFunctionalities: boolean = false;
  public isAdmin: boolean = false;

  private castStatusChecksToInt(status: boolean[]): number {
    let result: number = 0;
    for (let i = 0; i < colorSeverityNumber; i++) {
      result = result | ((status[i] ? 1 : 0) << i);
    }
    return result;
  }

  constructor(private store: Store, public dialogService: DialogService, public serverApi: ServerApi,
              public libIconsSrv: LibIconsService, private statusBar: StatusService, private layersApiSvc: LayersApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    this.setLayers();
    this.fillActiveSite();

    PermissionsManager.isPermitted$(Actions.LAYERS_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
        this.isPermAddLayer = isPerm; });
    PermissionsManager.isPermitted$(Actions.LAYERS_PERM, ACTION_TYPE.UPDATE).subscribe((isPerm: boolean) => {
        this.isPermEditLayer = isPerm; });
    PermissionsManager.isPermitted$(Actions.LAYERS_PERM, ACTION_TYPE.DELETE).subscribe((isPerm: boolean) => {
        this.isPermDeleteLayer = isPerm; });
    PermissionsManager.isPermitted$(Actions.LAYERS_PERM, ACTION_TYPE.CLONE).subscribe((isPerm: boolean) => {
      this.isPermCloneLayers = isPerm; });
    PermissionsManager.isPermitted$(Actions.LAYERS_PERM, ACTION_TYPE.DELETE_ALL).subscribe((isPerm: boolean) => {
      this.isPermDeleteAll = isPerm; });

    if (ApiTools.userRoles && (ApiTools.userRoles.includes('ADMIN') || ApiTools.userRoles.includes('SITE_ADMIN'))) {
      this.isAdmin = true;
    }
  }

  public layerPropertyChanged(data: ILayerProperty): void {
    switch (data.propType) {
      case LAYER_TREE_NODE_PROPERTIES.VISIBILITY: {
        this.store.dispatch(new SetLayerVisibility(data.node, data.propVal));
        // Log Siemens Analytics event
        this.siemensAnalyticsService.logEvent('INS_SelectionOfLayerVisibility');
        break;
      }
      case LAYER_TREE_NODE_PROPERTIES.EXPANDED: {
        this.store.dispatch(new SetLayerExpanded(data.node, data.propVal));
        break;
      }
    }
  }

  public setLayers(): void {
    this.layersTree$.subscribe((layers: LayerTreeNode[]) => {
      this.layers = cloneDeep(layers);
      if (!(this.isPermAddLayer && this.isPermEditLayer && this.isPermDeleteLayer)) {
        this.layers = this.filterInfertileLayers(this.layers);
      }
    });
  }

  filterInfertileLayers(layers: LayerTreeNode[]): LayerTreeNode[] {
    return layers.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.filterInfertileLayers(layer.children as LayerTreeNode[]);
      }
      return (!(layer.layerType === LAYER_TYPES.Group && layer.children.length === 0));
    });
  }

  public fillActiveSite(): void {
    this.activeSite$.subscribe((site: ISite) => {
      if (site) {
        this.activeSite = site;
      }
      this.enableFunctionalities = !isNullOrUndefined(site);
    });
  }

  cloneLayersDialog(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'currentSiteID', this.activeSite.id]
    ]);
    const dialog: DialogRef = this.dialogService.createDialog(CloneLayerDialogComponent, DialogType.Modal, inputsBinding);
    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'clone') {
        this.statusBar.addNewStatus(MessagesBank.CLONE_LAYERS);
        const siteId = model.values.get('cloneFromSiteID');
        this.layersApiSvc.cloneLayers(siteId);
      }
    });
  }

  deleteAllLayers(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Delete All Layers' ],
      [ 'message', 'Existing layers will be deleted with all content. Are you sure?']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

    dialogComp.buttonsInfo = [
      new ButtonInfo('cancel', 'Cancel'),
      new ButtonInfo('delete', 'Delete'),
    ];
    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'delete') {
        this.statusBar.addNewStatus(MessagesBank.DELETING_ALL_LAYERS);
        this.layersApiSvc.deleteAllLayers();
      }
    });
  }

  editLayer(event: MouseEvent): void {
    event.stopPropagation();
    let layerToEdit: ILayer = null;
    let origLayer: ILayer = null;
    this.layerArray.subscribe((layers: ILayer[]) => {
      origLayer = layers.find((layer: ILayer) => {
        return layer.id === this.hoveredLayer.id;
      });
      if (origLayer) {
        layerToEdit = cloneDeep(origLayer);
      }
    }).unsubscribe();

    if (layerToEdit != null) {
      let image: string;
      if (layerToEdit.libIconId != null) {
        image = layerToEdit.libIconId;
      } else if (layerToEdit.iconURL != null) {
        image = layerToEdit.iconURL;
      }
      const severityChecks: boolean[] = [];
      for (let i: number = 0; i < colorSeverityNumber; i++) {
        severityChecks[i] = (layerToEdit.layerStatus & (1 << i)) > 0 ? true : false;
      }
      const inputsBinding: InputsBindingsModel = new Map()
        .set('dialogMode', EditorMode.EDIT)
        .set('layerType', layerToEdit.layerType)
        .set('name', layerToEdit.name)
        .set('layerId', layerToEdit.id)
        .set('description', layerToEdit.description)
        .set('isVisibleByDefault', layerToEdit.visibleByDefault)
        .set('parentLayer', layerToEdit.parent)
        .set('image', image)
        .set('severityChecks', severityChecks)
        .set('severityColors', layerToEdit.statusColors)
        .set('layerLogic', layerToEdit.logicType)
        .set('visualizationMethod', layerToEdit.visualizationMethod || STATUS_VISUALIZATION_METHOD.STATUS_TAG)
        .set('visualizationConf', layerToEdit.visualizationConf);
      const dialog: DialogRef = this.dialogService.createDialog(AddEditLayerDialogComponent, DialogType.Modal, inputsBinding , null, 390);
      dialog.onClose$().subscribe((model: DialogModel) => {
        if (model.userAction === 'ok') {
          this.statusBar.addNewStatus(MessagesBank.EDITING_LAYER);
          const layerToAdd: ILayer = {
            visible: layerToEdit.visible,
            layerType: model.values.get('layerType'),
            expanded: true,
            description: model.values.get('description'),
            logicType: model.values.get('layerLogic'),
            statusColors: model.values.get('severityColors'),
            iconURL: null,
            libIconId: null,
            visibleByDefault: model.values.get('isVisibleByDefault'),
            layerStatus: this.castStatusChecksToInt(model.values.get('severityChecks')),
            parent: model.values.get('parentLayer'),
            id: layerToEdit.id,
            name: model.values.get('name'),
            accessMode: layerToEdit.accessMode,
            externalId: layerToEdit.externalId
          };
          if (layerToAdd.description === undefined) {
            layerToAdd.description = '';
          }
          if (layerToAdd.parent === '0') {
            layerToAdd.parent = '';
          }
          let isIconUrl: boolean = false;
          let iconFile: File = null;
          const layerType: LAYER_TYPES = model.values.get('layerType');
          if (typeof(model.values.get('image')) ===  'string') {
            if (model.values.get('image').indexOf('ResourceServlet') === -1 && model.values.get('image') !== statusIconSrc && model.values.get('image') !== scanIconSrcForPanel) {
              layerToAdd.libIconId = model.values.get('image');
            } else {
              layerToAdd.iconURL = model.values.get('image');
            }
          } else {
            iconFile = model.values.get('image');
            isIconUrl = true;
          }
          if (layerType === LAYER_TYPES.Group) {
            layerToAdd.layerType = LAYER_TYPES.Site;
            layerToAdd.libIconId = this.libIconsSrv.getIdByName('folderclose');
          }
          if (layerType === LAYER_TYPES.Status) {
            layerToAdd.visualizationMethod = model.values.get('visualizationMethod');
            layerToAdd.visualizationConf = model.values.get('visualizationConf');
          }

          if ( (origLayer.parent || layerToAdd.parent) && origLayer.parent != layerToAdd.parent) {
            const inputsBinding: InputsBindingsModel = new Map<string, any>([
              ['type', 'warning'],
              ['title', 'Change Layer Structure'],
              ['message', `All the content of the specified layers will be moved to the new location. Are you sure? <br> Click <b>Yes</b> to reload your site with the modification.`],
              ['buttonsInfo', [new ValueAndDisplay('cancel', 'Cancel'), new ValueAndDisplay('yes', 'Yes')]]
            ]);
            const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
            dialog.onClose$().subscribe((model: DialogModel) => {
              if (model.userAction === 'yes') {
                this.layersApiSvc.addUpdateLayer(isIconUrl ? iconFile : null, layerToAdd, EditorMode.EDIT, layerType);
              } else {
                this.statusBar.removeStatus(MessagesBank.EDITING_LAYER);
              }
            });
          } else {
            this.layersApiSvc.addUpdateLayer(isIconUrl ? iconFile : null, layerToAdd, EditorMode.EDIT, layerType);
          }
        }
      });
    }
  }

  copyLayer(event: MouseEvent): void {
    event.stopPropagation();
    const inputsBinding: InputsBindingsModel = new Map();
    const fromLayerId = this.hoveredLayer.id;
    const dialog: DialogRef = this.dialogService.createDialog(CopyLayerDialogComponent, DialogType.Modal, inputsBinding);

    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'apply') {
        const toLayerId: string = model.values.get('toLayerId');
        const moveLayerContent: boolean = model.values.get('moveLayerContent');

        if (moveLayerContent) {
          const inputsBinding: InputsBindingsModel = new Map<string, any>([
            ['type', 'warning'],
            ['title', 'Copy Layer Structure'],
            ['message', `All the content of the specified layers will be moved to the new location. Are you sure? <br> Click <b>Yes</b> to reload your site with the modification.`],
            ['buttonsInfo', [new ValueAndDisplay('cancel', 'Cancel'), new ValueAndDisplay('yes', 'Yes')]]
          ]);
          const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
          dialog.onClose$().subscribe((model: DialogModel) => {
            if (model.userAction === 'yes') {
              this.statusBar.addNewStatus(MessagesBank.COPY_LAYERS);
              this.layersApiSvc.copyLayer(fromLayerId, toLayerId, moveLayerContent);
            }
          });
        } else {
          this.statusBar.addNewStatus(MessagesBank.COPY_LAYERS);
          this.layersApiSvc.copyLayer(fromLayerId, toLayerId, moveLayerContent);
        }
      }
    });
  }

  removeLayer(event: MouseEvent): void {
    event.stopPropagation();
    let dialog: DialogRef;
    if (this.hoveredLayer.layerType === LAYER_TYPES.Site || this.hoveredLayer.layerType === LAYER_TYPES.Scan) {
      const inputsBinding: InputsBindingsModel = new Map()
        .set('layerObj', this.hoveredLayer);
      dialog = this.dialogService.createDialog(DeleteLayerDialogComponent, DialogType.Modal, inputsBinding);
      dialog.onClose$().subscribe((model: DialogModel) => {
        if (model.userAction === 'ok') {
          this.statusBar.addNewStatus(MessagesBank.DELETING_LAYER);
          const selectedDeleteOption: string = model.values.get('selectedDeleteOption');
          const deletedLayerId: string = model.values.get('layerObj').id;
          let replaceByLayerId: string = null;
          if (selectedDeleteOption === 'move') {
            replaceByLayerId = model.values.get('layerToMove');
          }
          this.removeLayerHandleDialogSelection(deletedLayerId, replaceByLayerId);
        }
      });
    } else {
      const hoveredLayer = this.hoveredLayer;
      const title: string = `Delete "${hoveredLayer.displayValue}" Layer`;
      const inputsBinding: InputsBindingsModel = new Map([
        [ 'type', 'warning'],
        [ 'title', title ],
        [ 'message', 'Are you sure you want to permanently delete all layer content?'],
      ]);
      dialog = this.dialogService.createNotificationDialog(inputsBinding);
      const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
      dialogComp.buttonsInfo = [
        new ButtonInfo('no', 'No'),
        new ButtonInfo('yes', 'Yes'),
      ];
      dialog.onClose$().subscribe((model: DialogModel) => {
        if (model.userAction === 'yes') {
          this.statusBar.addNewStatus(MessagesBank.DELETING_LAYER);
          const deletedLayerId: string = hoveredLayer.id;
          this.removeLayerHandleDialogSelection(deletedLayerId);
        }
      });
    }
  }

  removeLayerHandleDialogSelection(deletedLayerId: string, layerToReplaceId: string = null): void {
    let layerToRemove: ILayer = null;
    let layerToReplace: ILayer = null;
    this.layerArray.subscribe((layers: ILayer[]) => {
      layerToRemove = layers.find((layer: ILayer) => {
        return layer.id === deletedLayerId;
      });
      if (layerToReplaceId != null) {
        layerToReplace = layers.find((layer: ILayer) => {
          return layer.id === layerToReplaceId;
        });
      }
    }).unsubscribe();
    delete layerToRemove.objMode;
    if (layerToReplace != null) {
      delete layerToReplace.objMode;
    }
    if (layerToRemove.layerType === LAYER_TYPES.Group) {
      layerToRemove.layerType = LAYER_TYPES.Site;
    }
    this.layersApiSvc.deleteLayer(layerToRemove, layerToReplace);
  }

  AddLayer(): void {
    const dialog: DialogRef = this.dialogService.createDialog(AddEditLayerDialogComponent, DialogType.Modal, null , null, 390);
    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'ok') {
        this.statusBar.addNewStatus(MessagesBank.ADDING_LAYER);
        const layerToAdd: ILayer = {
          visible: model.values.get('isVisibleByDefault') ? LAYER_VISIBILITY.VISIBLE : LAYER_VISIBILITY.UNVISIBLE,
          layerType: model.values.get('layerType'),
          expanded: true,
          description: model.values.get('description'),
          logicType: model.values.get('layerLogic'),
          statusColors: model.values.get('severityColors'),
          iconURL: null,
          libIconId: null,
          visibleByDefault: model.values.get('isVisibleByDefault'),
          layerStatus: this.castStatusChecksToInt(model.values.get('severityChecks')),
          parent: model.values.get('parentLayer'),
          id: '',
          name: model.values.get('name'),
          accessMode: ACCESS_MODE.UNDEFINED
        };
        if (layerToAdd.description === undefined) {
          layerToAdd.description = '';
        }
        if (layerToAdd.parent === '0') {
          layerToAdd.parent = '';
        }
        let isIconUrl: boolean = false;
        let iconFile: File = null;
        const layerType: LAYER_TYPES = model.values.get('layerType');
        if (layerType === LAYER_TYPES.Site) {
          if (typeof(model.values.get('image')) ===  'string') {
            layerToAdd.libIconId = model.values.get('image');
          } else {
            iconFile = model.values.get('image');
            isIconUrl = true;
          }
        }
        if (layerType === LAYER_TYPES.Group) {
          layerToAdd.layerType = LAYER_TYPES.Site;
          layerToAdd.libIconId = this.libIconsSrv.getIdByName('folderclose');
        }
        if (layerType === LAYER_TYPES.Status) {
          layerToAdd.visualizationMethod = model.values.get('visualizationMethod');
          layerToAdd.visualizationConf = model.values.get('visualizationConf');
        }
        this.layersApiSvc.addUpdateLayer(isIconUrl ? iconFile : null, layerToAdd, EditorMode.NEW, layerType);
      }
    });
  }
}
