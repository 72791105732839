import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { APPLICATION_MODE, IAppConf } from '../Store/app.state/app.model';
import { AppState } from '../Store/app.state/app.state';
import { initializeThemeBasedIcons } from '../Store/layers.state/layers.const.utils';

export enum AppTheme {
  LIGHT='light',
  DARK='dark'
}
@Injectable({
  providedIn: 'root'
})

export class ThemeService {
  private currentTheme: AppTheme = AppTheme.LIGHT;

  @Select(AppState.getAppConf) appConf$: Observable<IAppConf>;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.appConf$.subscribe( (appConf: IAppConf) => {
      if (appConf.appMode === APPLICATION_MODE.EMBEDDED) {
        this.setTheme(AppTheme.LIGHT);
      } else {
        this.setTheme(AppTheme.DARK);
      }
    });
  }

  setTheme(theme: AppTheme): void {
    if (isPlatformBrowser(this.platformId)) {
      const themeLink = document.getElementById('theme-link') as HTMLLinkElement;
      const newHref = `assets/${theme}.theme.css`;
      if (themeLink) {
        themeLink.href = newHref;
        this.currentTheme = theme;
      }
    }
    initializeThemeBasedIcons(theme);
  }

  getTheme(): AppTheme {
    return this.currentTheme;
  }
}
