import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { cloneDeep } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { ApplicationView } from 'src/app/common/Models/UI/company-info.interface';
import { SetAppConf, SetSelectedMode } from 'src/app/Store/app.state/app.actions';
import { APPLICATION_MODE, IAppConf, SCENE_MODE } from 'src/app/Store/app.state/app.model';
import { AppState } from 'src/app/Store/app.state/app.state';
import { Set3DModel } from 'src/app/Store/models.state/models.actions';
import { I3DModel } from 'src/app/Store/models.state/models.model';
import { SetPlacemark } from 'src/app/Store/placemarks.state/placemarks.actions';
import { CoordinatesType, IPlacemark } from 'src/app/Store/placemarks.state/placemarks.model';
import { SetAllSites } from 'src/app/Store/sites.state/sites.actions';
import { ISite } from 'src/app/Store/sites.state/sites.model';
import { SitesState } from 'src/app/Store/sites.state/sites.state';
import { SiteDefaultView } from 'src/app/Store/sites.state/sites.utils';
import { IViewpoint } from 'src/app/Store/viewpoints.state/viewpoints.model';
import { ViewpointsState } from 'src/app/Store/viewpoints.state/viewpoints.state';
import { environment } from 'src/environments/environment';
import { ApiTools } from '../api.services/api.tools';
import { Models3DApiSvc } from '../api.services/models.3D.api.svc';
import { ServerApi } from '../api.services/server.api';
import { SessionApiSvc } from '../api.services/session.api.svc';
import { SitesApiSvc } from '../api.services/sites.api.svc';
import { SitesLoaderSvc } from '../api.services/sites.loader.svc';
import { CmdRouterService, CMD_ACTIONS, CMD_TARGETS } from '../cmd-router.service';
import { CommonUtilitySvc } from '../common-utility.service';
import { IntositeEmbeddedService } from './intosite-embedded.service';
import { OutgoingMessagesService } from './outgoing-messages.service';
import { PlacemarksState } from 'src/app/Store/placemarks.state/placemarks.state';
import { Router } from '@angular/router';
import { PlacemarksApiSvc } from '../api.services/placemarks.api.svc';

@Injectable({
  providedIn: 'root'
})
export class IncomingMessagesService {
  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  public activeSite: ISite;
  @Select(ViewpointsState.getViewpoints) viewpoints$: Observable<IViewpoint[]>;
  public sceneMode: SCENE_MODE;
  @Select(AppState.getSelectedMode) selectedMode$: Observable<SCENE_MODE>;
  public facilityVPs: IViewpoint[] = [];
  @Select(SitesState.getSites) sites$: Observable<ISite[]>;

  @Select(PlacemarksState.getPlacemarks) placemarks$: Observable<IPlacemark[]>;

  public appLoadData: any;
  constructor(public sessionApiSvc: SessionApiSvc, private outgoingMessageService: OutgoingMessagesService, private models3DApiSvc: Models3DApiSvc, private intositeEmbeddedService: IntositeEmbeddedService,
    private store: Store, private cmdRouterSvc: CmdRouterService, private serverApi: ServerApi, private commonUtilitySvc: CommonUtilitySvc, private siteLoaderSvc: SitesLoaderSvc, private siteApiSvc: SitesApiSvc,
    private router: Router, private placemarksApiSvc: PlacemarksApiSvc) {
      this.loadAppConfFromSession();
      this.loadAppAfterLogin();
      window.addEventListener("message", this.receiveMessage.bind(this), false);
      this.activeSite$.subscribe((site: ISite) => this.activeSite = site ? site : null);
      this.selectedMode$.subscribe((mode: SCENE_MODE) => this.sceneMode = mode);
      this.intositeEmbeddedService.loadSiteInEmbeddedMode$.subscribe((loadSite: boolean) => {
        this.loadSite();
        this.updateAppConf();
      });
  }

  public receiveMessage(event) {
    if (event.data) {
      if (event.data.origin !== 'VFD') {
        return;
      }
      const functionName = event.data.cmd;
      this[functionName].apply(this, event.data.args);
    }
  }

  private loadAppAfterLogin(): void {
    this.intositeEmbeddedService.embeddedLoginDone.subscribe((res: any) => {
      if (res) {
        if (this.appLoadData) {
          this.store.dispatch(new SetSelectedMode(SCENE_MODE.Facility));
          const data = JSON.parse(JSON.stringify(this.appLoadData));
          if (data.siteId && !data.zoomInToObject) {
            this.outgoingMessageService.zoomToFitOnSiteLoad = true;
          }
          if (data.siteId) {
            this.intositeEmbeddedService.externalSiteId = data.siteId;
          }
          if (data.zoomInToObject) {
            this.zoomToObjectOnSiteLoad(data);
          }
          this.appLoadData = null;
        }
        if (res.alreadyLoggedIn) {
          this.router.navigate([ApplicationView.Main]);         
        } else {
          this.sessionApiSvc.loginSucceeded(res.res, ApplicationView.Main);
        }
      }
    });
  }

  private intositeLogout() {

  }

  private loadAppConfFromSession(): void {
    const appConfStr = sessionStorage.getItem('appConf');
    if (!appConfStr) {
      return;
    }
  
    try {
      const appConf: IAppConf = JSON.parse(appConfStr);
      this.store.dispatch(new SetAppConf(appConf));
    } catch (error) {
      console.error('Failed to parse appConf from sessionStorage', error);
    }
  }

  private updateAppConf(): void {
    try {
      const appConf: IAppConf = this.store.selectSnapshot<IAppConf>((state: any) => state.StateApp.appConf);
      appConf.loadSpecificSite = false;
      this.store.dispatch(new SetAppConf(appConf));
      sessionStorage.setItem('appConf', JSON.stringify(appConf));
    } catch (error) {
      console.error('Failed to update appConf', error);
    }
  }

  async performIntositeLogin(data: any): Promise<any> {
    this.appLoadData = data;
    this.intositeEmbeddedService.userName = data.userName;
    this.intositeEmbeddedService.company = data.company;
    const appConf: IAppConf = {
      appMode: APPLICATION_MODE.EMBEDDED,
      readonly: false,
      appHeader: false,
      sideBarContainer: true,
      webGLToolbar: true,
      panoToolbar: true,
      loadSpecificSite: data.siteId ? true : false
    };
    sessionStorage.setItem('appConf', JSON.stringify(appConf));
    this.store.dispatch(new SetAppConf(appConf));
    this.intositeEmbeddedService.performIntositeLogin.next(true);
  }

  private async zoomToObjectOnSiteLoad(data: any): Promise<any> {
    const zoomInToObject = data.zoomInToObject;
    let placemarkLoaded: boolean = false;
    const addressPm = await this.placemarksApiSvc.getPlacemarkByExternalId(zoomInToObject.id, "system");
    if (!addressPm) {
      setTimeout(() => {
        this.serverApi.createNotificationDialog("err", "Placemark Not Found", "The placemark you are trying to load does not exist. Please ensure the external ID is correct and try again.");
        return;
      }, 500);
    }
    const placemarkSub: Subscription = this.placemarks$.subscribe( (placemarks: IPlacemark[]) => {
      let placemark = placemarks.find(pm => {
        return pm.externalId.some(obj => obj.externalId === zoomInToObject.id)}
      );

      if (placemark && !placemarkLoaded) {
        placemarkLoaded = true;
        placemarkSub.unsubscribe();
        setTimeout(() => {
          zoomInToObject.id = placemark.id;
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.ZOOM_IN_TO_OBJECT, {data: zoomInToObject});
        }, 500);
      }
    })
  }

  async externalLogin(loginData, siteId, viewpoint: any) {
    this.store.dispatch(new SetSelectedMode(SCENE_MODE.Facility));
    const appConf: IAppConf = {
      appMode: APPLICATION_MODE.EMBEDDED,
      readonly: true,
      appHeader: false,
      sideBarContainer: false,
      webGLToolbar: false,
      panoToolbar: false,
      loadSpecificSite: true
    };
    this.store.dispatch(new SetAppConf(appConf));
    this.sessionApiSvc.loginSucceeded(loginData, ApplicationView.Main);

    const cookiesToSet = loginData["Cookies"];
    if (cookiesToSet && cookiesToSet.length > 0) {
      cookiesToSet.forEach(cookieString => document.cookie = cookieString);
    } else {
      console.warn("No cookies were specified when external login ")
    }

    this.intositeEmbeddedService.viewpointToLoad = this.populateViewpoint(viewpoint);
    this.intositeEmbeddedService.siteId = siteId;
    if (viewpoint.showOnlySelectedZonesLayers) {
      this.intositeEmbeddedService.viewpointSelectedZones = new Map(Object.entries(viewpoint.selectedZones));
      this.intositeEmbeddedService.viewpointSelectedLayers = new Map(Object.entries(viewpoint.selectedLayers));
    } else {
      this.intositeEmbeddedService.viewpointSelectedZones.clear();
      this.intositeEmbeddedService.viewpointSelectedLayers.clear();
    }
  }

  async loadSite() {
    let getSitesUrl: string = `${environment.serverUrl}/services/SiteServices/getAllSites`;
    const sitesResponse: any = await this.serverApi.sendGetToServer(getSitesUrl).toPromise();

    let siteToLoad: ISite;
    const sites: ISite[] = [];
    sitesResponse.forEach((site: any) => {
      const siteStruct: ISite = ApiTools.convertSiteFromResponseToClient(site);
      siteStruct.isDefault = false;

      let siteToLoadFound = false;

      // Load site using external Id
      if (this.intositeEmbeddedService.externalSiteId && siteStruct.externalId.find( obj => obj.externalId === this.intositeEmbeddedService.externalSiteId)) {
        siteToLoad = siteStruct;
        siteToLoadFound = true;
      }

      // Load site using internal Id
      if (siteStruct.id == this.intositeEmbeddedService.siteId) {
        siteToLoad = siteStruct;
        siteToLoadFound = true;
      }

      if (siteToLoadFound) {
        siteStruct.defaultView = SiteDefaultView.LastVisited;
        if (this.intositeEmbeddedService.viewpointToLoad) {
          siteStruct.lastViewpoint = this.intositeEmbeddedService.viewpointToLoad;
        }
      }
      sites.push(siteStruct);
    });
    if (sites.length > 0) {
      this.store.dispatch(new SetAllSites(sites));
    }
    if (siteToLoad) {
      let openInMode = undefined;
      if (siteToLoad.lastViewpoint && siteToLoad.lastViewpoint.coordinatesType !== CoordinatesType.FACILITY) {
        openInMode = SCENE_MODE.Facility;
      }
      this.siteLoaderSvc.loadSite(siteToLoad, openInMode);
    } else {
      this.serverApi.createNotificationDialog("err", "Site Not Found", "The site you are trying to load does not exist. Please ensure the external ID is correct and try again.");
    }
  }

  public drawShape(data: any): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.DRAW_SHAPE, {type: data.type, borderColor: data.borderColor, fillColor: data.fillColor});
  }

  public renderShape(data: any, convertGeoToXY = true): void {
    if (convertGeoToXY) {
      data.data.points = this.commonUtilitySvc.convertGeotoXY(data.data.points);
    }
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.RENDER_SHAPE, {type: data.type, borderColor: data.borderColor, fillColor: data.fillColor, data: data.data});
  }

  public clearShape(data: any): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CLEAR_SHAPE, {type: data.type});
  }

  public zoomInToObject(data: any) {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.ZOOM_IN_TO_OBJECT, {data});
  }

  public modelFastMovement(data: any) {
    const movePlacemark = data.hasOwnProperty('movePlacemark') ? data.movePlacemark : true;
    const autoRotate = data.hasOwnProperty('autoRotate') ? data.autoRotate : true;
    const speed = data.speed;
    const time = data.time;
    let distanceToTravel = speed*time;
    const trackPoints = this.commonUtilitySvc.convertGeotoXY(data.trackPoints);
    trackPoints.forEach( (obj, i) => obj.referenceId = data.trackPoints[i].referenceId);

    const modelId = data.modelId;
    const models3d: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    const model3D: I3DModel = models3d.find((model: I3DModel) => model.id == modelId);
    let targetFound = false;
    if (model3D) {
      trackPoints.splice(0, 0, {x: model3D.latitude - this.activeSite.offsetX, z: model3D.longitude - this.activeSite.offsetY});
      let latitude, longitude, index = 0;
      for (; index < trackPoints.length - 1 ; index++) {
        const distBetPoints = this.getDistanceBetweenTwoPoints(trackPoints[index], trackPoints[index + 1]);
        if (distanceToTravel < distBetPoints) {
          const destinationPoints = this.calculatePointAtGivenDistance(trackPoints[index], trackPoints[index + 1], distBetPoints, distanceToTravel);
          latitude = destinationPoints.x + this.activeSite.offsetX;
          longitude = destinationPoints.z + this.activeSite.offsetY;
          targetFound = true;
          break;
        } else {
          if (index == trackPoints.length - 2) {
            latitude = trackPoints[index + 1].x + this.activeSite.offsetX;
            longitude = trackPoints[index + 1].z + this.activeSite.offsetY;
            targetFound = true;
            break;
          } else {
            latitude = trackPoints[index + 1].x + this.activeSite.offsetX;
            longitude = trackPoints[index + 1].z + this.activeSite.offsetY;
            this.updateModelLocationInternal(data.modelId, latitude, longitude, -1, autoRotate, 'internal_move', movePlacemark);
            distanceToTravel = distanceToTravel - distBetPoints;
          }
        }
      }
      if (targetFound) {
        const previousLocation = this.commonUtilitySvc.convertXYtoGeo([{x: trackPoints[index].x, z: trackPoints[index].z}]);
        const nextLocation = this.commonUtilitySvc.convertXYtoGeo([{x: trackPoints[index + 1].x, z: trackPoints[index + 1].z}]);
        this.outgoingMessageService.modelMovementInfo.set(model3D.id, {
          currentLocation: this.commonUtilitySvc.convertXYtoGeo([{x: latitude, z: longitude}])[0],
          previousLocation: {lat: previousLocation[0].lat, lng: previousLocation[0].lng, referenceId: trackPoints[index].referenceId},
          nextLocation: {lat: nextLocation[0].lat, lng: nextLocation[0].lng, referenceId: trackPoints[index + 1].referenceId},
        });
        this.updateModelLocationInternal(data.modelId, latitude, longitude, -1, autoRotate, null, movePlacemark);
      }
    }
  }

  public calculatePointAtGivenDistance(originPoint, destPoint, totalDistance, distanceToTravel) {
    const t = distanceToTravel/totalDistance // ratio of distances
    return { x: ((1 - t) * originPoint.x + t * destPoint.x), z: ((1 - t) * originPoint.z + t * destPoint.z)}
  }

  public getDistanceBetweenTwoPoints(originPoint, destPoint): number {
    const dx = destPoint.x - originPoint.x;
    const dz = destPoint.z - originPoint.z;
    return Math.sqrt( dx * dx + dz * dz );
  }

  public doFit(): void {
    const target: CMD_TARGETS = this.sceneMode === SCENE_MODE.Map ? CMD_TARGETS.MAP_MANAGER : CMD_TARGETS.WEBGL_MANAGER;
    this.cmdRouterSvc.sendActionCmd(target, CMD_ACTIONS.FIT);
  }

  public selectUnselectModel(data: any): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SELECT_UNSELECT_MODEL, {data});
  }

  public setFollowObjectMode(data: any): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SET_FOLLOW_OBJECT_MODE, {data});
  }

  public controlObjectMovement(data: any): void {
    const includePlacemark = data.hasOwnProperty('includePlacemark') ? data.includePlacemark : true;
    const models3d: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    const model3D: I3DModel = models3d.find((model: I3DModel) => model.id == data.id);
    if (model3D) {
      let placemarkId = null;
      if (includePlacemark) {
        this.outgoingMessageService.includePlacemarkInPause.set(model3D.id, true);
        const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
        const placemark: IPlacemark = placemarks.find((placemark) => placemark.name === model3D.name);
        placemarkId = placemark ? placemark.id : null;
      }
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CONTROL_OBJECT_MOVEMENT, {action: data.action, modelId: data.id, includePlacemark: includePlacemark, placemarkId: placemarkId});
    }
  }

  public resetModelLocation(data: any) {
    const movePlacemark = data.hasOwnProperty('movePlacemark') ? data.movePlacemark : true;
    this.models3DApiSvc.getModelById(data.modelId).subscribe( (res: any) => {
      if (res.hasErrors) {
        this.serverApi.createNotifiactionDialogForHttpCrisis(new Error("There was an internal error"), "There was an internal error");
      }
      this.updateModelLocationInternal(data.modelId, res.x, res.z, -1, true, data.referenceId, movePlacemark);
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.SET_MODEL_ROTATION, {modelId: data.modelId, rotation: res.rotation})
    },
    (err: HttpErrorResponse) => {
      if (err.status == 404) {
        this.serverApi.createNotifiactionDialogForHttpCrisis(err, "Model doesn't exist");
      }
    })
  }

  public rotateModel(data: any) {
    const modelId = data.modelId;
    const models3d: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    const model3D: I3DModel = models3d.find((model: I3DModel) => model.id == modelId);
    if (model3D) {
      model3D.rotation = model3D.rotation + data.angle;
      model3D.movementParams.autoRotate = false;
      this.serverApi.storeDispatch(new Set3DModel(model3D));
    }
  }

  public updateModelLocation(data: any): void {
    const movePlacemark = data.hasOwnProperty('movePlacemark') ? data.movePlacemark : true;
    let result = this.commonUtilitySvc.convertGeotoXY([{lat: data.location.latitude, lng:  data.location.longitude}]);
    let latitude = result[0].x + this.activeSite.offsetX
    let longitude = result[0].z + this.activeSite.offsetY;
    const speed = data.speed;
    const modelId = data.modelId;
    const referenceId = data.referenceId;
    const drawTrail = data.drawTrail;
    const trailColor = data.trailColor;
    const autoRotate = data.hasOwnProperty('autoRotate') ? data.autoRotate : true;
    this.updateModelLocationInternal(modelId, latitude, longitude, speed, autoRotate, referenceId, movePlacemark, drawTrail, trailColor);
  }

  private updateModelLocationInternal(modelId, latitude, longitude, speed = 10, autoRotate, referenceId, movePlacemark, drawTrail = false, trailColor = null) {
    if (speed !== -1) {
      speed = speed/1000;
    }
    const models3d: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    const model3D: I3DModel = models3d.find((model: I3DModel) => model.id == modelId);
    const originalModel = cloneDeep(model3D);
    if (model3D) {
      model3D.latitude = latitude;
      model3D.longitude = longitude;
      model3D.movementParams = {
        speed: speed,
        autoRotate: autoRotate,
        referenceId: referenceId
      }

      const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
      const placemark: IPlacemark = placemarks.find((placemark) => placemark.name === model3D.name);
      if (placemark && movePlacemark) {
        if (model3D.movementParams && model3D.movementParams.speed) {
          placemark.speed = model3D.movementParams.speed;
        }
        placemark.positionProperty.x = model3D.latitude - this.activeSite.offsetX;
        placemark.positionProperty.z = model3D.longitude - this.activeSite.offsetY;
        this.serverApi.storeDispatch(new SetPlacemark(placemark));
      }

      if (drawTrail) {
        this.drawTrail(originalModel, model3D, trailColor);
      }
      if (originalModel.latitude == model3D.latitude && originalModel.longitude == model3D.longitude) {
        this.outgoingMessageService.modelLocationUpdated({action: 'update', modelId: model3D.id, referenceId: referenceId, status:"nochange"});
      } else {
        this.serverApi.storeDispatch(new Set3DModel(model3D));
      }
    }
  }

  drawTrail(originalModel, model3D, trailColor) {
    const trailOptions = {type: "trail", borderColor: trailColor, data: {
      id: "trail_1",
      appid: "123-abc-123-123-123",
      points: [
        {x: originalModel.latitude - this.activeSite.offsetX, z: originalModel.longitude - this.activeSite.offsetY},
        {x: model3D.latitude - this.activeSite.offsetX, z: model3D.longitude - this.activeSite.offsetY},
      ]
    }
    }
    this.renderShape(trailOptions, false);
  }

  updateModelStatusInformation(data: any) {
    const modelId = data.modelId;
    const statusInfo = data.statusInfo;
    const status = data.status;
    const models3d: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
    const model3D: I3DModel = models3d.find((model: I3DModel) => model.id == modelId);
    if (model3D) {
      const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
      const placemark: IPlacemark = placemarks.find((placemark) => placemark.name === model3D.name);
      if (placemark) {
        placemark.placemarkData = statusInfo;
        placemark.selectedStatus = status;
        this.serverApi.storeDispatch(new SetPlacemark(placemark));
      }
    }
  }

  manageFODPlacemarks(data) {
    switch (data.action) {
      case 'CREATE':
        const iconUrl = `assets/resources/images/icons/address_pin_layer.png`;
        const position = this.commonUtilitySvc.convertGeotoXY([{lat: data.positionProperty.latitude, lng:  data.positionProperty.longitude}])
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MANAGE_STANDALONE_PLACEMARKS, {action: data.action, id: data.id, name: data.name,
          positionProperty: {x: position[0].x, y: 0, z: position[0].z}, iconUrl: iconUrl, url: data.url});
          break;
      case 'DELETE':
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MANAGE_STANDALONE_PLACEMARKS, {action: data.action, id: data.id});
          break;
      case 'DELETE_ALL':
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MANAGE_STANDALONE_PLACEMARKS, {action: data.action});
          break;
    }
  }

  manageExternalPlacemarks(data) {
    switch (data.action) {
      case 'CREATE':
        const iconUrl = `assets/resources/images/external/icons/${data.iconId}.png`;
        const position = this.commonUtilitySvc.convertGeotoXY([{lat: data.positionProperty.latitude, lng:  data.positionProperty.longitude}]);
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MANAGE_STANDALONE_PLACEMARKS, {action: data.action, id: data.id, name: data.name,
          positionProperty: {x: position[0].x, y: 0, z: position[0].z}, iconUrl: iconUrl, url: data.url});
          break;
      case 'DELETE':
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MANAGE_STANDALONE_PLACEMARKS, {action: data.action, id: data.id});
          break;
      case 'DELETE_ALL':
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MANAGE_STANDALONE_PLACEMARKS, {action: data.action});
          break;
      case 'DELETE_PM_BY_ICON_ID':
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.MANAGE_STANDALONE_PLACEMARKS, {action: data.action, iconId: data.iconId});
          break;
    }
  }

  populateViewpoint(viewpoint: any) {
    return {
      accessMode: "UNDEFINED",
      altitude: 0,
      category: "VIEWPOINT",
      coordinatesType: "FACILITY",
      creatorId: "",
      description: "",
      id: "",
      isDefaultInZone: false,
      latitude: 0,
      longitude: 0,
      name: "",
      parent: "WEBGL",
      parentLayerId: null,
      parentPanoramaId: null,
      parentZoneId: "",
      positionProperty: {x: viewpoint.x, y: viewpoint.y, z: viewpoint.z},
      range: 0,
      rotation: viewpoint.lookatX,
      showOnlySelectedZonesLayers: false,
      tilt: viewpoint.lookatZ,
    }
  }
}
