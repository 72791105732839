import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
  RemoveAllLayers,
  RemoveLayer,
  SetAllLayers,
  SetLayer,
  SetLayerExpanded,
  SetLayerVisibility,
  SetLayerWithChildren
} from './layers.actions';
import {
  GeneralLayerId,
  ILayer,
  IRemovedLayer,
  LAYER_MODE,
  LAYER_TYPES,
  LAYER_VISIBILITY,
  LAYER_VISIBILITY_MODE,
  LAYER_CREATED_BY_APP
} from './layers.model';
import {LayerTreeNode, UITreeNode} from '../../common/Models/UI/ui-tree-node.model';
import {UiProperties} from '../../common/Models/UI/ui.properties';
import {TreeStateHelper} from '../tree.state.helper';
import {LibIconsService} from '../../services/lib-icons.service';
import {SetSettingsLayerUiState} from '../settings.state/settings.actions';
import {isNullOrUndefined} from 'util';
import {ObjectSiteValidatorService} from '../../services/object-site-validator.service';
import {statusIconSrcForPanel, scanIconSrcForPanel, checklistIconSrcForPanel, ADDRESS_LAYER_ICON, ADDRESS_LAYER_ICON_FOR_PANEL, PANORMIC_LAYER_ICON, PANORMIC_LAYER_ICON_FOR_PANEL, QR_CODE_LAYER_ICON, QR_CODE_LAYER_ICON_FOR_PANEL} from './layers.const.utils';
import { Injectable } from '@angular/core';

export const statusIconSrcForLayerSelection: string = 'assets/layers-icons/status_all_for_layer_selection.svg';

export class LayerStateModel {
  layers: ILayer[] = [];
  tree: LayerTreeNode[] = [];
  removedLayerDetails: IRemovedLayer[] = [];
}

const additionalInfoGroupId: string = '0';

@State<LayerStateModel>({
  name: 'StateLayers',
  defaults: new LayerStateModel()
})

@Injectable()
export class LayersState {
  constructor(public libIconSrv: LibIconsService, private objectSiteValidatorService: ObjectSiteValidatorService) {
  }
  @Selector()
  static getLayers(state: LayerStateModel): ILayer[] {
    // console.log('GETTER - LAYERS');
    return state.layers;
  }

  @Selector()
  static getVisibleLayers(state: LayerStateModel): ILayer[] {
    // console.log('GETTER - VISIBLE LAYERS');
    return state.layers.filter((layer: ILayer) => layer.visible === LAYER_VISIBILITY.VISIBLE);
  }

  @Selector()
  static getVisiblityChangedLayers(state: LayerStateModel): ILayer[] {
    // console.log('GETTER - VISIBLE CHANGED LAYERS');
    return state.layers.filter((layer: ILayer) => {
      if (layer.objMode === LAYER_VISIBILITY_MODE.VISIBILITY_CHANGED) {
        layer.objMode = LAYER_VISIBILITY_MODE.IDLE;
        return true;
      }
      return false;
    });
  }

  @Selector()
  static getChangedLayers(state: LayerStateModel): ILayer[] {
    // console.log('GETTER - CHANGED LAYERS');
    return state.layers.filter((layer: ILayer) => {
      if (layer.layerMode === LAYER_MODE.EDITED) {
        layer.layerMode = LAYER_MODE.NEW_OR_OLD;
        return true;
      }
      return false;
    });
  }

  @Selector()
  static getStatusLayers(state: LayerStateModel): ILayer[] {
    // console.log('GETTER - STATUS LAYERS');
    return state.layers.filter((layer: ILayer) => layer.layerType === LAYER_TYPES.Status);
  }

  @Selector()
  static getSiteLayers(state: LayerStateModel): ILayer[] {
    // console.log('GETTER - GET SITE LAYERS');
    return state.layers.filter((layer: ILayer) => layer.layerType === LAYER_TYPES.Site);
  }

  @Selector()
  static getLayersTree(state: LayerStateModel): LayerTreeNode[] {
    // console.log('GETTER - LAYERS TREE', state.tree, state.layers);
    return state.tree;
  }

  @Selector()
  static getRemovedLayers(state: LayerStateModel): IRemovedLayer[] {
    // console.log('GETTER - REMOVED LAYERS');
    const removedLayerIdsCopies: IRemovedLayer[] = state.removedLayerDetails;
    state.removedLayerDetails = [];
    return removedLayerIdsCopies;
  }

  private setLayerNode(parentsChildren: LayerTreeNode[], layerNode: UITreeNode, keepExistingChildren: boolean = false): void {
    if (layerNode.parentId) {
      const parentNode: LayerTreeNode = TreeStateHelper.getParentNode(parentsChildren, layerNode) as LayerTreeNode;
      if (parentNode) {
        parentsChildren = parentNode.children as LayerTreeNode[];
      }
    }

    const index: number = parentsChildren.findIndex((node: LayerTreeNode) => node.id === layerNode.id);
    if (index >= 0) {
      if (layerNode.children.length > 0 || keepExistingChildren) {
        layerNode.children = parentsChildren[index].children;
      }
      parentsChildren[index] = layerNode as LayerTreeNode;
      TreeStateHelper.sortNodesArr(parentsChildren);
    } else {
      parentsChildren.push(layerNode as LayerTreeNode);
      TreeStateHelper.sortNodesArr(parentsChildren);
    }
  }

  @Action(SetAllLayers)
  setAllLayers(stateContext: StateContext<LayerStateModel>, { layers }: SetAllLayers): void {
    const state = stateContext.getState();
    layers.forEach((layer: any) => {
      this.addAllLayers(stateContext, layer);
    });
    stateContext.patchState({
      layers: [...state.layers],
      tree: [...state.tree]
    });
  }

  @Action(SetLayerWithChildren)
  setLayerWithChildren(stateContext: StateContext<LayerStateModel>, { layer }: SetLayerWithChildren): void {
    const state = stateContext.getState();
    this.addAllLayers(stateContext, layer);
    stateContext.patchState({
      layers: [...state.layers],
      tree: [...state.tree]
    });
  }

  public getGeneralLayerIconForPanel(layer: ILayer): string {
    if (layer.layerType === LAYER_TYPES.General) {
      switch (+layer.id) {
        case GeneralLayerId.ADDRESS:
          return ADDRESS_LAYER_ICON_FOR_PANEL;
        case GeneralLayerId.PANORAMIC:
          return PANORMIC_LAYER_ICON_FOR_PANEL;
        case GeneralLayerId.QR_CODE:
          return QR_CODE_LAYER_ICON_FOR_PANEL;
      }
    }

    return layer.iconURL;
  }

  @Action(SetLayer)
  setLayer({getState, patchState, dispatch }: StateContext<LayerStateModel>, { payload, patchNewState, keepLayerVisibility}: SetLayer): void {
    const state = getState();
    const originalState = getState();

    const existingLayerIndex: number = state.layers.findIndex((layer: ILayer) => layer.id === payload.id);
    if (existingLayerIndex >= 0) {
      if (keepLayerVisibility) {
        payload.visible = state.layers[existingLayerIndex].visible;
      }
      state.layers[existingLayerIndex] = payload;
    } else {
      state.layers.push(payload);
    }
    payload.objMode = LAYER_VISIBILITY_MODE.VISIBILITY_CHANGED;

    // update the tree state on every layers update
    const layerNode: UITreeNode = new LayerTreeNode(payload.id, payload.name, payload.parent, [], payload.layerType)
      .setProperty(UiProperties.VISIBLE, payload.visible)
      .setProperty(UiProperties.ICON_SRC, payload.libIconId ? this.libIconSrv.getLibIconUrlForDialog(payload.libIconId) : this.getGeneralLayerIconForPanel(payload))
      .setProperty(UiProperties.DESCRIPTION, payload.description)
      .setProperty(UiProperties.EXPANDED, payload.expanded)
      .setProperty(UiProperties.PLACEMARK_ICON_SRC, payload.libIconId ? this.libIconSrv.getLibIconUrlForScene(payload.libIconId) : payload.iconURL);
    this.setLayerNode(state.tree, layerNode, true);

    dispatch(new SetSettingsLayerUiState(payload.layerType, layerNode.id, layerNode.getProperty(UiProperties.VISIBLE).value, payload.expanded));

    if (!this.objectSiteValidatorService.validateObjectToActiveSite(payload.id)) {
      console.log('site id change in layers storage');
      state.layers = originalState.layers;
      state.tree = originalState.tree;
    }
    if (patchNewState) {
      patchState({
        layers: [...state.layers],
        tree: [...state.tree]
      });
    }
  }

  @Action(SetLayerExpanded)
  setLayerExpanded({getState, patchState, dispatch }: StateContext<LayerStateModel>, { node, expanded}: SetLayerExpanded): void {
    const state = getState();

    const existingLayerIndex: number = state.layers.findIndex((layerItr: ILayer) => layerItr.id === node.id);
    if (existingLayerIndex >= 0) {
      state.layers[existingLayerIndex].expanded = expanded;
    }
    node.setProperty(UiProperties.EXPANDED, expanded);
    dispatch(new SetSettingsLayerUiState(node.layerType, node.id, node.getProperty(UiProperties.VISIBLE).value, expanded));
    this.setLayerNode(state.tree, node);

    patchState({
      layers: [...state.layers],
      tree: [...state.tree]
    });
  }

  @Action(SetLayerVisibility)
  setLayerVisibility({getState, patchState, dispatch }: StateContext<LayerStateModel>, { node, visibleState}: SetLayerVisibility): void {
    const state = getState();

    let effectedLayers: UITreeNode[] = [node];
    if (visibleState !== LAYER_VISIBILITY.PARTIAL_VISIBLE) {
      effectedLayers = effectedLayers.concat(TreeStateHelper.getChildrenNodes(node));
    }

    (effectedLayers as LayerTreeNode[]).forEach((layer: LayerTreeNode) => {
      const existingLayerIndex: number = state.layers.findIndex((layerItr: ILayer) => layerItr.id === layer.id);
      if (existingLayerIndex >= 0 && state.layers[existingLayerIndex].visible !== visibleState) {
        state.layers[existingLayerIndex].visible = visibleState;
        state.layers[existingLayerIndex].objMode = LAYER_VISIBILITY_MODE.VISIBILITY_CHANGED;
      }
      layer.setProperty(UiProperties.VISIBLE, visibleState);
      this.setLayerNode(state.tree, layer);
      dispatch(new SetSettingsLayerUiState(layer.layerType, layer.id, visibleState, layer.getProperty(UiProperties.EXPANDED).value));
    });

    patchState({
      layers: [...state.layers],
      tree: [...state.tree]
    });
  }

  public deleteNode(tree: LayerTreeNode[], nodeId: string):  LayerTreeNode[] {
    return tree.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.deleteNode(layer.children as LayerTreeNode[], nodeId);
      }
      return (layer.id !== nodeId);
    });
  }

  public deleteNonGeneralNodes(tree: LayerTreeNode[]):  LayerTreeNode[] {
    return tree.filter((layer: LayerTreeNode) => {
      if (layer.children.length > 0) {
        layer.children = this.deleteNonGeneralNodes(layer.children as LayerTreeNode[]);
      }
      return (layer.layerType === LAYER_TYPES.General || layer.id === additionalInfoGroupId);
    });
  }

  public createRemovalOfNonGeneralLayers(layers: ILayer[]): IRemovedLayer[] {
    const returnVal: IRemovedLayer[] = [];
    layers.forEach((layer: ILayer) => {
      if (layer.layerType !== LAYER_TYPES.General && layer.id !== additionalInfoGroupId) {
        returnVal.push({removedId: layer.id, replacementId: undefined, replacedIcon: undefined});
      }
    });
    return returnVal;
  }

  public createLayerRemoval(removedState: IRemovedLayer[], removedLayerID: string, replacementLayerId: string, replaceIconUrl: string): IRemovedLayer[] {
    removedState.push({removedId: removedLayerID, replacementId: replacementLayerId, replacedIcon: replaceIconUrl});
    return removedState;
  }

  @Action(RemoveLayer)
  removeLayer({getState, patchState }: StateContext<LayerStateModel>, { removedLayerID, replacementLayerId, replaceIconUrl }: RemoveLayer): void {
    patchState({
      layers: getState().layers.filter((layer: ILayer) => layer.id !== removedLayerID),
      tree: this.deleteNode(getState().tree, removedLayerID),
      removedLayerDetails: this.createLayerRemoval(getState().removedLayerDetails, removedLayerID, replacementLayerId, replaceIconUrl)
    });
  }

  @Action(RemoveAllLayers)
  removeAllLayers({getState, patchState }: StateContext<LayerStateModel>): void {
    patchState({
      removedLayerDetails: this.createRemovalOfNonGeneralLayers(getState().layers),
      layers: getState().layers.filter((layer: ILayer) => layer.layerType === LAYER_TYPES.General || layer.id === additionalInfoGroupId),
      tree: this.deleteNonGeneralNodes(getState().tree)
    });
  }

  public addAllLayers(stateContext: StateContext<LayerStateModel>, layer: any): void {
    if (isNullOrUndefined(layer)) {
      return;
    }
    const isGroupLayer: boolean = layer.children != null && layer.layers != null;
    let layerData: ILayer;
    const convertedId: string = /*GeneralLayerId[layer.id.value] !== undefined ? GeneralLayerId[layer.id.value] : */ layer.id.value;
    if (!isGroupLayer) {
      let iconUrl: string;
      if (layer.layerType === LAYER_TYPES.Status) {
        iconUrl = statusIconSrcForPanel;
        // Status layer created by checklist app should have a different icon.
        if (layer.createdByApp && (layer.createdByApp.value === LAYER_CREATED_BY_APP.CHECKLIST)) {
          iconUrl = checklistIconSrcForPanel;
        } else {
          iconUrl = statusIconSrcForPanel;
        }
      } else if (layer.layerType === LAYER_TYPES.Scan) {
        iconUrl = scanIconSrcForPanel;
      } else if (layer.layerType === LAYER_TYPES.General) {
        switch (+layer.id.value) {
          case GeneralLayerId.ADDRESS:
            iconUrl = ADDRESS_LAYER_ICON;
            break;
          case GeneralLayerId.PANORAMIC:
            iconUrl = PANORMIC_LAYER_ICON;
            break;
          case GeneralLayerId.QR_CODE:
            iconUrl = QR_CODE_LAYER_ICON;
            break;
        }
      } else {
        iconUrl = layer.iconURL.value !== '' ? layer.iconURL.value : null;
      }
      layerData = {parent: layer.parent.empty ? undefined : layer.parent.value,
        id: convertedId,
        name: layer.name.value,
        visible: layer.state.visible,
        layerType: layer.layerType,
        description: layer.description.value,
        expanded: layer.state.expanded,
        logicType: layer.logicType.value,
        statusColors: layer.statusColors.map((color) => color != null ? color.value : null),
        libIconId: layer.libIconId != null ? layer.libIconId.value : null,
        visibleByDefault: layer.visibleByDefault != null ? layer.visibleByDefault.value : true,
        layerStatus: layer.layerStatus,
        accessMode: layer.accessMode,
        iconURL: iconUrl,
        layerMode: LAYER_MODE.NEW_OR_OLD,
        createdByApp: layer.createdByApp != null ? layer.createdByApp.value : null,
        scanProjectName: layer.scanProjectName.value,
        scanProjectBaseUrl: layer.scanProjectBaseUrl.value,
        scanProjectUUID: layer.scanProjectUUID.value,
        pointCloudUUID: layer.pointCloudUUID.value,
        visualizationMethod: layer.visualizationMethod.value ? layer.visualizationMethod.value : null,
        visualizationConf: layer.visualizationConf ? layer.visualizationConf : null,
        externalId: layer.externalId ? layer.externalId : []
      };
      this.setLayer(stateContext, new SetLayer(layerData, false));
    } else {
      layerData = {parent: layer.parent.empty ? undefined : layer.parent.value,
        layerType: LAYER_TYPES.Group,
        id: convertedId,
        name: layer.name.value,
        visible: layer.state.visible,
        description: layer.description.value,
        expanded: layer.state.expanded,
        logicType: layer.logicType.value,
        statusColors: layer.statusColors.map((color) => color != null ? color.value : null),
        libIconId: null,
        visibleByDefault: null,
        layerStatus: layer.layerStatus,
        accessMode: layer.accessMode,
        iconURL: null,
        layerMode: LAYER_MODE.NEW_OR_OLD,
        externalId: layer.externalId ? layer.externalId : []};
      this.setLayer(stateContext, new SetLayer(layerData, false));
      layer.layers.forEach((innerChild) => {
        this.addAllLayers(stateContext, innerChild);
      });
      layer.children.forEach((child) => {
        this.addAllLayers(stateContext, child);
      });
    }
  }
}
