import {Component, ElementRef, Injector, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {BaseDialog} from '../../Dialog-types/base-dialog';
import {ButtonInfo} from '../../../UI-Components/helperClasses/value-and-display.class';
import {Validators} from '../../../Validators/validators';
import {ImmutableMap} from '../../../UI-Components/list/ImmutableMap';
import {InputsBindingsModel} from '../../../Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../Dialog-types/dialog-ref';
import {NotificationDialogComponent} from '../notification-dialog/notification-dialog.component';
import {DialogService} from '../../../../services/dialogs.service';
import {DeleteDialogContext} from './delete-objects-dialog.utils';
import {IObjectInWebGL} from '../../../../Store/models.state/models.model';

@Component({
  selector: 'ins-delete-objects',
  templateUrl: './delete-objects-dialog.component.html',
  styleUrls: ['./delete-objects-dialog.component.scss', './../shared-dialogs-ui.scss',
    './../../../UI-Components/shared-UI-components.scss']
})

export class DeleteObjectsDialogComponent extends BaseDialog implements OnInit {

  private objectsHoverIndicator: Map<string, boolean> = new Map<string, boolean>();
  public buttonsInfo: ButtonInfo[] = [];
  @Input() objectsToDelete: ImmutableMap<string> = new ImmutableMap<string>();
  @Input() context: DeleteDialogContext = DeleteDialogContext.model;
  showTooltip: boolean = false;
  @ViewChildren('rowContentItemName') rowContentInner: QueryList<ElementRef>;
  public hint: string = '';
  public title: string = '';
  private _updateShowTooltip(objectKey: string, onHover: boolean): void {
    const rowsElement: ElementRef[] = this.rowContentInner.toArray();
    const objectStr: string = 'object';
    const selectedObject: ElementRef = rowsElement.find( (rowElement: ElementRef) => {
      return rowElement.nativeElement.id.substring(objectStr.length) === objectKey.toString();
    });
    this.showTooltip = onHover && selectedObject.nativeElement.scrollWidth > selectedObject.nativeElement.clientWidth;

  }

  constructor(public injector: Injector,  public validators: Validators, public dialogService: DialogService) {
    super(injector);
    this.buttonsInfo.push(
        new ButtonInfo('cancel', 'Cancel'),
        new ButtonInfo('delete', 'Delete All')
      );

  }

  ngOnInit(): void {
    this.hint = `Select ${this.context}`;
    this.objectsToDelete.set('-1', this.hint);
    this.title = this.context === DeleteDialogContext.model ? '3D Models' : '2D Layouts';
  }

  public defineDialogModel(): void {
    this.dialogModel.initModel(
      ['objectsToDelete', this.objectsToDelete, true],
      ['context', this.context]
      );
  }

  onChanges(): void { }

  removeFromList(modelKey: string): void {
    this.objectsToDelete.delete('-1');
    this.objectsToDelete = this.objectsToDelete.delete(modelKey);
    this.objectsHoverIndicator.delete(modelKey);
    this.objectsToDelete.set('-1', this.hint);
    this.changed({freeElementId: modelKey});
  }

  public handleInput(data: IObjectInWebGL): void {
    if (data.id) {
      const toDelete: boolean = !this.objectsToDelete.has(data.id);
      if (toDelete) {
        this.objectsToDelete.delete('-1');
        this.objectsToDelete.set(data.id, data.name);
        this.objectsHoverIndicator.set(data.id, false);
        this.objectsToDelete.set('-1', this.hint);
      } else {
        this.removeFromList(data.id);
      }
    }
  }

  onMouseHover(modelKey: string): void {
    if (modelKey !== '-1') {
      this.objectsHoverIndicator.set(modelKey, true);
      this._updateShowTooltip(modelKey, true);
    }
  }

  onMouseLeaveHover(modelKey: string): void {
    if (modelKey !== '-1') {
      this.objectsHoverIndicator.set(modelKey, false);
      this._updateShowTooltip(modelKey, false);
    }
  }

  onClearSelection(): void {
    this.objectsToDelete.getKeys().forEach( modelKey => this.removeFromList(modelKey));
  }

  openNotificationDialog(): void {
    const typeAdjSingle: string = this.context === DeleteDialogContext.model ? 'Model' : 'Layout';
    const typeAdjMulti: string = this.context === DeleteDialogContext.model ? 'models' : 'layouts';
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', `Delete ${typeAdjSingle}` ],
      [ 'message', `The selected ${typeAdjMulti} will be permanently deleted. Do you wish to continue?`]
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'No'),
      new ButtonInfo('yes', 'Yes'),
    ];
    dialog.onClose$().subscribe((data) => {
      if (data.userAction === 'yes') {
        this.close('delete');
      }
    });
  }
}
