import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ServerApi } from 'src/app/services/api.services/server.api';
import { SessionApiSvc } from 'src/app/services/api.services/session.api.svc';
import { IntositeEmbeddedService } from 'src/app/services/external/intosite-embedded.service';
import { SetSettingsChanged } from 'src/app/Store/settings.state/settings.actions';
import { SettingsStateModel } from 'src/app/Store/settings.state/settings.state';

@Component({
  selector: 'ins-embedded-app-landing',
  templateUrl: './embedded-app-landing.component.html',
  styleUrls: ['./embedded-app-landing.component.scss']
})
export class EmbeddedAppLandingComponent implements OnInit {

  constructor(private readonly intositeEmbeddedService: IntositeEmbeddedService, private readonly router: Router, private readonly route: ActivatedRoute, 
    private readonly serverApi: ServerApi, private readonly sessionApiSvc: SessionApiSvc, private readonly store: Store) {
    document.addEventListener('visibilitychange', this.handleVisibilityChange.bind(this));
  }

  ngOnInit(): void {
    this.intositeEmbeddedService.performIntositeLogin.subscribe((performLogin: boolean) => {
      if (performLogin) {
        const sessionId: string = localStorage.getItem('intosite_session');
        if (sessionId) {
          this.intositeEmbeddedService.embeddedLoginDone.next({alreadyLoggedIn: true});
        } else {
          this.router.navigate(['login'], {queryParams: this.route.snapshot.queryParams});
        }
      }
    });
  }
  private handleVisibilityChange() {
    if (document.hidden && !document.hasFocus()) {
      const settings: SettingsStateModel = this.serverApi.storeSelectSnap<SettingsStateModel>(state => state.StateSettings);
      if (settings.settingChanged) {
        this.store.dispatch(new SetSettingsChanged(false));
        this.sessionApiSvc.saveSettings(settings);
      }
    }
  };
}
