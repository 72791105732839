import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {LayersState} from '../../../../Store/layers.state/layers.state';
import {
  ILayer,
  LAYER_VISIBILITY,
  STATUS_LAYER_MASK
} from '../../../../Store/layers.state/layers.model';
import {ZonesState} from '../../../../Store/zones.state/zones.state';
import {IRemovedZone, IZone} from '../../../../Store/zones.state/zones.model';
import {ModelsState} from '../../../../Store/models.state/models.state';
import {I3DModel, ILayout} from '../../../../Store/models.state/models.model';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService, IActionCmd} from '../../../../services/cmd-router.service';
import {DialogModel} from '../../../../common/Models/Dialog/dialog.model';
import {DialogComSvc} from '../../../../services/modeless-com.service';
import {DialogRef} from '../../../../common/Forms/Dialog-types/dialog-ref';
import {ServerApi} from '../../../../services/api.services/server.api';
import {PlacemarksState} from '../../../../Store/placemarks.state/placemarks.state';
import {CoordinatesType, IPlacemark, WEBGL_OBJECT_MODE} from '../../../../Store/placemarks.state/placemarks.model';
import {LibIconsService} from '../../../../services/lib-icons.service';
import {
  SetOpenedPanel,
  SetSelectedMode,
  ToggleRightMenu,
  UpdateCurrViewpointParameters
} from '../../../../Store/app.state/app.actions';
import {SCENE_MODE} from '../../../../Store/app.state/app.model';
import {AppState} from '../../../../Store/app.state/app.state';
import {InputsBindingsModel} from '../../../../common/Models/Dialog/inputs-binding.model';
import {DialogService, DialogType} from '../../../../services/dialogs.service';
import {PanoSearchDialogComponent} from '../../../../common/Forms/Dialogs/pano-search-dialog/pano-search-dialog.component';
import {IViewpoint} from '../../../../Store/viewpoints.state/viewpoints.model';
import {ViewpointsHelper} from '../../../../Store/viewpoints.state/viewpoints.helper';
import {ModeDependentPanels} from '../../side-bar.container/side-bar.container';
import {MessagesBank, StatusService} from '../../../../services/status.service';
import {ISite} from '../../../../Store/sites.state/sites.model';
import {isNullOrUndefined} from 'util';
import {SessionApiSvc} from '../../../../services/api.services/session.api.svc';
import {ApiTools} from 'src/app/services/api.services/api.tools';
import {WebshareApiSvc} from 'src/app/services/api.services/webshare.api.svc';
import {NotificationDialogComponent} from 'src/app/common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from 'src/app/common/UI-Components/helperClasses/value-and-display.class';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';
import {ACTION_TYPE, Actions, PermissionsManager} from 'src/app/services/permissions-manager';
import {ViewerObjectType} from 'src/app/common/Models/UI/viewer-object-type.enum';
import {STATUS_LAYER_ICON_PRE} from 'src/app/Store/layers.state/layers.const.utils';

interface IComData {
  cmd: string;
  args: any;
}

@Component({
  selector: 'ins-pano-container',
  templateUrl: './pano-container.component.html',
  styleUrls: ['./pano-container.component.scss']
})
export class PanoContainerComponent implements OnInit, OnChanges {

  @Select(LayersState.getLayers) allLayers$: Observable<ILayer[]>;
  @Select(LayersState.getVisiblityChangedLayers) layers$: Observable<ILayer[]>;
  @Select(ZonesState.getZones) zones$: Observable<IZone[]>;
  @Select(ZonesState.getRemovedZones) removedZones$: Observable<IRemovedZone>;
  @Select(ModelsState.getLayouts) layouts$: Observable<ILayout[]>;
  @Select(ModelsState.get3DModels) models3D$: Observable<I3DModel[]>;
  @Select(ModelsState.getNew3DModels) new3DModels$: Observable<I3DModel[]>;
  @Select(ModelsState.getUpdated3DModels) updated3DModels$: Observable<I3DModel[]>;
  @Select(PlacemarksState.getPlacemarksByType(CoordinatesType.FACILITY, WEBGL_OBJECT_MODE.NEW)) newPlacemarks$: Observable<IPlacemark[]>;
  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  @Select(AppState.getCurrentViewpoint) currVp$: Observable<IViewpoint>;

  @Input() panoSceneBlocked: boolean = false;
  @Input() rightMenuOpened: boolean = true;
  @Input() sceneMode: SCENE_MODE;
  lastSceneMode: SCENE_MODE;
  @Input() openedPanelName: string;
  tempUnHandledNewPlacemarks: IPlacemark[] = [];

  searchDialogRef: PanoSearchDialogComponent;
  iframeSrc: string = environment.panoAppUrl;
  frameWin: Window;
  panoCmdQueue: IComData[] = [];
  frameLoaded: boolean = false;
  site: ISite;
  public zonesVisibilityMap: Map<string, boolean> = new Map();
  public webshareAuthPending: boolean = false;

  public showNavigator: boolean = false;
  constructor(private cmdRouterSvc: CmdRouterService, private dialogComSvc: DialogComSvc, private statusBar: StatusService,
              public serverApi: ServerApi, public libIconSrv: LibIconsService, public store: Store, public dialogService: DialogService,
              public sessionApiSvc: SessionApiSvc, private webshareApiSvc: WebshareApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    window.addEventListener('message', this.handleIFrameCmds.bind(this));
    this.activeSite$.subscribe((site: ISite) => {
      this.site = site;
      if (!site || (site && site.id != parseInt(ApiTools.defaultSiteId))) {
        this.frameLoaded = false;
      }
      if (!isNullOrUndefined(site)) {
        if (localStorage.getItem("angularjsApp") == "yes") {
          this.iframeSrc = `${environment.baseServerUrl}/${environment.externalApp}/PanoramicViewer?siteId=${site.id}`;
        } else {
          this.iframeSrc = `${environment.panoAppUrl}?siteId=${site.id}`;
        }
      }
      this.setPermissionsForPanoViewer();
      if (this.site?.placemarkClustering != null) {
        this.sendCmdToIFrame({cmd: 'setPlacemarkClustering', args: [this.site.placemarkClustering]});
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {

    if (this.sceneMode === SCENE_MODE.Panoramic) {
      requestAnimationFrame(() => {
        const iframeElem: HTMLIFrameElement = document.getElementById('panoFrame') as HTMLIFrameElement;
        this.frameWin = iframeElem.contentWindow;
      });
    }

    if (this.sceneMode !== SCENE_MODE.Panoramic) {
      this.showNavigator = false;
    }

    if (this.frameWin && changes.panoSceneBlocked ) {
      this.sendCmdToIFrame({cmd: 'blockPanoButton', args: [this.panoSceneBlocked]});
    }

    if (this.frameWin && changes.rightMenuOpened ) {
      this.sendCmdToIFrame({cmd: 'setPanoMenuOpen', args: [changes.rightMenuOpened.currentValue]});
    }
  }

  public setZonesListener(): void {
    this.removedZones$.subscribe((zone: IRemovedZone) => {
      if (zone) {
        this.sendCmdToIFrame({cmd: 'replaceZone', args: [zone.removedId, zone.replacementId]});
      }
    });

    this.zones$.subscribe((zones: IZone[]) => {
      const zonesArr: IZone[] = [];
      zones.forEach(zone => {
        if (!this.zonesVisibilityMap.has(zone.id) || this.zonesVisibilityMap.get(zone.id) != zone.visible) {
          this.zonesVisibilityMap.set(zone.id, zone.visible);
          zonesArr.push(zone);
        }
      });
      if (zonesArr.length > 0) {
        const visibilityArr: string = zonesArr.map((zone: IZone) => zone.visible).join(';');
        const idsArr: string = zonesArr.map((zone: IZone) => zone.id).join(';');
        this.sendCmdToIFrame({cmd: 'setZonesVisibility', args: [idsArr, visibilityArr]});
      }
    });
  }

  ngOnInit(): void {
    this.initActionCmdHandler();
    this.setLayersVisibility();
    this.setZonesListener();
  }

  public initActionCmdHandler(): void {
    this.cmdRouterSvc.actionCmdListener$(CMD_TARGETS.PANORAMIC_MANAGER)
      .subscribe((actionCmd: IActionCmd) => {
        switch (actionCmd.action) {
          case CMD_ACTIONS.GO_TO_PANO: {
            if (this.panoSceneBlocked) {
              return;
            }
            this.showPanoLoadingProgressBar();
            let currVp: IViewpoint;
            this.currVp$.subscribe((vp: IViewpoint) => {
              currVp = vp;
            }).unsubscribe();
            this.lastSceneMode = this.sceneMode;
            if (ModeDependentPanels.find((name: string) => name === this.openedPanelName) !== undefined) {
              // opened panel is not allowed, we should close it
              this.store.dispatch(new SetOpenedPanel(''));
            }
            this.store.dispatch(new SetSelectedMode(SCENE_MODE.Panoramic));
            const pmId: string = actionCmd.options['pmId'];
            this.loadPano(pmId, ViewpointsHelper.getVpHeading(currVp).toString(), '0');
            break;
          }
          case CMD_ACTIONS.GO_TO_SCAN_PM: {
            if (this.panoSceneBlocked) {
              return;
            }
            this.showPanoLoadingProgressBar();
            let currVp: IViewpoint;
            this.currVp$.subscribe((vp: IViewpoint) => {
              currVp = vp;
            }).unsubscribe();
            this.lastSceneMode = this.sceneMode;
            if (ModeDependentPanels.find((name: string) => name === this.openedPanelName) !== undefined) {
              // opened panel is not allowed, we should close it
              this.store.dispatch(new SetOpenedPanel(''));
            }
            this.store.dispatch(new SetSelectedMode(SCENE_MODE.Panoramic));
            const pmId: string = actionCmd.options['pmId'];
            const pmType: string = actionCmd.options['pmType'];
            this.loadScanPM(pmId, pmType, ViewpointsHelper.getVpHeading(currVp).toString(), '0');
            break;
          }
          case CMD_ACTIONS.OPEN_PANO_FROM_NAVIGATOR: {
            this.showPanoLoadingProgressBar();
            const placemarkId: string = actionCmd.options['placemarkId'];
            const isScanPM: boolean = actionCmd.options['isScanPM'];
            this.sendCmdToIFrame({cmd: 'enterPanoramicView', args: []});
            this.sendCmdToIFrame({cmd: 'openPanoFromNavigator', args: [placemarkId, isScanPM]});
            break;
          }
          case CMD_ACTIONS.VP_NEW_EDIT_MODE: {
            const newVpMode: boolean = actionCmd.options['newMode'];
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            this.updateViewpointProperties(true, newVpMode);
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              // get out of the listen change mode
              this.updateViewpointProperties(false);
            });
            break;
          }
          case CMD_ACTIONS.GO_TO_VP_MODE: {
            this.showPanoLoadingProgressBar();
            if (this.sceneMode !== SCENE_MODE.Panoramic) {
              this.lastSceneMode = this.sceneMode;
              if (ModeDependentPanels.find((name: string) => name === this.openedPanelName) !== undefined) {
                // opened panel is not allowed, we should close it
                this.store.dispatch(new SetOpenedPanel(''));
              }
              this.store.dispatch(new SetSelectedMode(SCENE_MODE.Panoramic));
            }
            const selectedVP: IViewpoint = actionCmd.options['selectedVP'];

            const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
            const selectedPm: IPlacemark = placemarks.find((pm: IPlacemark) => pm.id == selectedVP.parentPanoramaId);
            if (selectedPm && selectedPm.scanUUID) {
              this.loadScanPM(selectedVP.parentPanoramaId, 'SCAN_PLACEMARK', ViewpointsHelper.getVpHeading(selectedVP).toString(), '0');
            } else {
              this.loadPano(selectedVP.parentPanoramaId, ViewpointsHelper.getVpHeading(selectedVP).toString(), '0');
            }
            this.goToViewpoint(selectedVP);
            break;
          }
          case CMD_ACTIONS.TOGGLE_EDIT_MODE: {
            this.sendCmdToIFrame({cmd: 'toggleEditMode', args: undefined});
            break;
          }
          case CMD_ACTIONS.SAVE_CHANGES_MODE: {
            const dialogRef: DialogRef = actionCmd.options['dialogRef'];
            dialogRef.onClose$().subscribe((data: DialogModel) => {
              if (data.userAction === 'yes') {
                this.sendCmdToIFrame({cmd: 'saveChangesInEditMode', args: [true]});
              } else if (data.userAction === 'no') {
                this.sendCmdToIFrame({cmd: 'saveChangesInEditMode', args: [false]});
              }
            });
            break;
          }
          case CMD_ACTIONS.FILL_VIEWPOINT_PROPERTIES: {
            this.sendCmdToIFrame({cmd: 'fillViewpointPropertiesInternal', args: undefined});
            break;
          }
          case CMD_ACTIONS.STOP_RENDERING_VP_MODE: {
            this.sendCmdToIFrame({cmd: 'stopRendering', args: undefined});
            break;
          }
          case CMD_ACTIONS.REFRESH_LAYERS: {
            this.sendCmdToIFrame({cmd: 'refreshLayers', args: undefined});
            break;
          }
          case CMD_ACTIONS.REFRESH_ZONES: {
            this.sendCmdToIFrame({cmd: 'refreshZones', args: undefined});
            break;
          }
          case CMD_ACTIONS.CLEAR_PANORAMIC_VIEWER: {
            this.sendCmdToIFrame({cmd: 'clearPanoViewer', args: undefined});
            break;
          }
          case CMD_ACTIONS.SET_WEBSHARE_PARAMS: {
            const webshareSiteAuthType: string = actionCmd.options['webshareSiteAuthType'];
            const webshareLoadImageMethod: string = actionCmd.options['webshareLoadImageMethod'];
            const webshareAuthToken: string = actionCmd.options['webshareAuthToken'];
            const webshareApiKey: string = actionCmd.options['webshareApiKey'];
            this.sendCmdToIFrame({ cmd: 'setWebshareParams', args: [webshareSiteAuthType, webshareLoadImageMethod, webshareAuthToken, webshareApiKey] });
            break;
          }
        }
      });
  }

  public goToViewpoint(vp: IViewpoint): void {
    if (vp && vp.parent === 'PANORAMIC') {
      this.sendCmdToIFrame({cmd: 'goToViewpoint', args: [vp.rotation, vp.range, vp.tilt, vp.parentPanoramaId, vp.name]});
    }
  }

  public openNavigator(placemarkId: string, isScanPano: boolean): void {
    let selectedPm: IPlacemark;
    if (!isScanPano) {
      const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
      selectedPm = placemarks.find((pm: IPlacemark) => pm.id == placemarkId);
    }

    if (isScanPano || (selectedPm && selectedPm.coordinatesType === CoordinatesType.FACILITY)) {
      this.showNavigator = true;
    }
  }

  public loadPano(panoPmId: string, heading: string, pitch: string): void {
    this.openNavigator(panoPmId, false);
    this.sendCmdToIFrame({cmd: 'enterPanoramicView', args: []});
    this.sendCmdToIFrame({cmd: 'loadPano', args: [panoPmId, heading, pitch]});
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_PanoViewAccess');
  }

  public loadScanPM(scanPmId: string, scanPmType: string, heading: string, pitch: string): void {
    this.openNavigator(scanPmId, true);
    this.sendCmdToIFrame({cmd: 'enterPanoramicView', args: []});
    this.sendCmdToIFrame({cmd: 'loadScanPM', args: [scanPmId, scanPmType, heading, pitch]});
    // Log Siemens Analytics event
    this.siemensAnalyticsService.logEvent('INS_PanoViewAccess');
  }

  public handleIFrameCmds(cmd: any): void {
    if (cmd.data.origin !== 'panoView') {
      return;
    }
    switch (cmd.data.cmd) {
      case 'exitPanoramicView': {
        this.exitPanoView();
        this.hidePanoLoadingProgressBar();
        break;
      }
      case 'panoLoaded': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.PANO_LOADED, {placemarkId: cmd.data.data[0]});
        break;
      }
      case 'panoLinkClicked': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.PANO_LINK_CLICKED, {placemarkId: cmd.data.data[0]});
        break;
      }
      case 'panoLinksCreated': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.PANO_LINKS_CREATED, {});
        break;
      }
      case 'clickEvent': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.CLICK_OUTSIDE_DIR, CMD_ACTIONS.CLICK);
        break;
      }
      case 'openSearchDialog': {
        const placemarks: any = cmd.data.data[0];
        const pmsIcons: any = cmd.data.data[1];
        this.handleSearchDialog(placemarks, pmsIcons);
        break;
      }
      case 'searchDialogClosed': {
        this.searchDialogRef && this.searchDialogRef.close();
        break;
      }
      case 'updateSearchDialog': {
        const placemarks: any = cmd.data.data[0];
        const pmsIcons: any = cmd.data.data[1];
        this.dialogComSvc.sendToActiveDialog({allPlacemarks: placemarks, allPlacemarksIcons: pmsIcons, dirtyData: true});
        break;
      }
      case 'setViewpointProperties': {
        const x: any = cmd.data.data[0].toFixed(10);
        const y: any = cmd.data.data[1].toFixed(10);
        const z: any = cmd.data.data[2].toFixed(10);
        const lat: any = cmd.data.data[3].toFixed(10);
        const lon: any = cmd.data.data[4].toFixed(10);
        const fov: any = cmd.data.data[5].toFixed(10);
        const panoId: any = cmd.data.data[6];
        const initialRotation: any = cmd.data.data[7];
        this.dialogComSvc.sendToActiveDialog({x_coord: x, y_coord: y, z_coord: z, heading_vp: lat, range_vp: fov, tilt_vp: lon, panoramaId: panoId});
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.FILL_VIEWPOINT_PROPERTIES, {heading_vp: lat, initialRotation: initialRotation});

        const params: any = {
          parent: 'PANORAMIC',
          coordinatesType: 'FACILITY',
          positionProperty: {x, y , z},
          rotation: lat,
          range: fov,
          tilt: lon,
          parentPanoramaId: panoId
        };
        this.store.dispatch(new UpdateCurrViewpointParameters(params));
        break;
      }
      case 'setPanoViewerLockedMode': {
        const isLockedMode: any = cmd.data.data;
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.VIEWPOINTS_PANEL, CMD_ACTIONS.SET_PANO_LOCKED_MODE, {locked: isLockedMode});
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.NAVIGATOR, CMD_ACTIONS.SET_PANO_LOCKED_MODE, {locked: isLockedMode});
        break;
      }
      case 'vpChangedEvent': {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.VIEWPOINTS_PANEL, CMD_ACTIONS.REMOVE_SELECTED_VP);
        break;
      }
      case 'finishProgressBar': {
        this.hidePanoLoadingProgressBar();
        break;
      }
      case 'showProgressBar': {
        this.showPanoLoadingProgressBar();
        break;
      }
      case 'initPano': {
        if (this.site){
          this.frameLoaded = true;
        }
        this.runCmdQueue();
        break;
      }
      case 'toggleRightMenu': {
        this.store.dispatch(new ToggleRightMenu());
        break;
      }
      case 'showPointCloud': {
        const scanUUID : any = cmd.data.data[0];
        const pointCloudUUID: any = cmd.data.data[1];
        const x: any = cmd.data.data[2];
        const y: any = cmd.data.data[3];
        const z: any = cmd.data.data[4];
        const phi: any = cmd.data.data[5];
        const theta: any = cmd.data.data[6];
        const projectName: any = cmd.data.data[7];
        const fov: any = cmd.data.data[8];
        this.showPointCloud(scanUUID, pointCloudUUID, x, y, z, phi, theta, projectName, fov);
        break;

      }
      case 'showNotification': {
        const type = cmd.data.data[0];
        const title = cmd.data.data[1];
        const message = cmd.data.data[2];
        const buttonText = cmd.data.data[3];
        const action = cmd.data.data[4] || 'close';

        const inputsBinding: InputsBindingsModel = new Map([
          [ 'type', type],
          [ 'title', title],
          [ 'message', message],
          [ 'onXAction', buttonText]
        ]);
        const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
        const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);
        dialogComp.buttonsInfo = [
          new ButtonInfo(action, buttonText),
        ];
        dialog.onClose$().subscribe( (model: DialogModel) => {
          if (model.userAction == 'exitPanoramicView') {
            this.exitPanoView();
          }
        })
      }
    }
  }

  private showPanoLoadingProgressBar(): void {
    this.statusBar.removeStatus(MessagesBank.LOADING_PANO);
    this.statusBar.addNewStatus(MessagesBank.LOADING_PANO);
  }

  private hidePanoLoadingProgressBar(): void {
    this.statusBar.removeStatus(MessagesBank.LOADING_PANO);
  }

  private async showPointCloud(scanUUID: any, pointCloudUUID: any, x: any, y: any, z: any, phi: any, theta: any, projectName: any, fov: any) {
    if (this.webshareAuthPending) {
      return;
    }
    this.webshareAuthPending = true;
    const sub = this.webshareApiSvc.webShareLoginInfoSubject()
      .subscribe( async ( success ) => {
        sub.unsubscribe();
        if (success) {
          const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
          const placemark: IPlacemark = placemarks.find( pm => pm.scanUUID == scanUUID);
          try {
            await this.webshareApiSvc.checkUserAccessToWebshareProject(placemark.parentLayerId);
            this.webshareApiSvc.getWebShareScanProjectDetails(projectName, scanUUID)
              .subscribe( (res: any) => {
                if ( res.hasOwnProperty('TransformationGlobal') ) {
                  x = res['TransformationGlobal'][12];
                  z = res['TransformationGlobal'][13];
                  y = res['TransformationGlobal'][14];
                }

                this.webshareApiSvc.showPointCloud(fov, pointCloudUUID, x, y, z, phi, theta, projectName);
            })
          } catch (error) {}
        }
        this.webshareAuthPending = false;
      })
    this.webshareApiSvc.loginToWebshare();
  }

  public handleSearchDialog(placemarks: any, pmsIcons: any): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'allPlacemarks', placemarks],
      [ 'allPlacemarksIcons', pmsIcons]
    ]);
    const dialog: DialogRef = this.dialogService.createDialog(PanoSearchDialogComponent, DialogType.Modeless, inputsBinding);
    dialog.onClose$().subscribe(() => {
      this.sendCmdToIFrame({cmd: 'searchDialogClosed', args: undefined});
    });
    this.searchDialogRef = (dialog.instance as PanoSearchDialogComponent);
    this.searchDialogRef.placemarkClicked.subscribe((placemark: any) => {
      this.sendCmdToIFrame({cmd: 'lookAtPlacemark', args: [placemark]});
    });

  }

  public setLayersVisibility(): void {
    this.layers$.subscribe((layers: ILayer[]) => {
      const visibilityArr: string = layers.map((layer: ILayer) => layer.visible === LAYER_VISIBILITY.VISIBLE).join(';');
      const idsArr: string = layers.map((layer: ILayer) => layer.id).join(';');
      this.sendCmdToIFrame({cmd: 'setLayersVisibility', args: [idsArr, visibilityArr]});

      this.handleTempPms(layers);
    });
  }

  public exitPanoView(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.VIEWPOINTS_PANEL, CMD_ACTIONS.REMOVE_SELECTED_VP);
    this.store.dispatch(new SetSelectedMode(this.lastSceneMode));
  }

  public displayPlacemarkContent(pmId: string): void {
    const selectedPm: IPlacemark = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks)
      .find((pm: IPlacemark) => pm.id === pmId);
    if (!selectedPm) {
      console.log('ERROR - placemark not found');
    }
    let url: string = selectedPm.url;
    if (!url) {
      return;
    }

    if (selectedPm.placemarkUiType === 'BALOON') {
      this.sendCmdToIFrame({cmd: 'openPlacemarkByBalloon', args: [selectedPm.id, url, selectedPm.settings.width, selectedPm.settings.height]});
    } else {
      const popupSaveAs: boolean = this.shouldPopupSaveAsDialog(url);
      let openInWin: string = '';
      if (popupSaveAs) {
        openInWin = '_self';
      }
      if (url.match('(.*)(.jpg|.png|.jpeg)(.*)')) {
        url += '&template';
      }

      const left: number = (screen.width / 2) - (selectedPm.settings.width / 2) + window.screenX;
      const top: number = (screen.height / 2) - (selectedPm.settings.height / 2);
      window.open(url, openInWin,
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no,' +
        'width=' + selectedPm.settings.width + ', height=' + selectedPm.settings.height + ', top=' + top + ', left=' + left);
    }
  }

  public shouldPopupSaveAsDialog(fileName: string): boolean {
    const typesToOpenDialog: string[] = ['avi', 'mp4', 'ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf'];
    const result: string = typesToOpenDialog.find((extension: string) => {
      return fileName.includes(extension);
    });
    return result !== undefined;
  }

  public sendCmdToIFrame(data: IComData): void {
    this.panoCmdQueue.push(data);
    this.runCmdQueue();
  }

  public runCmdQueue(): void {
    if (this.frameLoaded) {
      this.panoCmdQueue.forEach((cmd: IComData) =>
        this.frameWin.postMessage(cmd, '*'));

      this.panoCmdQueue = [];
    }
  }

  public panoFrameLoaded(): void {
    const iframeElem: HTMLIFrameElement = document.getElementById('panoFrame') as HTMLIFrameElement;
    this.frameWin = iframeElem.contentWindow;
  }

  public setPlacemarksOnScene(): void {
    this.newPlacemarks$.subscribe((pms: IPlacemark[]) => {
      // console.log('pms', pms);
      const layers: ILayer[] = this.store.selectSnapshot<ILayer[]>((state: any) => state.StateLayers.layers);
      pms.forEach((pm: IPlacemark) => {
        this.tempUnHandledNewPlacemarks.push(pm);
        this.handleTempPms(layers);
      });
    });
  }

  public hasStatus(layer: ILayer, statusMask: STATUS_LAYER_MASK): boolean {
    return (layer.layerStatus & statusMask) === statusMask;
  }

  public handleTempPms(layers: ILayer[]): void {
    this.tempUnHandledNewPlacemarks = this.tempUnHandledNewPlacemarks.filter((pm: IPlacemark) => {
      const layer: ILayer = layers.find((layer1: ILayer) => layer1.id === pm.parentLayerId);
      if (!layer) {
        return true;
      }
      let iconUrl: string;
      let pmType: string = pm.category;
      if (pm.statusType) {
        // this is a status pm
        pmType = 'STATUS_PLACEMARK';
        iconUrl = this.getStatusPMIcon(pm, layer);
        for (let i = 0; i < 6; i ++) {
          pm.issues[i] = this.hasStatus(layer, STATUS_LAYER_MASK['STATUS' + (i + 1)]) ? pm.issues[i] : -1;
        }
      } else {
        iconUrl = layer.libIconId ? this.libIconSrv.getLibIconUrlForScene(layer.libIconId) : layer.iconURL;
      }

      if (iconUrl) {
        this.sendCmdToIFrame({cmd: 'createPlacemark', args: [
            pmType, pm.id, pm.name, (pm.positionProperty.x)?.toFixed(10), (pm.positionProperty.y)?.toFixed(10), (pm.positionProperty.z)?.toFixed(10), iconUrl, pm.url, pm.description,
            'true' /*visibility*/, pm.style.showLeg, pm.style.showLabelAlways, pm.placemarkUiType, pm.parentLayerId, pm.parentZoneId,
            pm.issues ? pm.issues.join(';') : '', 'CHECKLIST', undefined
          ]});
      }
      return false;
    });
  }

  public getStatusPMIcon(pm: IPlacemark, layer: ILayer): string {
    // TODO: url change
    let retValue: string = environment.windowOrigin + '/' + STATUS_LAYER_ICON_PRE;
    if (pm.statusType === 'STATUS') {
      retValue += (layer.statusColors[pm.selectedStatus] !== undefined ? layer.statusColors[pm.selectedStatus] : '') + '.png';
    } else if (layer.logicType === 1) {
      let maxIssue: number = 0;
      for (let i = 0; i < 6; i++) {
        maxIssue = pm.issues[i] > pm.issues[maxIssue] ? i : maxIssue;
      }
      retValue += (layer.statusColors[maxIssue] !== undefined ? layer.statusColors[maxIssue] : '') + '.png';
    } else {
      for (let i = 0; i < 6; i++) {
        if (pm.issues[i] > 0  && this.hasStatus(layer, STATUS_LAYER_MASK['STATUS' + (i + 1)])) {
          retValue += (layer.statusColors[i] !== undefined ? layer.statusColors[i] : '') + '.png';
          break;
        }
      }
    }
    return retValue;
  }

  fillViewpointPropertiesInternal(): void {
    this.sendCmdToIFrame({cmd: 'fillViewpointPropertiesInternal', args: undefined});
  }

  public updateViewpointProperties(isCheckMode: boolean, newVpMode: boolean = false): void {
    if (isCheckMode) {
      // Start fillViewpointPropertiesInternal
      this.sendCmdToIFrame({cmd: 'startChangeViewpointMode', args: [newVpMode]});
    } else {
      // stop fillViewpointPropertiesInternal
      this.sendCmdToIFrame({cmd: 'stopChangeViewpointMode', args: undefined});
    }
  }

  public setPermissionsForPanoViewer(): void {
    PermissionsManager.isPermitted$(Actions.CREATE_PLACEMARK_BUTTON).subscribe((isPerm: boolean) => {
      this.sendCmdToIFrame({cmd: 'setPermissions', args: [ViewerObjectType.PLACEMARK, ACTION_TYPE.UPDATE, Actions.permPlacemarksToEdit.length > 0 ? true : false]});
    });
  }
}
