import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ins-loading-circle',
  template: `<div><mat-icon class="spinner" [svgIcon]="getSvgIcon()"></mat-icon></div>`,
  styleUrls: ['./loading-circle.component.scss']
})
export class LoadingCircleComponent implements OnInit {

  @Input() size = 24;
  @Input() background = 'light';
  
  constructor(private readonly _elementRef: ElementRef) { }

  ngOnInit(): void {
    this._elementRef.nativeElement.style.setProperty('--spinner-size', `${this.size}px`);
  }

  getSvgIcon(): string {
    return `loader.circle.bg.${this.background}`;
  }
}
