import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntositeEmbeddedService {
  public viewpointSelectedZones = new Map();
  public viewpointSelectedLayers = new Map();
  public siteId;
  public externalSiteId;
  public viewpointToLoad;
  public userName;
  public company;

  public loadSiteInEmbeddedMode$ = new Subject<boolean>();
  public embeddedLoginDone: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public performIntositeLogin: Subject<boolean> = new Subject<boolean>();
  constructor() { }
}
