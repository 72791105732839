import {ISettings} from './settings.model';
import {LAYER_TYPES, LAYER_VISIBILITY} from '../layers.state/layers.model';
import {IViewpoint} from '../viewpoints.state/viewpoints.model';

export class SetSettings {
  static readonly type: string = '[Settings] FillSettings';

  constructor(public settings: ISettings) {}
}

export class SetSettingsSitesExpandedObj {
  static readonly type: string = '[Settings] SetSitesExpandedObj';

  constructor(public sitesExpandedObj: any) {}
}

export class SetSettingsZoneVisibility {
  static readonly type: string = '[Settings] SetSettingsZoneVisibility';

  constructor(public zoneId: string, public visible: boolean) {}
}

export class SetSettingsLastViewpoint {
  static readonly type: string = '[Settings] SetSettingsLastViewpoint';

  constructor(public viewpoint: IViewpoint) {}
}

export class SetDialogViewed {
  static readonly type: string = '[Settings] SetDialogViewed';

  constructor(public dialogName: string) {}
}

export class SetSettingsLayerUiState {
  static readonly type: string = '[Settings] SetSettingsLayerUiState';

  constructor(public layerType: LAYER_TYPES, public layerId: string, public visibleIn: LAYER_VISIBILITY, public expandedIn: boolean) {}
}

export class SetSettingsChanged {
  static readonly type: string = '[Settings] SetSettingsChanged';

  constructor(public changed: boolean) {}
}
