import {Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {AppState} from '../Store/app.state/app.state';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../services/cmd-router.service';

@Directive({
  selector: '[insBlockHostFrame]'
})
export class BlockHostFrameDirective implements OnChanges, OnInit {

  @Input() trigger: any;

  @Select(AppState.getPartialBlockedMode) blockMode: Observable<boolean>;

  coverDiv: any;

  constructor(private _elementRef: ElementRef, private renderer: Renderer2, private cmdRouterSvc: CmdRouterService) {

    this.coverDiv = this.renderer.createElement('div');

    this.blockMode.subscribe((shouldBlock: boolean) => {
      const parent: any = this._elementRef.nativeElement.parentNode;
      const pointerEvents = 'pointer-events';
      if (parent) {
        if (shouldBlock) {
          this.renderer.appendChild(parent, this.coverDiv);
          this.renderer.setStyle(this._elementRef.nativeElement, pointerEvents, 'none');
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.SCENE, CMD_ACTIONS.CLOSE_ACTIVE_PANEL);
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CLOSE_ACTIVE_PANEL);
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CLOSE_ACTIVE_PANEL);
        } else {
          this.renderer.removeChild(parent, this.coverDiv);
          this.renderer.removeStyle(this._elementRef.nativeElement, pointerEvents);
          this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.SCENE, CMD_ACTIONS.OPEN_LAST_ACTIVE_PANEL);
        }
      }
    });
  }

  ngOnInit(): void {
    this.setStyleOfCoverDiv();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // we will listen on any given trigger. when the trigger fires,
    // we will wait 0.5 sec to let the new state render and then read the new values of the object.
    // (Human response time ~750ms so the user will never fill it)
    const intervalHandler: any = setInterval(() => {
      this.setStyleOfCoverDiv();
    }, 20);
    setTimeout(() => {
      clearInterval(intervalHandler);
    }, 501);
  }

  setStyleOfCoverDiv(): void {
    const bbRect: ClientRect = this._elementRef.nativeElement.getBoundingClientRect();
    this.renderer.setStyle(this.coverDiv, 'position', 'fixed');
    this.renderer.setStyle(this.coverDiv, 'left', bbRect.left + 'px');
    this.renderer.setStyle(this.coverDiv, 'top', bbRect.top + 'px');
    this.renderer.setStyle(this.coverDiv, 'height', bbRect.height + 'px');
    this.renderer.setStyle(this.coverDiv, 'width', bbRect.width + 'px');
    this.renderer.setStyle(this.coverDiv, 'background', 'rgba(0, 0, 0, 0.3)');
    this.renderer.setStyle(this.coverDiv, 'pointer-events', 'none');
    this.renderer.setStyle(this.coverDiv, 'z-index', '1999');
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setStyleOfCoverDiv();
  }
}
