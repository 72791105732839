import {IViewpoint} from './viewpoints.model';

export const DEFAULT_ZOOM_LEVEL: number = 10;
export class ViewpointsHelper {

  static zoomToAlt: number[] = [
    99000, 98000, 97000, 96000, 95000, 94000, 93000, 92000, 91000, 90000, 82000, 38000,
    19000, 9200, 4300, 2000, 990, 570, 280, 100, 36, 12, 0];

  public static getVpHeading(vp: IViewpoint): number {
    if (!vp.positionProperty) {
      return 0;
    }
    const x: number = -1 * (vp.tilt - vp.positionProperty.z);
    const y: number = (vp.rotation - vp.positionProperty.x);
    const heading: number = this.toDegree(Math.atan2(x, y));
    return heading;
  }

  public static toDegree(rads: number): number {
    return rads * 180 / Math.PI;
  }

  public static zoomToAltitude(zoom: number): number {
    return ViewpointsHelper.zoomToAlt[zoom < 0 ? 0 : zoom > 21 ? 21 : zoom];
  }

  public static altitudeToZoom(altitude: number): number {
    // if altitude is above 99 km, return zoom level 4
    if (altitude > 99000) {
      return 4;
    }
    for (let i: number = 0; i < 22; ++i) {
      if (altitude > ViewpointsHelper.zoomToAlt[i] - ((ViewpointsHelper.zoomToAlt[i] - ViewpointsHelper.zoomToAlt[i + 1]) / 2)) {
        return i;
      }
    }
    return DEFAULT_ZOOM_LEVEL;
  }

}
