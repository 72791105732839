import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlacemarkClusterService {
  private static readonly BACKGROUND_COLOR = 'rgba(147, 220, 237)';
  private static readonly BORDER_COLOR = 'gray';
  private static readonly BORDER_WIDTH = 1;
  private static readonly TEXT_COLOR = 'rgba(30, 30, 30, 1)';
  private static readonly FONT_STYLE = 'Segoe UI';
  private static readonly FONT_SIZE = 10;

  private clustering = false;
  public clusterMarkers = new Map<string, google.maps.Marker>();
  public mapRef: google.maps.Map;
  public placemarkMarkers: Map<string, google.maps.Marker>;
  public svgCache: Map<number, string> = new Map();

  constructor() {}

  public init(mapRef: google.maps.Map, placemarkMarkers): void {
    this.mapRef = mapRef;
    this.placemarkMarkers = placemarkMarkers;
  }

  public setPlacemarkClustering(clustering: boolean): void {
    if (this.clustering !== clustering) {
      this.clustering = clustering;
      clustering ? this.createClusterMarkers() : this.clearClusterMarkers();
    }
  }

  public placemarkVisibilityUpdated(): void {
    if (this.clustering) {
      this.createClusterMarkers();
    }
  }

  public placemarkCreated(marker: google.maps.Marker): void {
    if (marker.get('type') !== 'ADDRESS') {
      this.updateMarker(marker.getPosition());
    }
  }

  public placemarkRemoved(marker: google.maps.Marker): void {
    this.updateMarker(marker.getPosition());
  }

  public placemarkUpdated(marker: google.maps.Marker): void {
    this.updateMarker(marker.getPosition());    
    this.updateMarker(marker.get("previousPosition"));
  }

  private clearClusterMarkers(): void {
    this.clusterMarkers.forEach(marker => marker.setMap(null));
    this.clusterMarkers.clear();
  }

  private createClusterMarkers(): void {
    const processedPositions = new Set<string>();
    Array.from(this.placemarkMarkers.values()).forEach(marker => {
      if (marker.get('type') !== 'ADDRESS') {
        const key = marker.getPosition().toString();
        if (!processedPositions.has(key)) {
          this.updateMarker(marker.getPosition());
          processedPositions.add(key);
        }
      }
    });
  }

  private updateMarker(position: google.maps.LatLng): void {
    if (!this.clustering) {
      return;
    }
    const samePositionPlacemarks = Array.from(this.placemarkMarkers.values()).filter(
      p => position?.equals(p.getPosition()) && p.getVisible() && p.get('type') !== 'ADDRESS'
    );
    const key = position.toString();

    samePositionPlacemarks.length > 1
      ? this.addOrUpdateMarker(key, position, samePositionPlacemarks.length)
      : this.removeMarker(key);
  }

  private addOrUpdateMarker(key: string, position: google.maps.LatLng, count: number): void {
    if (this.clusterMarkers.has(key)) {
      this.updateNumberMarker(this.clusterMarkers.get(key), count);
    } else {
      const marker = this.createNumberMarker(position, count);
      this.clusterMarkers.set(key, marker);
    }
  }

  private removeMarker(key: string): void {
    const marker = this.clusterMarkers.get(key);
    if (marker) {
      marker.setMap(null);
      this.clusterMarkers.delete(key);
    }
  }

  private createSvgContent(number: number): string {
    if (this.svgCache.has(number)) {
      return this.svgCache.get(number);
    }
    const [size, offset, r] = [40, 10, 9];
    const svgContent = `
    <svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="transparent"/>
      <circle cx="${offset}" cy="${offset}" r="${r}" 
        fill="${PlacemarkClusterService.BACKGROUND_COLOR}" 
        stroke="${PlacemarkClusterService.BORDER_COLOR}" 
        stroke-width="${PlacemarkClusterService.BORDER_WIDTH}" />
      <text x="${offset}" y="${offset}" 
        text-anchor="middle" 
        dy=".3em" 
        font-family="${PlacemarkClusterService.FONT_STYLE}" 
        font-size="${PlacemarkClusterService.FONT_SIZE}" 
        fill="${PlacemarkClusterService.TEXT_COLOR}">${number}
      </text>
    </svg>
    `;
    this.svgCache.set(number, svgContent);
    return svgContent;
  }

  private createNumberMarker(position: google.maps.LatLng, number: number): google.maps.Marker {
    const svgUrl = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.createSvgContent(number));
    const marker: google.maps.Marker = new google.maps.Marker();
    marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
    marker.setIcon({
        url: svgUrl
    });
    marker.setPosition(position);
    marker.setMap(this.mapRef);
    marker.setClickable(false);
    return marker;
  }

  private updateNumberMarker(marker: google.maps.Marker, number: number): void {
    const svgUrl = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.createSvgContent(number));
    marker.setIcon({
      url: svgUrl
    });
  }

  public clearClusterPlacemarks(): void {
    this.clearClusterMarkers();
  }
}