import {Action, Selector, State, StateContext} from '@ngxs/store';
import {APPLICATION_MODE, IAppConf, NO_MODELESS_DIALOG_OPEN, SCENE_MODE} from './app.model';
import {
  ClearScene,
  ClosePanel,
  GoToProxyViewpoint,
  LoadSite, Logout,
  SetActiveModelessDialogID,
  SetActiveSite, SetDiscussionOpenedPanel, SetEmptyActiveSite, SetGoogleBlocked, SetObjectsNumberToBeLoaded,
  SetOpenedPanel,
  SetSelectedMode,
  ToggleRightMenu,
  UpdateCurrViewpointParameters,
  SetLimitedAccessConf,
  SetCompanyInfo,
  SetWebshareSession,
  RemoveWebshareSession,
  SetAppConf,
} from './app.actions';
import {ISite} from '../sites.state/sites.model';
import {IViewpoint} from '../viewpoints.state/viewpoints.model';
import {SiteDefaultView, SitesUtils} from '../sites.state/sites.utils';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../services/cmd-router.service';
import {CoordinatesType} from '../placemarks.state/placemarks.model';
import {SetSettingsLastViewpoint} from '../settings.state/settings.actions';
import {isNullOrUndefined} from 'util';
import {ILimitedAccessConf} from 'src/app/common/Models/UI/limited-access-conf.model';
import {Injectable} from '@angular/core';
import {ICompanyInfo, IWebShareConf} from 'src/app/common/Models/UI/company-info.interface';

export enum ObjectsOnWebGLConsumingLoadingTime {
  LAYOUTS = 'LAYOUTS',
  MODELS = 'MODELS',
  FILEPLACEMARK = 'FILEPLACEMARK',
  STATUSPALCEMARK = 'STATUSPALCEMARK',
  TEMPLATEPLACEMARK = 'TEMPLATEPLACEMARK',
  TCPLACEMARK = 'TCPLACEMARK',
  ADDRESSES = 'ADDRESSES',
  QR = 'QR',
  PANORAMIC = 'PANORMAIC',
  SCANPLACEMARK = 'SCANPLACEMARK'
}

export class AppStateModel {
  googleBlocked: boolean = false;
  appConf: IAppConf = undefined;
  selectedMode: SCENE_MODE = SCENE_MODE.Load;
  openedPanel: string = '';
  companyInfo: ICompanyInfo = undefined;
  webshareSession: IWebShareConf = undefined;
  activeSite: ISite = undefined;
  activeModelessDialogID: number = NO_MODELESS_DIALOG_OPEN;
  currViewpoint: IViewpoint = undefined;
  rightActionMenuIsOpen: boolean = true;
  limitedAccessConf: ILimitedAccessConf = undefined;
  objectsThatShouldBeLoaded: Map<ObjectsOnWebGLConsumingLoadingTime, number> = new Map()
    .set(ObjectsOnWebGLConsumingLoadingTime.LAYOUTS, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.MODELS, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.FILEPLACEMARK, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.STATUSPALCEMARK, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.TEMPLATEPLACEMARK, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.TCPLACEMARK, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.ADDRESSES, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.QR, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.PANORAMIC, undefined)
    .set(ObjectsOnWebGLConsumingLoadingTime.SCANPLACEMARK, undefined);
  logoutToggleIndex: number = 0;
  constructor(openedPanel: string = '', googleBlocked: boolean = false, logoutToggleIndex: number = 0, limitedAccessConf: ILimitedAccessConf = undefined, 
  companyInfo: ICompanyInfo = undefined, webshareSession: IWebShareConf = undefined, appConf: IAppConf = undefined) {
    this.openedPanel = openedPanel;
    this.googleBlocked = googleBlocked;
    this.logoutToggleIndex = logoutToggleIndex;
    this.limitedAccessConf = limitedAccessConf;
    this.companyInfo = companyInfo;
    this.webshareSession = webshareSession;
    
    const defaultAppConf: IAppConf = {
      appMode: APPLICATION_MODE.STANDALONE,
      readonly: false,
      appHeader: true,
      sideBarContainer: true,
      webGLToolbar: true,
      panoToolbar: true,
      loadSpecificSite: false
    }

    this.appConf = appConf || defaultAppConf;
  }
}

@State<AppStateModel>({
  name: 'StateApp',
  defaults: new AppStateModel()
})

@Injectable()
export class AppState {
  constructor(private cmdRouterSvc: CmdRouterService, private siteUtil: SitesUtils) {}

  @Selector()
  static getGoogleBlocked(state: AppStateModel): boolean {
    return state.googleBlocked;
  }

  @Selector()
  static getAppConf(state: AppStateModel): IAppConf {
    return state.appConf;
  }

  @Selector()
  static getLimitedAccessConf(state: AppStateModel): ILimitedAccessConf {
    return state.limitedAccessConf;
  }

  @Selector()
  static getPartialBlockedMode(state: AppStateModel): boolean {
    return !!state.activeModelessDialogID;
  }

  @Selector()
  static getRightMenuIsOpen(state: AppStateModel): boolean {
    return state.rightActionMenuIsOpen;
  }

  @Selector()
  static getCurrentViewpoint(state: AppStateModel): IViewpoint {
    return state.currViewpoint;
  }

  @Selector()
  static getActiveModelessDialogId(state: AppStateModel): number {
    return state.activeModelessDialogID;
  }

  @Selector()
  static getSelectedMode(state: AppStateModel): SCENE_MODE {
    return state.selectedMode;
  }

  @Selector()
  static getOpenedPanel(state: AppStateModel): string {
    return state.openedPanel;
  }

  @Selector()
  static getCompanyInfo(state: AppStateModel): ICompanyInfo {
    return state.companyInfo;
  }

  @Selector()
  static getWebshareConfs(state: AppStateModel): IWebShareConf[] {
    return state.companyInfo.webShareConfs;
  }

  @Selector()
  static getWebshareSession(state: AppStateModel): IWebShareConf {
    return state.webshareSession;
  }

  @Selector()
  static getActiveSite(state: AppStateModel): ISite {
    return state.activeSite;
  }

  @Selector()
  static getLogoutToggleIndex(state: AppStateModel): number {
    return state.logoutToggleIndex;
  }

  @Selector()
  static getNumberOfObjectsToLoad(state: AppStateModel): number {
    const sum: number =  Array.from(state.objectsThatShouldBeLoaded.values()).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    if (!isNaN(sum)) {
      state.objectsThatShouldBeLoaded.set(ObjectsOnWebGLConsumingLoadingTime.LAYOUTS, undefined)
        .set(ObjectsOnWebGLConsumingLoadingTime.MODELS, undefined)
        .set(ObjectsOnWebGLConsumingLoadingTime.FILEPLACEMARK, undefined)
        .set(ObjectsOnWebGLConsumingLoadingTime.STATUSPALCEMARK, undefined)
        .set(ObjectsOnWebGLConsumingLoadingTime.TEMPLATEPLACEMARK, undefined)
        .set(ObjectsOnWebGLConsumingLoadingTime.TCPLACEMARK, undefined)
        .set(ObjectsOnWebGLConsumingLoadingTime.ADDRESSES, 0)
        .set(ObjectsOnWebGLConsumingLoadingTime.QR, 0)
        .set(ObjectsOnWebGLConsumingLoadingTime.PANORAMIC, 0)
        .set(ObjectsOnWebGLConsumingLoadingTime.SCANPLACEMARK, 0);
    }
    return sum;
  }

  @Action(SetGoogleBlocked)
  setGoogleBlocked({getState, patchState }: StateContext<AppStateModel>, { blocked }: SetGoogleBlocked): void {
    patchState({googleBlocked: blocked});
  }

  @Action(SetAppConf)
  SetAppConf({getState, patchState }: StateContext<AppStateModel>, { appConf }: SetAppConf): void {
    patchState({appConf: appConf});
  }

  @Action(SetLimitedAccessConf)
  setLimitedAccessConf({getState, patchState }: StateContext<AppStateModel>, { limitedAccessConf }: SetLimitedAccessConf): void {
    patchState({limitedAccessConf: limitedAccessConf});
  }

  @Action(SetSelectedMode)
  SetSelectedMode({getState, patchState, dispatch }: StateContext<AppStateModel>, { payload, saveNewViewpoint }: SetSelectedMode): void {
    if (saveNewViewpoint) {
      const currVp: IViewpoint = getState().currViewpoint;
      if (currVp && payload !== SCENE_MODE.Panoramic) {
        // we should update 'last viewpoint' in settings as well
        currVp.coordinatesType = (payload === SCENE_MODE.Map ? CoordinatesType.GIS : CoordinatesType.FACILITY);
        currVp.parent = (payload === SCENE_MODE.Map ? 'MAPS' : 'WEBGL');
        dispatch(new SetSettingsLastViewpoint(currVp));
      }
      patchState({
        currViewpoint: Object.assign({}, currVp),
        selectedMode: payload
      });
    } else {
      patchState({
        selectedMode: payload
      });
    }
  }

  @Action(SetOpenedPanel)
  SetOpenedPanel({getState, patchState }: StateContext<AppStateModel>, { payload }: SetOpenedPanel): void {
    const state: AppStateModel = getState();
    let newMenuName: string = '';
    if (state.openedPanel === '') {
      newMenuName = payload;
    } else if (state.openedPanel === payload) {
      newMenuName = '';
    } else {
      newMenuName = payload;
    }
    patchState({openedPanel: newMenuName});
  }

  @Action(SetDiscussionOpenedPanel)
  SetDiscussionOpenedPanel({getState, patchState }: StateContext<AppStateModel>, { payload, isOpen }: SetDiscussionOpenedPanel): void {
    const state: AppStateModel = getState();
    let newMenuName: string = '';
    newMenuName = payload;
    patchState({openedPanel: newMenuName});
  }

  @Action(UpdateCurrViewpointParameters)
  updateCurrViewpointParameters({getState, patchState, dispatch}: StateContext<AppStateModel>, { params }: UpdateCurrViewpointParameters): void {
    const currVp: IViewpoint = getState().currViewpoint;
    if (!!currVp) {
      Object.keys(currVp).forEach((key: string) => {
        if (params[key] !== undefined) {
          currVp[key] = params[key];
        }
      });
    }

    if(params.parent != "PANORAMIC"){
      dispatch(new SetSettingsLastViewpoint(currVp));
    }
    patchState({currViewpoint: Object.assign({}, currVp)});
  }

  @Action(LoadSite)
  loadSite(stateContext: StateContext<AppStateModel>, { site, openInMode }: LoadSite): void {
    this.setActiveSite(stateContext, {site, openInMode});
  }

  @Action(SetCompanyInfo)
  setCompanyInfo({getState, patchState }: StateContext<AppStateModel>, { companyInfo }: SetCompanyInfo): void {
    patchState({companyInfo: Object.assign({}, companyInfo)});
  }

  @Action(SetWebshareSession)
  setWebshareSession({getState, patchState }: StateContext<AppStateModel>, { webshareSession }: SetWebshareSession): void {
    patchState({webshareSession: webshareSession});
  }

  @Action(RemoveWebshareSession)
  removeWebshareSession({getState, patchState }: StateContext<AppStateModel>): void {
    patchState({
      webshareSession: null
    });
  }

  private switchSite(site: ISite, openInMode: SCENE_MODE): SCENE_MODE{
    let currSelectedMode: SCENE_MODE;
    // switching site, run logic to determine in what state should we open
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.UPDATE_OFFSETS, {site: site});
    if (site.defaultView === SiteDefaultView.LastVisited && !!site.lastViewpoint && isNullOrUndefined(openInMode)) {
      currSelectedMode = (site.lastViewpoint.coordinatesType === CoordinatesType.FACILITY) ? SCENE_MODE.Facility : SCENE_MODE.Map;
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: site.lastViewpoint});
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: site.lastViewpoint});
    } else {
      if (openInMode !== null && openInMode !== undefined) {
        currSelectedMode = openInMode;
      } else if (site.defaultView === SiteDefaultView.Maps) {
        currSelectedMode = SCENE_MODE.Map;
      } else if (site.defaultView === SiteDefaultView.Facility) {
        currSelectedMode = SCENE_MODE.Facility;
      } else {
        // default - if there is no last viewpoint data
        currSelectedMode = SCENE_MODE.Map;
      }
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {lat: site.latitude, lng: site.longitude, selectedVP: site.lastViewpoint});
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE);
    }
    return currSelectedMode;
  }


  @Action(SetActiveSite)
  setActiveSite({getState, patchState }: StateContext<AppStateModel>, { site, openInMode }: SetActiveSite): void {
    const currActiveSite: ISite = getState().activeSite;
    const googleBlocked: boolean = getState().googleBlocked;
    let currSelectedMode: SCENE_MODE;

    if (!currActiveSite || currActiveSite.id !== site.id) {
      currSelectedMode = this.switchSite(site, openInMode);
    } else {
      // only update in an already active site - just keep the selected mode
      currSelectedMode = getState().selectedMode;
    }

    if (googleBlocked) {
      currSelectedMode = SCENE_MODE.Facility;
    }

    patchState({
      activeSite: site,
      selectedMode: currSelectedMode,
      currViewpoint: site.lastViewpoint
    });
  }

  @Action(ClosePanel)
  ClosePanel({getState, patchState }: StateContext<AppStateModel>): void {
    patchState({openedPanel: ''});
  }

  @Action(SetActiveModelessDialogID)
  setActiveModelessDialogID({getState, patchState }: StateContext<AppStateModel>, { payload }: SetActiveModelessDialogID): void {
    patchState({activeModelessDialogID: payload});
  }

  @Action(ClearScene)
  clearScene(): void {
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CLEAR_SCENE, {siteLayersOnly: true});
  }

  @Action(GoToProxyViewpoint)
  goToProxyViewpoint({getState, patchState }: StateContext<AppStateModel>, { vp }: GoToProxyViewpoint): void {
    let currSelectedMode: SCENE_MODE = (vp.coordinatesType === CoordinatesType.FACILITY) ? (vp.parent === 'PANORAMIC' ? SCENE_MODE.Panoramic : SCENE_MODE.Facility) : SCENE_MODE.Map;
    if (getState().googleBlocked && currSelectedMode === SCENE_MODE.Map) {
      vp = undefined; // will make us open in facility 'zoom to fit' mode
      currSelectedMode = SCENE_MODE.Facility;
    }
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: vp});
    this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: vp});
    if (currSelectedMode === SCENE_MODE.Panoramic) {
      this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.PANORAMIC_MANAGER, CMD_ACTIONS.GO_TO_VP_MODE, {selectedVP: vp});
    }
    patchState({
      selectedMode: currSelectedMode
    });
  }

  @Action(ToggleRightMenu)
  toggleRightMenu({getState, patchState }: StateContext<AppStateModel>): void {
    const currState: boolean = getState().rightActionMenuIsOpen;
    patchState({rightActionMenuIsOpen: !currState});
  }

  @Action(Logout)
  logout({getState, patchState}: StateContext<AppStateModel>): void {
    const currentVal: number = getState().logoutToggleIndex;
    patchState({logoutToggleIndex: currentVal + 1,
    activeSite: undefined});
  }

  @Action(SetObjectsNumberToBeLoaded)
  setObjectsNumberToBeLoaded({getState, patchState}: StateContext<AppStateModel>, {objectsOnWebGLConsumingLoadingTime, num}: SetObjectsNumberToBeLoaded): void {
    console.log('counting object that were loaded from server:', objectsOnWebGLConsumingLoadingTime, 'with count of ', num);
    const currState: Map<ObjectsOnWebGLConsumingLoadingTime, number> = getState().objectsThatShouldBeLoaded;
    currState.set(objectsOnWebGLConsumingLoadingTime, num);
    patchState({objectsThatShouldBeLoaded: currState});
  }
  // use it only after proxy!!
  @Action(SetEmptyActiveSite)
  setEmptyActiveSite({patchState}: StateContext<AppStateModel>): void {
    patchState({activeSite: null});
  }
}
