import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {NotificationDialogComponent} from '../../../../../common/Forms/Dialogs/notification-dialog/notification-dialog.component';
import {ButtonInfo} from '../../../../../common/UI-Components/helperClasses/value-and-display.class';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {DialogService} from '../../../../../services/dialogs.service';
import {SessionApiSvc} from '../../../../../services/api.services/session.api.svc';
import {CMD_ACTIONS, CMD_TARGETS, CmdRouterService} from '../../../../../services/cmd-router.service';
import {Store} from '@ngxs/store';
import {IUserData} from 'src/app/services/user-details.service';
import {ICompanyInfo} from 'src/app/common/Models/UI/company-info.interface';

@Component({
  selector: 'ins-profile-panel',
  templateUrl: './profile-panel.component.html',
  styleUrls: ['./profile-panel.component.scss', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class ProfilePanelComponent implements OnChanges {

  @Input() user: IUserData = null;
  public userTitle = "Temp User";

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();

  constructor(public dialogService: DialogService, private sessionApiSvc: SessionApiSvc, private cmdRouterSvc: CmdRouterService, private store: Store) { }

  ngOnChanges() {
    if(this.user){
      const companyInfo = this.store.selectSnapshot<ICompanyInfo>((state: any) => state.StateApp.companyInfo );
      if (companyInfo.ssoEnable) {
        this.userTitle = this.user.email;
      } else {
        this.userTitle = this.user.name + ' ' + this.user.family;
      }
    }
  }

  signOut(): void {
    const inputsBinding: InputsBindingsModel = new Map([
      [ 'type', 'warning'],
      [ 'title', 'Sign-Out' ],
      [ 'message', 'Are you sure you want to sign-out?\n']
    ]);
    const dialog: DialogRef = this.dialogService.createNotificationDialog(inputsBinding);
    const dialogComp: NotificationDialogComponent = (dialog.instance as NotificationDialogComponent);

    dialogComp.buttonsInfo = [
      new ButtonInfo('no', 'No'),
      new ButtonInfo('yes', 'Yes'),
    ];
    dialog.onClose$().subscribe((model: DialogModel) => {
      if (model.userAction === 'yes') {
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.MAP_MANAGER, CMD_ACTIONS.CLEAR_SCENE);
        this.cmdRouterSvc.sendActionCmd(CMD_TARGETS.WEBGL_MANAGER, CMD_ACTIONS.CLEAR_SCENE);
        this.sessionApiSvc.closeSession();
      }
    });
  }

  public getToolTipConf(element: any, label: string): any {
    const toolTipConf = {text: '', position: { H: 'hRight', V:'vTop' }, type: 'info'};
    if ( element.offsetWidth < element.scrollWidth )  {
      toolTipConf.text = label;
    }
    return toolTipConf;
  }
}
