import {Component, EventEmitter, Output} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {SetAllZones, SetZone} from '../../../../../Store/zones.state/zones.actions';
import {Observable} from 'rxjs';
import {ZonesState} from '../../../../../Store/zones.state/zones.state';
import {IZone} from '../../../../../Store/zones.state/zones.model';
import {InputsBindingsModel} from '../../../../../common/Models/Dialog/inputs-binding.model';
import {DeleteZoneDialogComponent} from '../../../../../common/Forms/Dialogs/delete-zone-dialog/delete-zone-dialog.component';
import {DialogService, DialogType} from '../../../../../services/dialogs.service';
import {DialogRef} from '../../../../../common/Forms/Dialog-types/dialog-ref';
import {AddEditZoneDialogComponent} from '../../../../../common/Forms/Dialogs/add-zone-dialog/add-edit-zone-dialog.component';
import {EditorMode} from '../../../../../common/Forms/Dialogs/dialog.helper';
import {DialogModel} from '../../../../../common/Models/Dialog/dialog.model';
import {MessagesBank, StatusService} from '../../../../../services/status.service';
import {ZonesApiSvc} from '../../../../../services/api.services/zones.api.svc';
import {ACTION_TYPE, Actions, PermissionsManager} from '../../../../../services/permissions-manager';
import {AppState} from '../../../../../Store/app.state/app.state';
import {ISite} from '../../../../../Store/sites.state/sites.model';
import {isNullOrUndefined} from 'util';
import {SiemensAnalyticsService} from 'src/app/services/siemens-analytics.service';

@Component({
  selector: 'ins-zones-menu',
  templateUrl: './zones-menu.component.html',
  styleUrls: ['./zones-menu.component.scss', './../submenus-shared-design.scss',
    './../../../../../common/UI-Components/shared-UI-components.scss']
})
export class ZonesMenuComponent {

  public hover: {} = {};

  public isPermAddZone: boolean = false;
  public isPermEditZone: boolean = false;
  public isPermDeleteZone: boolean = false;
  public allZoneSelected: boolean = false;
  @Select(ZonesState.getZones) zones$: Observable<IZone[]>;
  @Select(AppState.getActiveSite) activeSite$: Observable<ISite>;
  zones: IZone[] = [];
  enableFunctionalities: boolean = false;

  @Output()
  public closePanel: EventEmitter<void> = new EventEmitter();

  constructor(private store: Store, public dialogSvc: DialogService,
              private statusBar: StatusService, private zonesApiSvc: ZonesApiSvc, private siemensAnalyticsService: SiemensAnalyticsService) {
    this.zones$.subscribe((zoneArr: IZone[]) => {
      this.zones = zoneArr;
      let visibleZonesNum: number = 0;
      this.zones.forEach((zone: IZone) => {
        if (zone.visible) {
          visibleZonesNum++;
        }
      });
      this.allZoneSelected = visibleZonesNum === this.zones.length;
      this.activeSite$.subscribe((site: ISite) => {
        this.enableFunctionalities = !isNullOrUndefined(site);
      });
    });

    PermissionsManager.isPermitted$(Actions.ZONES_PERM, ACTION_TYPE.CREATE).subscribe((isPerm: boolean) => {
      this.isPermAddZone = isPerm; });
    PermissionsManager.isPermitted$(Actions.ZONES_PERM, ACTION_TYPE.UPDATE).subscribe((isPerm: boolean) => {
      this.isPermEditZone = isPerm; });
    PermissionsManager.isPermitted$(Actions.ZONES_PERM, ACTION_TYPE.DELETE).subscribe((isPerm: boolean) => {
      this.isPermDeleteZone = isPerm; });
  }

  trackByVisibility(item: IZone): any {
    return item.id;
  }

  public cbCustomStyle(zone: IZone): any {
    const res = {
      'font-size': '12px'
    };
    if (zone.isDefault) {
      res['font-style'] = 'italic';
    }
    return res;
  }

  public selectAll(event$: any): void {
    const updatedZones: IZone[] = [];
    this.zones.forEach((zone: IZone) => {
      zone.visible = event$.new;
      updatedZones.push(zone);
    });
    this.store.dispatch(new SetAllZones(updatedZones));
  }

  public deleteZone(zoneToDelete: IZone): void {
      const inputsBinding: InputsBindingsModel = new Map()
        .set('zoneName', zoneToDelete.name);
      const dialog: DialogRef = this.dialogSvc.createDialog(DeleteZoneDialogComponent, DialogType.Modal, inputsBinding);
      dialog.onClose$().subscribe((data: DialogModel) => {
        if (data.userAction === 'add') {
          this.statusBar.addNewStatus(MessagesBank.DELETING_ZONE);
          if (data.values.get('selectedDeleteOption') === 'move') {

            let zoneToMoveContent: IZone;
            this.zones$.subscribe((zoneArr: IZone[]) => {
              zoneArr.filter((zone: IZone) => zone.name === data.values.get('zoneToMove'))
                .forEach((zoneToMove: IZone) => {
                  zoneToMoveContent = zoneToMove;
                });
            }).unsubscribe();

            if (zoneToMoveContent) {
              this.zonesApiSvc.deleteZoneAndReplace(zoneToDelete, zoneToMoveContent);
            }

          } else if (data.values.get('selectedDeleteOption') === 'delete') {
            this.zonesApiSvc.deleteZoneById(zoneToDelete);
          }
        }
      });
  }

  addZone(): void {
    const dialog: DialogRef = this.dialogSvc.createDialog(AddEditZoneDialogComponent, DialogType.Modal);
    if (!dialog) {
      return;
    }

    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'ok') {
        this.statusBar.addNewStatus(MessagesBank.ADDING_ZONE);
        const desc: string = data.values.get('description') ? data.values.get('description') : '';
        const newZone: IZone = {parent: '-1',
        id: '-1',
        name: data.values.get('name'),
        visible: data.values.get('isVisibleByDefault'),
        isDefault: false,
        description: desc,
        defaultViewpointId: '',
        accessMode: 'UNDEFINED',
        visibleByDefault: data.values.get('isVisibleByDefault')};

        this.zonesApiSvc.addNewZone(newZone);
      }
    });
  }

  editZone(zone: IZone): void {
    const inputsBinding: InputsBindingsModel = new Map<string, any>([
      [ 'dialogMode', EditorMode.EDIT],
      [ 'name', zone.name],
      [ 'description', zone.description],
      [ 'isVisibleByDefault', zone.visibleByDefault]
    ]);
    const dialog: DialogRef = this.dialogSvc.createDialog(AddEditZoneDialogComponent, DialogType.Modal, inputsBinding);
    if (!dialog) {
      return;
    }

    dialog.onClose$().subscribe((data: DialogModel) => {
      if (data.userAction === 'ok') {
        this.statusBar.addNewStatus(MessagesBank.EDITING_ZONE);
        const defaultVpId: string = zone.defaultViewpointId ? zone.defaultViewpointId : '';

        const updateZone: IZone = {
          id: zone.id,
          name: data.values.get('name'),
          description: data.values.get('description'),
          visible: data.values.get('isVisibleByDefault'),
          isDefault: zone.isDefault,
          defaultViewpointId: defaultVpId,
          parent: zone.parent,
          accessMode: zone.accessMode,
          visibleByDefault: data.values.get('isVisibleByDefault')
          };
        this.zonesApiSvc.updateZone(updateZone);
      }
    });
  }

  markHover(hover: boolean, node: IZone): void {
    this.hover[node.id] = hover;
  }

  setZoneVisibility(zone: IZone, values: any): void {
    if (values.old !== '' && values.old !== values.new) {
      if (values.new) {
        const zonesThatWereVisible: Set<string> = this.store.selectSnapshot<Set<string>>((state: any) => state.StateZones.visibleZonesHistory);
        if (!zonesThatWereVisible.has(zone.id)) {
          this.statusBar.addNewStatus(MessagesBank.LOADING_SITE_INFORMATION, true);
        }
      }
      this.store.dispatch(new SetZone(zone));

      // Log Siemens Analytics event
      this.siemensAnalyticsService.logEvent('INS_SelectionOfZoneVisibility');
    }
  }
}
