import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ServerApi } from './server.api';
import { IPlacemark, PlacemarkData } from 'src/app/Store/placemarks.state/placemarks.model';
import { SetPlacemark } from 'src/app/Store/placemarks.state/placemarks.actions';
import { Set3DModel } from 'src/app/Store/models.state/models.actions';
import { I3DModel } from 'src/app/Store/models.state/models.model';
import { environment } from 'src/environments/environment';
import { ApiTools } from './api.tools';
import { UserDetailsService } from '../user-details.service';

@Injectable()
export class DispatcherSseApiSvc {
  constructor(private serverApi: ServerApi, private store: Store, private userDetailsService: UserDetailsService) { }

  // Below method is used only for UI updates from API Gateway Dispatcher Component.
  public getSseUpdates(isSiteChanged: boolean): void {
    if (isSiteChanged) {
      this.serverApi.stopEeventsFromDispatcher();
    }
    const eventStreamURL: string = `${environment.dispatcherUrl}?tenantId=${this.userDetailsService.getCompany()}&siteId=${ApiTools.defaultSiteId}`;
    this.serverApi.startEventsFromDispatcher(eventStreamURL).subscribe((response: any) => {
      const messages: any[] = JSON.parse(response.data);
      const placemarks: IPlacemark[] = this.store.selectSnapshot<IPlacemark[]>((state: any) => state.StatePlacemarks.placemarks);
      const models: I3DModel[] = this.store.selectSnapshot<I3DModel[]>((state: any) => state.StateModels.models3D);
      if (messages && messages.length > 0) {
        console.log(`Got : ${messages.length} SSE Messages from Dispatcher`);
        for (let i = 0; i < messages.length; i++) {
          switch (messages[i].objectType) {
            case 'PM':
              this.updatePlacemarks(messages[i], placemarks);
              break;
            case '3D':
              this.update3DModels(messages[i], models);
              break;
          }
        }
      }
    },
      (error: any) => {
        console.log('Error receiving sse updates from dispatcher', error);
      });
  }

  private updatePlacemarks(message: any, placemarks: IPlacemark[]): void {
    let existingPmIndex: number = placemarks.findIndex((pm: IPlacemark) => pm.externalId && pm.externalId.length > 0 && pm.externalId[0].externalId == message.externalId);
    if (existingPmIndex != -1) {
      console.log('SSE Message for PM : ', message);
      if (message.status) {
        placemarks[existingPmIndex].selectedStatus = message.status - 1;
      }
      if (message.eventInfo) {
        placemarks[existingPmIndex].eventInfo = message.eventInfo;
      }
      if (message.url) {
        placemarks[existingPmIndex].url = message.url;
      }
      if (message.name) {
        placemarks[existingPmIndex].name = message.name;
      }
      if (message.placemarkData) {
        this.updatePlacemarkData(placemarks[existingPmIndex], JSON.parse(message.placemarkData), message.updatePolicy);
      }
      if (message.xLoc) {
        placemarks[existingPmIndex].positionProperty.x = parseFloat(message.xLoc);
      }
      if (message.yLoc) {
        placemarks[existingPmIndex].positionProperty.y = parseFloat(message.zLoc);
      }
      if (message.zLoc) {
        placemarks[existingPmIndex].positionProperty.z = parseFloat(message.yLoc);
      }
      this.serverApi.storeDispatch(new SetPlacemark(placemarks[existingPmIndex]));
    }
  }

  private update3DModels(message: any, models: I3DModel[]): void {
    let existingModelIndex: number = models.findIndex((model: I3DModel) => model.externalId && model.externalId.length > 0 && model.externalId[0].externalId == message.externalId);
    if (existingModelIndex != -1) {
      console.log('SSE Message for 3D : ', message);
      if (message.x) {
        models[existingModelIndex].latitude = message.x;
      }
      if (message.y) {
        models[existingModelIndex].longitude = message.y;
      }
      if (message.z) {
        models[existingModelIndex].altitude = message.z;
      }
      if (message.rotation) {
        models[existingModelIndex].rotation = message.rotation;
      }
      if (message.speed) {
        models[existingModelIndex].movementParams.speed = message.speed;
      }
      if (message.autoRotate) {
        models[existingModelIndex].movementParams.autoRotate = message.autoRotate;
      }
      if (message.referenceId) {
        models[existingModelIndex].movementParams.referenceId = message.referenceId;
      }
      this.serverApi.storeDispatch(new Set3DModel(models[existingModelIndex]));
    }
  }

  private updatePlacemarkData(existingPm: IPlacemark, placemarkData: any, updatePolicy: String): void {
    const UPDATE_POLICY_REPLACE = "replace";
    if (updatePolicy && updatePolicy !== "" && updatePolicy === UPDATE_POLICY_REPLACE) {
      if (!existingPm.placemarkData) {
        existingPm.placemarkData = [];
      }
      const filteredPms = existingPm.placemarkData.filter(pm => !pm.replaceVariable);
      existingPm.placemarkData = filteredPms;
      for (const pmData of placemarkData) {
        let placemarkData: PlacemarkData = {
          variableId: pmData.variableName,
          name: pmData.variableName,
          value: pmData.variableValue,
          replaceVariable: true
        };
        existingPm.placemarkData.push(placemarkData);
      }
    } else {
      for (const pmData of placemarkData) {
        let selectedPmData: PlacemarkData = existingPm.placemarkData.find((d: PlacemarkData) => d.variableId === pmData.variableName);
        if (selectedPmData) {
          selectedPmData.value = pmData.variableValue;
        } else {
          let placemarkData: PlacemarkData = {
            variableId: pmData.variableName,
            name: pmData.variableName,
            value: pmData.variableValue,
            replaceVariable: true
          };
          existingPm.placemarkData.push(placemarkData);
        }
      }
    }
  }
}
